import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";

const OnlineDetails = () => {
  const [isLastTab, setIsLastTab] = useState(false);
  const handleNext = () => {
    console.log("Next clicked");
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };
  const emailFields = [
    { label: t("Email address legal entity"), name: "email_legal_entity" },
    {
      label: t("Email address online performance"),
      name: "email_online_performance",
    },
    {
      label: t("Email address performance sheet"),
      name: "email_performance_sheet",
    },
    {
      label: t("Email address digital contracts"),
      name: "email_digital_contracts",
    },
    {
      label: t("Email address invoicing reminders 'rappels'"),
      name: "email_invoicing_reminders",
    },
  ];

  const radioFields = [
    {
      title: t("Digital contract"),
      name: "digital",
      ids: ["digitalYes", "digitalNo"],
    },
    {
      title: t("Online performance"),
      name: "online",
      ids: ["onlineYes", "onlineNo"],
    },
    {
      title: t("New online encodage"),
      name: "encodage",
      ids: ["newEncodageYes", "newEncodageNo"],
    },
    {
      title: t("Performance sheet"),
      name: "performance",
      ids: ["performanceYes", "performanceNo"],
    },
  ];

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 22vw)" }}>
        <div className="row">
          {emailFields.map((field, index) => (
            <div className="col-4 mb-3" key={index}>
              <LabelWithInputField
                type="email"
                label={field.label}
                value=""
                name={field.name}
                id={field.name}
                handleChange={() => {}}
                error=""
                className="ps-2"
              />
            </div>
          ))}

          {radioFields.map((field, index) => (
            <div className="col-4 mb-3" key={index}>
              <div className="mb-2">
                <LabelField title={field.title} />
              </div>
              {["yes", "no"].map((option, optIndex) => (
                <RadioField
                  key={optIndex}
                  name={field.name}
                  label={t(option.charAt(0).toUpperCase() + option.slice(1))}
                  className="radioBtn"
                  ischecked={false}
                  handleChange={() => {}}
                  id={field.ids[optIndex]}
                  containerClassName={`companyLanguage ${
                    optIndex === 0 ? "me-3" : ""
                  }`}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </>
  );
};

export default OnlineDetails;
