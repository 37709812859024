import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/atoms/Button";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import "./css/planning.css";
import IconWithTitle from "../common/molecules/IconWithTitle";
import PlanningCalendar from "../common/molecules/PlanningCalendar";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import Icon from "../../Icon";
import CalendarIcon from "../common/atoms/CalendarIcon";
import {
  addEmployee,
  removeEmployee,
  resetAllData,
  selectCompanyId,
  selectContractType,
  selectDates,
  selectEmployees,
  selectLocationId,
  setTab,
} from "../../features/planning/planningSlice";
import employeesData from "./employees.json";
import userIcon from "../../static/images/User.svg";
import { formatDate, generateDateRange } from '../common/utlis/dateHelper'; // Adjust the import path as needed
import OverFlowWrapper from "../common/OverFlowWrapper";
interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}
interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center?: number;
  shifts: Shifts[], // First shift
};
interface ScheduleMap {
  [date: string]: Schedule; 
}

interface Employee {
  id: number;
  name: string;
  function_id: number;
  function: string;
  employee_type_id: number;
  employee_type: string;
  partiair_commitee_id: number;
  pc: string;
  salary: string;
  image?: string; // Add this property to the interface
}

const WeeklyPlanning = () => {
  const [showModal, setShowModal] = useState(true);
  const dispatch = useDispatch();
  const monthView = useSelector(selectContractType);
  const selectedEmployees = useSelector(selectEmployees);
  const companyId = useSelector(selectCompanyId);
  const locationId = useSelector(selectLocationId);
  const selectedDates = useSelector(selectDates);
  const [schedule, setSchedule] = useState<ScheduleMap>({});


  const [error, setError] = useState({
    datesError: "",
    employeesError: "",
  });
  useEffect(() => {
    const initialSchedule: ScheduleMap = {};
    const defaultShift = {
      location_id: locationId, // If location is not 0 then add location_id key, otherwise omit
      shifts: [{
        start_time: "", // First shift start time
        end_time: "",   // First shift end time
        sequence: 1,
      }],
    }
      ;

    if (selectedDates.length === 2) {
      const [startDate, endDate] = selectedDates;
      const datesInRange = generateDateRange(startDate, endDate);

      datesInRange.forEach((date) => {
        initialSchedule[formatDate(date)] = defaultShift;
      });
    } else {
      selectedDates.forEach((date: string) => {
        initialSchedule[formatDate(date)] = defaultShift;
      });
    }

    setSchedule(initialSchedule);
  }, [selectedDates]);

  const handleCloseModal = () => {
    dispatch(resetAllData());
    setShowModal(false);
  };

  const handleNextClick = () => {
    const isValid = validation();
    if (isValid) {
      dispatch(setTab("tab_2"));
    }
  };

  const validation = () => {
    let valid = true;
    let errorObj = { datesError: "", employeesError: "" };

    if (selectedDates.length === 0 && monthView != "month") {
      errorObj.datesError = "Please select dates.";
      valid = false;
    }

    if (selectedEmployees.length === 0) {
      errorObj.employeesError = "Please select employees.";
      valid = false;
    }

    setError(errorObj);
    return valid;
  };

  const handleEmployeeClick = (employee: Employee) => {
    setError({
      datesError: "",
      employeesError: "",
    });
    if (selectedDates.length === 0 && monthView != "month") {
      setError({
        datesError: "Please select dates",
        employeesError: "",
      });
      return "";
    }

    const isSelected = selectedEmployees.some(
      (emp: any) => emp.employee_id === employee.id
    );
    if (isSelected) {
      dispatch(removeEmployee(employee.id));
    } else {
      const newEmployee = {
        company_id: companyId,
        // location_id: employee.location_id,
        // location: employee.location,
        // project_id: 0,
        // cost_center_id: 0,
        employee_id: employee.id,
        employee_name: employee.name,
        employee_type_id: employee.employee_type_id,
        employee_type: employee.employee_type,
        paritair_commitee: employee.partiair_commitee_id,
        pc: employee.pc,
        function_id: employee.function_id,
        function: employee.function,
        salary: employee.salary,
        start_date: selectedDates[0],  // Add start_date
        end_date: selectedDates.length > 1 ? selectedDates[1] : selectedDates[0],
        schedule: schedule,
      };
      dispatch(addEmployee(newEmployee));
    }
  };

  const isEmployeeSelected = (employeeId: number) => {
    return selectedEmployees.some((emp: any) => emp.employee_id === employeeId);
  };

  return (
    <>
      <ModalPopup
        show={showModal}
        handleClose={handleCloseModal}
        modalTitle={t("Employee scheduling")}
        modalBackgroundColor="#8571b9"
        className="planningModal"
        buttonText={t("Next")}
        showNextIcon={true}
        onNextClick={handleNextClick}
        iconFillColor="#8571b9"
      >
        {monthView !== "month" && (
          <Title
            title={`${t("Choose a day for which you want to plan")}:`}
            className="bodyTitle colorPrimary mb-3"
          />
        )}
        <div>
          <CalendarIcon isCalendarIcon={true} page="planning" />
          {monthView !== "month" && (
            <PlanningCalendar
              isReactCalendar={true}
              customClassName={error.employeesError ? "error-border" : ""}
            />
          )}
          {/* Error Message for Calendar */}
          {error.datesError && (
            <div className="error-message">{error.datesError}</div>
          )}

          <div className="colorPrimary poppins-medium planningEmployeesTitle mb-2">
            {t("Choose an employee(s) for whom you want to create a schedule:")}
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <span
              data-bs-toggle="tooltip"
              title={t("Filter")}
              onClick={() => {}}
            >
              <Icon
                name="FilterIcon"
                width="1vw"
                height="1vw"
                isIcon={true}
                className="secondaryColorHoverEffect cursor-pointer"
              />
            </span>
            <AutoCompleteWithSearch placeholder={t("Search employee")} />
          </div>
          <OverFlowWrapper className="planningEmployeeHeight">
            <div
              className={`planningEmployeeContainer d-grid overflow-auto ${
                error.employeesError ? "error-border" : ""
              }`}
            >
              {employeesData.map((employee) => {
                const selected = isEmployeeSelected(employee.id);
                return (
                  <div
                    key={employee.id}
                    className={`d-inline-flex align-items-center employeeNameWithProfile cursor-pointer ${
                      selected ? "employee-selected" : ""
                    }`}
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    <IconWithTitle
                      src={userIcon}
                      isImage={true}
                      imageWidth="2vw"
                      imageHeight="2vw"
                      imageBorderRadius="50%"
                      title={employee.name}
                      isTitle={true}
                      labelClassName={`colorPrimary poppins-medium ms-2 employeeName`}
                    />
                  </div>
                );
              })}
            </div>
          </OverFlowWrapper>
          {/* Error Message for Employee Container */}
          {error.employeesError && (
            <div className="error-message">{error.employeesError}</div>
          )}
        </div>
      </ModalPopup>
    </>
  );
};

export default translate(WeeklyPlanning);
