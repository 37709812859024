import React, { useState, ChangeEvent, ReactNode } from "react";

const style = {};

interface Props {
  id?: string;
  type?: string;
  className?: string;
  value?: string | number;
  checked?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  name?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  children?: ReactNode;
}

const InputTextfield: React.FC<Props> = ({
  id = "",
  type = "text",
  min,
  max,
  step,
  className = "form-control field-shadow rounded-3",
  value,
  isDisabled = false,
  placeholder = "",
  customStyle = {},
  handleChange,
  handleBlur,
  name = "",
  error,
  checked,
  readOnly,
  autoComplete,
  children,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  // const handleBlur = () => {
  //   setIsFocused(false);
  // };

  return (
    <>
      <input
        style={{ ...style, ...customStyle }}
        id={id}
        type={type}
        className={`${className} inputFieldColor`}
        disabled={isDisabled}
        placeholder={isFocused ? "" : placeholder}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        min={min}
        max={max}
        step={step}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />
      <span className="text-danger">{error}</span>
      {children}
    </>
  );
};

export default InputTextfield;
