import React, { useEffect, useState } from "react";
import { ApiCall } from "../../../services/ApiServices";
import Button from "../../common/atoms/Button";
import Pagination from "../../common/atoms/Pagination";
import ModalPopup from "../../common/atoms/ModalPopup";
import { Link } from "react-router-dom";
import CustomNotify from "../../common/atoms/CustomNotify";
import ExportCSV from "./ExportCSV";
import { t, translate } from "./Translation";
import {
  ABSOLUTE_CORE_APP,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  ABSOLUTE_CORE_MOBILE_APP,
  MAX_FILE_SIZE,
  SUPER_ADMIN,
} from "../../../Constants";
import {
  LOCALE_TARGETS,
  LOCALE_TARGETS_SEARCH,
  SAVE_TRANSLATION,
  UPDATE_TRANSLATIONS,
} from "../../../routes/ApiEndpoints";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import CSVReader from "../../common/molecules/CSVReader";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import secureLocalStorage from "react-secure-storage";
// import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import Icon from "../../../Icon";
import Header from "../../common/layout/Header";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import InputTextfield from "../../common/atoms/InputTextField";
import BackLink from "../../common/atoms/BackLink";
import OverFlowWrapper from "../../common/OverFlowWrapper";

interface Translation {
  id: number;
  translation: string;
  text: string;
  isEditing: boolean;
}

export interface LanguageData {
  [key: string]: {
    [key: string]: Translation;
  };
}
interface Language {
  id: number;
  name: string;
  key: string;
}

interface Applications {
  id: number;
  name: string;
  key: string;
}

interface SearchProps {
  string: string;
  appName: string;
}

const options = [
  { value: ABSOLUTE_CORE_MOBILE_APP, label: "ABSOLUTECORE MOBILE APP" },
  { value: ABSOLUTE_CORE_APP, label: "ABSOLUTECORE WEB APP" },
  // { value: "option3", label: "Option 3" },
];

const ManageTranslation = () => {
  const [languageData, setLanguageData] = useState<LanguageData>({});
  const [language, setLanguage] = useState("EN");
  const [languageDropDown, setLanguageDropDown] = useState<Language[]>();
  const [applicationDropDown, setApplicationDropDown] =
    useState<Applications[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [key, setKey] = useState(0); // State to control the key prop of CSV reader
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state
  const [searchData, setSearchData] = useState<SearchProps>({
    string: "",
    appName: ABSOLUTE_CORE_APP,
  });
  const lang_key = secureLocalStorage.getItem("site_lang") ?? "en";
  // const userAuth = useSelector(selectAuth);

  const userAuth = {
    role: SUPER_ADMIN,
  };

  localStorage.translationStrings =
    localStorage.translationStrings || JSON.stringify([]);

  useEffect(() => {
    getLocalTarget();
  }, [lang_key]);

  // Fetch language`````````aaaa data when the component mounts
  const getLocalTarget = async () => {
    const requestData = {
      appName: ABSOLUTE_CORE_APP,
      langKey: lang_key,
    };
    const response = await ApiCall.service(
      LOCALE_TARGETS,
      "POST",
      requestData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const initialLanguageData: LanguageData = {};
      setLanguageDropDown(response.languages);
      setLanguage(Object.keys(response.translations)[0]);
      Object.keys(response.translations).forEach((languageKey) => {
        initialLanguageData[languageKey] = {};
        Object.keys(response.translations[languageKey]).forEach(
          (translationKey) => {
            initialLanguageData[languageKey][translationKey] = {
              ...response.translations[languageKey][translationKey],
              isEditing: false, // Initialize the isEditing state
            };
          }
        );
      });
      setLanguageData(initialLanguageData);
    }
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLanguage(event.target.value);
  };

  const handleModalClose = () => setShowModal(!showModal);

  /**
   * edit, save, cancel action to handler
   * @param key
   * @param type
   */
  const handleEditClick = async (key: any, type: string) => {
    if (type === "save") {
      if (languageData[language][key].translation === "") {
        CustomNotify({
          type: "warning",
          message: t("Please provide translation"),
        });
        return;
      }
      const response = await ApiCall.service(
        SAVE_TRANSLATION,
        "POST",
        {
          translation: languageData[language][key].translation,
          key: key,
          appName: searchData.appName,
        },
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
      } else {
        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    }
    setLanguageData((prevLanguageData) => {
      const newLanguageData = {
        ...prevLanguageData,
        [language]: {
          ...prevLanguageData[language],
          [key]: {
            ...prevLanguageData[language][key],
            isEditing: !prevLanguageData[language][key].isEditing,
          },
        },
      };

      return newLanguageData;
    });
  };

  /**
   * Translation update in languageData
   * @param translationKey
   * @returns
   */
  const handleInputChange =
    (translationKey: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      setLanguageData((prevLanguageData) => {
        const newLanguageData = {
          ...prevLanguageData,
          [language]: {
            ...prevLanguageData[language],
            [translationKey]: {
              ...prevLanguageData[language][translationKey],
              translation: newValue,
            },
          },
        };

        return newLanguageData;
      });
    };

  /**
   * Page handler
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  let itemsPerPage = 5;
  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const entriesArray =
    languageData[language] &&
    Object.values(languageData[language]).sort((a, b) =>
      a.text.localeCompare(b.text, undefined, { sensitivity: "base" })
    );
  const currentItems =
    languageData[language] &&
    entriesArray.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages =
    languageData[language] &&
    Math.ceil(Object.keys(languageData[language]).length / itemsPerPage);

  let index = 0;

  const importFromCSV = async (importedData: any, file: any) => {
    if (!importedData || importedData.length === 0) {
      setErrorMsg(t("Please select a CSV file to upload."));
      return;
    }

    if (file.type !== "text/csv") {
      setErrorMsg(
        t("Invalid file format, pleases upload only .csv file format.")
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMsg(t("File size is greater than 10MB."));
      return;
    }

    const importedHeaders = importedData.data[0];

    if (!Object.keys(languageData).includes(importedHeaders[2])) {
      setErrorMsg(t("Imported CSV headers do not match the expected format."));
      return;
    }

    const missingDeveloperStrings: string[] = [];

    importedData.data.slice(1).forEach((row: any) => {
      const text = row[1];

      if (!text) {
        setErrorMsg(t("Empty developer string found in imported data."));
        return;
      }

      const matchingLanguage = Object.keys(languageData).find((language) =>
        Object.values(languageData[importedHeaders[2]]).some(
          (translation) => translation.text === text
        )
      );

      if (!matchingLanguage) {
        missingDeveloperStrings.push(text);
        return; // Skip further processing for this row
      }

      let id = NaN;
      if (row.length > 1) {
        for (const data in languageData[importedHeaders[2]]) {
          if (
            data &&
            languageData[importedHeaders[2]][data].text === text.toString()
          ) {
            id = languageData[importedHeaders[2]][data].id;
          }
        }

        const translation: Translation = {
          id: id,
          translation: row[index + 2],
          text,
          isEditing: false,
        };

        if (
          languageData &&
          languageData[importedHeaders[2]] &&
          languageData[importedHeaders[2]][translation.id]
        ) {
          languageData[importedHeaders[2]][translation.id] = translation;

          const updatedLanguageData = {
            ...languageData,
            [importedHeaders[2]]: {
              ...languageData[importedHeaders[2]],
              [translation.id]: translation,
            },
          };

          // Update the state with the new data
          setLanguageData(updatedLanguageData);
        }
      }
    });

    setErrorMsg("");

    let response = await ApiCall.service(
      UPDATE_TRANSLATIONS,
      "POST",
      {
        translations: languageData[importedHeaders[2]],
      },
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguage("EN");
      CustomNotify({
        type: "success",
        message: t(response.message) || t(response.msg),
      });
      setKey((prevKey) => prevKey + 1); // Update the key to force re-render
    }
  };

  const getLocaleStrings = async () => {
    const response = await ApiCall.service(
      LOCALE_TARGETS_SEARCH,
      "POST",
      searchData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguageData(response.translations);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    await getLocaleStrings();
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      string: "",
      appName: ABSOLUTE_CORE_APP,
    });
    setCurrentPage(1);
    getLocalTarget();
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption: any) => {
    setSearchData((prevData) => ({
      ...prevData,
      appName: selectedOption.value, // Update appName with selected option
    }));
  };

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Translation",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <div>
        <Header
          headerName={t("Manage Translations")}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-3">
          <div className="row">
            <BackLink backLink="/" />
          </div>
          <div className="row">
            <div className="col-8">
              <LabelWithInputField
                label={t("Developer strings")}
                name="string"
                handleChange={handleSearchChange}
                placeholder={t("Search by developer strings")}
                value={searchData.string}
                labelClassName="mb-2 colorPrimary poppins-medium"
              />
            </div>
            <div className="col-4">
              <div className="d-flex align-items-center seachBtnWrapper">
                <ResetBtn handleResetClick={handleReset} />
                <SearchBtn handleSearchClick={handleSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="managepagesBg mb-5">
          <div className="row">
            {/* <AccessControl
                requiredPermissions={[
                  {
                    permission: "Translation",
                    update: true,
                    create: true,
                  },
                ]}
                actions={true}
                strict={false}
              >
              </AccessControl> */}
            <div className="col-md-12">
              <CSVReader
                key={key}
                importFromCSV={importFromCSV}
                errorMsg={errorMsg}
              />
            </div>
          </div>
          <div className="row mb-3">
            {userAuth.role === SUPER_ADMIN && (
              <div className="col-lg-3 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                <SelectWithSearch
                  options={options}
                  search={false}
                  value={options.find(
                    (option) => option.value === searchData.appName
                  )}
                  onChange={handleSelectChange}
                />

                {/* <select
                  value={language}
                  onChange={handleApplicationChange}
                  className="border-0 form-select rounded-3 px-3 py-1 field-shadow select-field"
                >
                  {languageDropDown &&
                    languageDropDown
                      .sort((a, b) => a?.name.localeCompare(b.name)) // Sort the languages array by name
                      .map((language) => (
                        <option key={language.key} value={language.key}>
                          {language.name}
                        </option>
                      ))}
                </select> */}
              </div>
              //  <AccessControl
              //   requiredPermissions={[
              //     {
              //       permission: "Translation",
              //       update: true,
              //       create: true,
              //     },
              //   ]}
              //   actions={true}
              //   strict={false}
              // >
              //   {currentItems && currentItems.length > 0 && (
              //     <ExportCSV languageData={languageData} language={language} />
              //   )}
              // </AccessControl>
            )}
            <div className="col-6 ms-auto text-end">
              <Link to="/translation/link-app-language">
                <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                  {t("Link language")}
                </div>
              </Link>
            </div>
          </div>

          <OverFlowWrapper className="table-responsive manageTranslationWrapper">
            <table className="table customTableProperties">
              <thead>
                <tr className="TableHeader">
                  <th style={{ width: "42%" }}>{t("Developer strings")}</th>
                  <th style={{ width: "42%" }}>{t("Translations")}</th>
                  <th style={{ width: "15%" }}>{t("Action")}</th>
                  {/* <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Translation",
                            update: true,
                          },
                        ]}
                      >
                        <th className="ps-4">{t("Action")}</th>
                      </AccessControl> */}
                </tr>
                <tr>
                  <td
                    className="emptyRow p-0"
                    style={{ height: "0.7vw" }}
                    colSpan={3}
                  ></td>
                </tr>
              </thead>
              <tbody>
                {currentItems && currentItems.length > 0 ? (
                  Object.values(currentItems).map((data, key) => (
                    <tr
                      key={key}
                      className="border-bottom box-shadow mb-3 align-middle"
                    >
                      <td
                        className="text-break"
                        data-label={t("Developer String")}
                      >
                        {data.text}
                      </td>
                      <td className="text-break" data-label={t("Translation")}>
                        <InputTextfield
                          value={data.translation}
                          isDisabled={!data.isEditing}
                          handleChange={handleInputChange(data.id)}
                        />
                      </td>
                      {/* <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Translation",
                                update: true,
                              },
                            ]}
                          > */}
                      <td>
                        <div>
                          {data.isEditing ? (
                            <>
                              <span
                                title={t("Save")}
                                onClick={() => handleEditClick(data.id, "save")}
                                className="me-2 secondaryColorHoverEffect"
                              >
                                <Icon {...IconProps} name="SaveIcon" />
                              </span>
                              <span
                                title={t("Cancel")}
                                onClick={() =>
                                  handleEditClick(data.id, "cancel")
                                }
                                className="secondaryColorHoverEffect"
                              >
                                <Icon
                                  isIcon={true}
                                  width="0.9vw"
                                  height="0.9vw"
                                  name="close"
                                />
                              </span>
                            </>
                          ) : (
                            <span
                              title={t("Edit")}
                              onClick={() => handleEditClick(data.id, "edit")}
                              className="secondaryColorHoverEffect"
                            >
                              <Icon {...IconProps} name="EditIcon" />
                            </span>
                          )}
                        </div>
                      </td>
                      {/* </AccessControl> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="border text-danger text-center">
                      {t("No records")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </OverFlowWrapper>
          {totalPages > 1 && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      <ModalPopup
        show={showModal}
        handleClose={function (): void {
          throw new Error("Function not implemented.");
        }} // onHide={handleModalClose}
        // title={t("Delete confirmation")}
        // body={t("Are you sure want to delete") + "?"}
        // onCloseButtonClick={handleModalClose}
        // closeTitle={t("No")}
        // confirmTitle={t("Yes")}
      />
      {/* </AccessControl> */}
    </>
  );
};

export default translate(ManageTranslation);
