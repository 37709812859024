import React, { useEffect, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import {
  LocationFormData,
  fileData,
  formatHouseNumber,
  formatPostalCode,
  formatStreet,
  inputFields,
  intialFileData,
} from "./LocationUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  FILE_URL,
  GET_COUNTRIES,
  LOCATIONS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";

const AddLocation: React.FC = () => {
  // const [locationName, setLocationName] = useState("");
  const initialFormData: LocationFormData = {
    location_pic: "",
    location_name: "",
    street: "",
    number: "",
    postal_code: "",
    box: "",
    city: "",
    country: "",
    company_id: [],
  };
  const locationinputFields = inputFields;
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [fileDetails, setFileDetails] = useState<fileData>(intialFileData);
  const [formData, setFormData] = useState<LocationFormData>(initialFormData);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [countries, setCountries] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [url, setUrl] = useState<string | null>(null);

  const companiesObj = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
  ];

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const response = await ApiCall.getService(
      `${GET_COUNTRIES}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 200) {
      console.log(response.data);
      setCountries(response.data);
    }
  };
  // Handle input change for text fields
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof LocationFormData
  ) => {
    let value: any = "";
    switch (fieldKey) {
      case "country":
        setSelectedCountry(e);
        // value = e.iso;
        value = e?.iso || "";
        console.log(value);
        break;
      case "postal_code":
        value = formatPostalCode(e.target.value);
        break;
      case "number":
        value = formatHouseNumber(e.target.value);
        break;
      case "street":
        value = formatStreet(e.target.value);
        break;
      case "company_id":
        const selectedCompanyIds = e.map((company: any) => company.value);
        value = selectedCompanyIds;
        setCompanies(e);
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));

    // Clear the error for the updated field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey], // Remove the error if value is not empty
    }));
  };

  const handleFileUploadResponse = (uploadResponse: any, index: number) => {
    setFileDetails({
      fileId: uploadResponse.response[0],
      fileName: uploadResponse.response[1],
      filePath: uploadResponse.response[2],
    });
    const fileUrl = uploadResponse.response["file_url"];
    setFormData((prevData) => ({
      ...prevData,
      location_pic: uploadResponse.response[0] || "",
    }));
  };

  const handleFileDeleteResponse = (deleteResponse: any, index: number) => {
    console.log(deleteResponse);
    setFileDetails({
      fileId: 0,
      fileName: "",
      filePath: "",
    });
    setFormData((prevData) => ({
      ...prevData,
      location_pic: "",
    }));
  };

  const validateForm = () => {
    let errors: Record<string, string> = {};
    // Loop through the form fields to check for validation
    locationinputFields.forEach((field) => {
      if (field.isMandatory && !formData[field.name]) {
        console.log(!formData[field.name]);
        errors[field.name] = `${t(field.label)} is required`;
      }
    });

    return errors;
  };

  // Handle form submit (with validation)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the form submission if there are errors
    }
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        LOCATIONS,
        "POST",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode === 201) {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setFormData(initialFormData);
        setFileDetails(intialFileData);
      } else if (response.statusCode === 422 || response.statusCode === 500) {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving locations:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Add locationeeee")}
        headerClassName="myAccountCardTitle"
      />
      <form onSubmit={handleSubmit}>
        {fileDetails.filePath ? (
          <img
            src={
              fileDetails.filePath.startsWith("https")
                ? fileDetails.filePath
                : `${FILE_URL}/${fileDetails.filePath}`
            }
            alt={t("Uploaded")}
            style={{
              backgroundColor: "var(--color-white)",
              borderRadius: "50%",
              boxShadow: "rgb(248 216 235) 0.052vw 0vw 0.521vw 0.156vw",
            }}
            width="50%"
            height="50%"
          />
        ) : null}
        <FileUpload
          id="locations"
          name="location_picture"
          label="Upload"
          file_type={1}
          isMandatory={false}
          edit={false}
          fileId={fileDetails.fileId}
          fileName={fileDetails.fileName}
          filePath={fileDetails.filePath}
          multiple={false}
          uploadPath="locations"
          formats=".pdf,.docx,.doc,.jpg,.jpeg"
          maxFileSize={10 * 1024 * 1024}
          returnFileUploadErrors={(errorInfo) => {
            // Handle file upload errors
          }}
          fileUploadResponse={handleFileUploadResponse}
          deleteResponse={handleFileDeleteResponse}
        />
        {locationinputFields.map((field, index) =>
          field.type === "select" ? (
            <SelectWithSearch
              key={index}
              search={true}
              options={countries}
              value={selectedCountry}
              onChange={(e) => handleInputChange(e, field.name)}
              placeHolder={field.placeholder}
              isMulti={false}
              className="country-select"
              isTranslate={false}
              isMandatory={field.isMandatory}
              fieldLabel={t("Country")}
              title={t("Country")}
              error={formErrors[field.name]}
            />
          ) : (
            <InputTextFieldWithLabel
              key={index}
              type="text"
              className="location-input"
              placeholder={t(field.placeholder)}
              label={t(field.label)}
              value={formData[field.name]}
              name={field.name}
              id={field.id}
              handleChange={(e) =>
                handleInputChange(e, field.name as keyof LocationFormData)
              }
              isMandatory={field.isMandatory}
              labelClassName="form-label"
              colClassName="col-12"
              error={formErrors[field.name]}
            />
          )
        )}
        <SelectWithSearch
          // key={index}
          search={true}
          options={companiesObj}
          value={companies}
          // onChange={handleCompaniesChange}
          onChange={(selectedCompanies) =>
            handleInputChange(selectedCompanies, "company_id")
          }
          placeHolder={t("Select companies")}
          isMulti={true}
          className="country-select"
          isTranslate={false}
          isMandatory={true}
          fieldLabel={t("Companies")}
          title={t("Companies")}
        />
        <button type="submit" className="btn btn-primary">
          {t("Submit")}
        </button>
      </form>
    </>
  );
};

export default translate(AddLocation);
