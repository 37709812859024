import React from "react";

interface ImageWithTitleProps {
  title?: string;
  className?: string;
}
const Title: React.FC<ImageWithTitleProps> = ({ title, className }) => {
  return <label className={`${className}`}>{title}</label>;
};

export default Title;
