import React from "react";
import Icon from "../../Icon";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Image from "../common/atoms/Image";
import payHereIcon from "../../static/images/payHereIcon.png"; // Import the pay here icon image

interface InvoicesDataCardProps {
  isOutstanding: boolean;
  invoice: any;
  company: any;
  viewInvoice: (invoiceId: number) => void;
  downloadInvoice: (invoiceId: number) => void;
}

const InvoicesDataCard: React.FC<InvoicesDataCardProps> = ({
  isOutstanding,
  invoice,
  company,
  viewInvoice,
  downloadInvoice
}) => {
  const IconProps = {
    isIcon: true,
  };

  return (
    <>
      <table className="table table-borderless mb-0">
        <tbody>
          <tr>
            <td
              style={{
                width: "50%",
                borderTopLeftRadius: "0.8vw",
                borderBottomLeftRadius: "0.8vw",
              }}
              className="ps-4 text-break tableBg"
            >
              <div className="invoiceData">{t("Week")} {invoice.bright_id}</div>
              <div className="invoiceData">{t("Company")} "{company.official_name}"</div>
              <div className="invoiceData">{t("Listing")}: {invoice.invoice_number}</div>
            </td>
            <td
              style={{
                width: "35%",
                borderTopRightRadius: "0.8vw",
                borderBottomRightRadius: "0.8vw",
              }}
              className="align-middle tableBg"
            >
              <div className="d-flex align-items-center">
                <div className="colorPrimary me-4">
                  <span className="invoiceAmount">
                    €{new Intl.NumberFormat('de-DE').format(invoice.invoice_details.total)}
                  </span>
                  <sub className="poppins-semibold">incl btw</sub>
                </div>

                <div>
                  <span
                    className="me-3 cursor-pointer"
                    title={isOutstanding ? t("Pay here") : t("Paid")}
                    onClick={() => isOutstanding && viewInvoice(invoice.bright_id)}
                  >
                    {isOutstanding ? (
                      <Image src={payHereIcon} imageHeight="0.8vw" />
                    ) : (
                      <Icon
                        {...IconProps}
                        name="TickIcon"
                        width="1vw"
                        height="1vw"
                        className="secondaryColorHoverEffect cursor-pointer"
                      />
                    )}
                  </span>
                  <span className="me-3" title={t("View")} onClick={() => viewInvoice(invoice.bright_id)}>
                    <Icon
                      {...IconProps}
                      name="viewIcon"
                      width="1vw"
                      className="secondaryColorHoverEffect cursor-pointer"
                      height="1vw"
                    />
                  </span>
                  <span className="" title={t("Download")} onClick={() => downloadInvoice(invoice.bright_id)}>
                    <Icon
                      {...IconProps}
                      name="downloadIcon"
                      width="0.8vw"
                      height="0.8vw"
                      className="secondaryColorHoverEffect cursor-pointer"
                    />
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{ height: "0.7vw" }}
              className="emptyRow p-0"
            ></td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default translate(InvoicesDataCard);
