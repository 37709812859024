import React, { ReactNode, MouseEvent } from 'react';

interface LinkProps {
  title?: string;
  href?: string;
  className?: string;
  children?: ReactNode;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void; // Updated type here
  target?: string;
  rel?: string;
}

const Link: React.FC<LinkProps> = ({ title, href, className, children, onClick, target, rel }) => {
  return (
    <a href={href} className={className} onClick={onClick} target={target} rel={rel}>
      {title}
      {children}
    </a>
  );
};

export default Link;
