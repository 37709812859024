import { Suspense } from 'react';
import { ApiCall } from '../../../services/ApiServices';
import { useLoaderData, defer, Await } from 'react-router-dom';
import ManageRolesPage from './ManageRolesPage';
import { GET_ROLES, ROLES } from '../../../routes/ApiEndpoints';
import { t, translate } from '../../CentralDataMangement/translation/Translation';
import response from "./temroles.json";
import React from 'react'; // Add this line if it's missing


// Define the types for roles
type Role = {
    id: number;
    name: string;
    unique_name: string;
    created_at: string;
    updated_at: string;
    created_by: number | null;
    updated_by: number | null;
};

type LoaderData = {
    roles: Role[];
};

const ManageRoles = () => {
    const { roles } = useLoaderData() as LoaderData;

    return (
        <>
            <Suspense fallback={<p style={{ textAlign: 'center' }}>{t("Loading") + "..."}</p>}>
                <Await resolve={roles}>
                    {(loadedRoles) => <ManageRolesPage rolesObj={loadedRoles} />}
                </Await>
            </Suspense>
        </>
    );
}

export default translate(ManageRoles);

async function loadEvents() {
    // If using API call, uncomment below code
    const response = await ApiCall.getService(ROLES, "GET","central-data-management");
    if (response.status === 200) {
      return response.data['roles'];
    } else {
      return [];
    }
}

export function loader() {
    return defer({
        roles: loadEvents(),
    });
}
