import React from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory for navigation
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
import Link from "../common/atoms/Link";
// import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import LoadImage from "../../static/images/DummyImage.jpg";
import * as ROUTE from "../../routes/RouteConstants";
import { useSelector } from "react-redux";
import { selectPlanningShow, setShow } from "../../features/planning/planningSlice";
import PlanningTabs from "../Planning/PlanningTabs";
import { useDispatch } from "react-redux";
import { t , translate} from "../CentralDataMangement/translation/Translation";

const EmployeeOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const show = useSelector(selectPlanningShow); // Access the `show` state


  const IconProps = {
    isIcon: true,
    className: "secondaryColorHoverEffect",
  };

  const employees = [
    {
      id: 1,
      name: "John Doe",
      position: "Developer",
      location: "Vestiging 1",
      role: "Winkelbediende CATII",
      pc: "PC201",
      salary: "€12,50",
      employeeImage: LoadImage,
    },
    {
      id: 2,
      name: "Jane Smith",
      position: "Designer",
      location: "Vestiging 2",
      role: "Graphic Designer",
      pc: "PC202",
      salary: "€15,00",
      employeeImage: "", // No image provided, will default to UserImage
    },
  ];
  const handleEmployeeClick = (employee: any) => {
    // Fetch employee details (if needed)
    // Store details in state or global state

    // Redirect to the employee registration page
    navigate(`${ROUTE.EMPLOYEE_REGISTRATION}?id=${employee.id}`);
  };

  return (
    <>
      <Header headerName={t("Your pool")} />
      <div className="bg-white contentBg">
        <div className="row mb-3">
          <div className="col-4 d-flex">
            <Link className="me-3" href="">
              <span title={t("Home")}>
                <Icon
                  {...IconProps}
                  name="houseIcon"
                  width="1.3vw"
                  height="1.3vw"
                />
              </span>
            </Link>
            <AutoCompleteWithSearch placeholder={t("Search employee")} />
          </div>
          <div className="col-6">
            <Button
              title={t("Employee scheduling")}
              className="saveBtn me-3"
              handleClick={() => dispatch(setShow(true))} // Future implementation
            />
            <Button
              title={t("Add employee")}
              className="saveBtn me-3"
              handleClick={() => {}} // Future implementation
            />
            <Button
              title={t("Register employee")}
              className="saveBtn"
              handleClick={() => navigate(ROUTE.EMPLOYEE_REGISTRATION)} // Future implementation
            />
          </div>
          <div className="col-2 text-end">
            <div className="d-inline-block me-4 poppins-medium">
              {t("Total") + ":"}
              <span className="ms-1">{employees.length}</span>
            </div>
            <span onClick={() => {}}>
              <Icon {...IconProps} name="FilterIcon" width="1vw" height="1vw" />
            </span>
          </div>
        </div>
        <div className="employeeOverviewWrapper scrollBar">
        <div
          className="row"
        >
          {employees.length > 0 ? (
            employees.map((employee) => (
              <div className="col-3">
                <EmployeeDetailsCard
                key={employee.id}
                cardClassName="text-break text-start d-flex align-items-center justify-content-center"
                employeeInformationClass="planningEmployeeDetails"
                employeeName={employee.name}
                employeePosition={employee.position}
                employeeLocation={employee.location}
                employeeRole={employee.role}
                employeePC={employee.pc}
                employeeSalary={employee.salary}
                employeeImage={employee.employeeImage}
                isCardIcon={true}
                iconName="EditIcon"
                cardMainClassName="employeeDetailsCardPlanning colorPrimary position-relative h-100"
                iconClassName="text-end cardEditIcon position-absolute top-0 end-0 cursor-pointer"
                handleClick={() => handleEmployeeClick(employee)}
                favouriteIconName="starIcon"
                isFavourtite={true}
              />
              </div>
            ))
          ) : (
            <div className="no-employees">{t("No employees available")}</div>
          )}
        </div>
        </div>
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default translate(EmployeeOverview);
