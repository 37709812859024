import React, { useEffect, useState, useContext } from "react";
import { PcContext } from "./PcContext";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Icon";

const Table = (props) => {
  const [thvalues, setThvalues] = useState([]);
  const [tvalues, setTvalues] = useState([]);
  const [type, setType] = useState("");
  const {
    pc_unique_key,
    setPc_unique_key,
    current_sec,
    cat_rightsec,
    setCat_rightsec,
    cat_leftsec,
    setCat_leftsec,
    cat_subsec_type,
    setCat_subsec_type,
    cat_fun_updated,
    setCat_fun_updated,
    sec_completed,
    setSec_completed,
    cat_subsec_id,
    setCat_subsec_id,
  } = useContext(PcContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.header !== undefined) {
      setThvalues(props.header);
    }
    if (props.tvalue !== undefined) {
      setTvalues(props.tvalue);
    }
    if (props.type) {
      setType(props.type);
    }
  }, [props]);
  let editCatOrFun = () => {
    if (props.sectype === "cat") {
      setCat_subsec_type(1);
      setCat_leftsec("col-xl-8 col-lg-12 col-md-12");
      setCat_rightsec("d-block col-xl-4 col-lg-12 col-md-12");
      setCat_subsec_id(props.secId);
    } else if (props.sectype === "funct") {
      setCat_subsec_type(2);
      setCat_leftsec("col-xl-8 col-lg-12 col-md-12");
      setCat_rightsec("d-block col-xl-4 col-lg-12 col-md-12");
      setCat_subsec_id(props.secId);
    }
  };

  let editSec = (type) => {
    switch (type) {
      case "pc":
        setCat_subsec_type(3);
        setCat_subsec_id(props.secId);
        break;
      case "age":
        setCat_subsec_type(4);
        break;
      case "emp_type":
        setCat_subsec_type(5);
        break;
      case "sal_benifts":
        setCat_subsec_type(6);
      case "age_restriction":
        setCat_subsec_type(7);
        break;
    }
    if (type !== "sal_benifts") {
      setCat_leftsec("col-xl-8 col-lg-12 col-md-12");
      setCat_rightsec("d-block col-xl-4 col-lg-12 col-md-12");
    }
    // setCat_subsec_id(props.secId);
  };
  const Iconprops = {
    isIcon: true,
    width: "1.2vw",
    height: "1.2vw",
  };
  
  return (
    <div>
      {tvalues && thvalues && (
        <table className="table table-borderless bodyBg">
          <tbody>
            <tr>
              {console.log(thvalues)}
              {/* {thvalues.map((th_val, index) => ( */}
              <td className="align-middle ps-lg-4">
                <span className="poppins-medium">{thvalues[0]}</span>
                <br />
                <span>{tvalues[0]}</span>
              </td>
              <td className="align-middle">
               <span className="poppins-medium"> {thvalues[1]}</span>
                <br />
                <span>{tvalues[1]}</span>
              </td>

              {/* ))} */}
              {/* {tvalues.map((tval, key) => (
								<td className="fw-bold text-break poppins-regular-18px" key={key}>
									{tval}
								</td>
							))} */}
              <td className="editPcAction align-middle">
                {/* <div onClick={() => {editCatOrFun()}} className="h5">
									{props.sectype != 'pc' && props.type == "addpc" && <FaEdit />}
								</div> */}
                <div
                  onClick={() => {
                    editSec("pc");
                  }}
                  className="mb-2"
                >
                  {props.type === "editpc" && props.sectype === "pc" && (
                    <span
                      title={
                        props.type === "editpc" ? t("Edit PC") : t("View PC")
                      }
                      className="cursor-pointer"
                    >
                      <Icon
                        {...Iconprops}
                        name={
                          cat_subsec_type === 3 ? "EditIconActive" : "EditIcon"
                        }
                      />
                    </span>
                  )}
                </div>
                <div
                  onClick={() => {
                    editCatOrFun();
                  }}
                  className="mb-1"
                >
                  {props.sectype === "cat" &&
                    (props.type === "addpc" || props.type === "editpc") && (
                      <span title={t("Edit category")} className="cursor-pointer">
                        <Icon
                          name={
                            props.sectype === "cat" &&
                            cat_subsec_id === props.secId
                              ? "EditIconActive"
                              : "EditIcon"
                          }
                          {...Iconprops}
                        />
                      </span>
                    )}
                  {props.sectype === "funct" &&
                    (props.type === "addpc" || props.type === "editpc") && (
                      <span title={t("Edit function")} className="cursor-pointer">
                        <Icon
                          name={
                            props.sectype === "cat" &&
                            cat_subsec_id === props.secId
                              ? "EditIconActive"
                              : "EditIcon"
                          }
                          {...Iconprops}
                        />
                      </span>
                    )}
                </div>
                <div
                  onClick={() => {
                    editSec("age");
                  }}
                  className="mb-1"
                >
                  {props.sectype === "pc" && props.type !== "addpc" && (
                    <span
                      title={
                        props.type === "editpc" ? t("Edit age") : t("View age")
                      }
                      className="cursor-pointer"
                    >
                      <Icon
                        {...Iconprops}
                        name={
                          cat_subsec_type === 4 ? "ageIconActive" : "ageIcon"
                        }
                      />
                    </span>
                  )}
                </div>
                <div
                  onClick={() => {
                    editSec("emp_type");
                  }}
                  className="mb-1"
                >
                  {props.sectype === "pc" && props.type !== "addpc" && (
                    <span
                      title={
                        props.type === "editpc"
                          ? t("Edit employee type")
                          : t("View employee type")
                      }
                      className="cursor-pointer"
                    >
                      <Icon
                        {...Iconprops}
                        name={
                          cat_subsec_type === 5
                            ? "editEmployeeTypeActive"
                            : "editEmployeeType"
                        }
                      />
                    </span>
                  )}
                </div>
                <div
                  onClick={() => {
                    editSec("sal_benifts");
                  }}
                  className="mb-1"
                >
                  {props.sectype === "pc" && props.type !== "addpc" && (
                    <span
                      title={
                        props.type === "editpc"
                          ? t("Edit salary benefits")
                          : t("View salary benefits")
                      }
                      onClick={() => {
                        props.type === "editpc"
                          ? navigate("/salary-benefits/edit?k=" + pc_unique_key)
                          : navigate(
                              "/salary-benefits/view?k=" + pc_unique_key
                            );
                      }}
                      className="cursor-pointer"
                    >
                      <Icon
                        {...Iconprops}
                        name={
                          cat_subsec_type === 6
                            ? "salaryBenefitsActive"
                            : "salaryBenefits"
                        }
                      />
                    </span>
                  )}
                </div>
                <div
                  onClick={() => {
                    editSec("age_restriction");
                  }}
                  className="mb-1"
                >
                  {props.sectype === "pc" && props.type !== "addpc" && (
                    <span
                      title={
                        props.type === "editpc"
                          ? t("Edit end time according to age")
                          : t("View end time according to age")
                      }
                      className="cursor-pointer"
                    >
                      <Icon
                        {...Iconprops}
                        name={
                          cat_subsec_type === 7 ? "timeIconActive" : "timeIcon"
                        }
                      />
                    </span>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default translate(Table);
