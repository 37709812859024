import React, { ChangeEvent } from "react";

interface Props {
  type?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  value?: string | undefined;
}

const Search: React.FC<Props> = ({
  type = "search",
  placeholder = "",
  handleChange,
  className,
  name = "",
  value = "",
}) => {
  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        className={`${className} form-control inputFieldColor field-shadow ps-2`}
        name={name}
        value={value}
      />
    </>
  );
};

export default Search;
