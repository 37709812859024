import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import LabelField from "../../common/atoms/LabelField";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabelWithSelectField from "../../common/molecules/LabelwithSelectField";
import ModalPopup from "../../common/atoms/ModalPopup";
import Button from "../../common/atoms/Button";
import CustomNotify from "../../common/atoms/CustomNotify";
import { Link, useNavigate } from "react-router-dom";
import { ApiCall } from "../../../services/ApiServices";
import {
  ABSOLUTE_CORE_APP,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
} from "../../../Constants";
import { GET_ALL_APP_LANG, LINK_APP_LANG } from "../../../routes/ApiEndpoints";
import { t, translate } from "./Translation";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import BackLink from "../../common/atoms/BackLink";
import "./translation.css";
interface Option {
  label: string;
  value: any;
}

interface AddLanguageProps {
  languages: { id: number; name: string }[];
  appLanguages: {
    app_name: string;
    app_languages: { language_id: number; language_name: string }[];
  }[];
}

interface OptionProps {
  value?: number;
  name?: any;
}

const LinkAppLanguages: React.FC = () => {
  const [selectedApp, setSelectedApp] = useState<string | null>(
    ABSOLUTE_CORE_APP
  );
  const [showModal, setShowModal] = useState(false);
  const [optionList, setOptionList] = useState<OptionProps[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<number[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState<AddLanguageProps>({
    languages: [],
    appLanguages: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFormData = async () => {
      const response = await ApiCall.getService(
        GET_ALL_APP_LANG,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        const { languages, app_langs } = response.data; // Adjust this based on your actual response structure

        // Map app languages to Option format
        const updatedOptionList = app_langs
          .sort((a: any, b: any) => (a["app_name"] > b["app_name"] ? 1 : -1))
          .map((item: any) => ({
            value: item.app_name,
            name: item.app_name,
          }));
        setOptionList(updatedOptionList);
        setFormData({
          languages,
          appLanguages: app_langs,
        });
      }
    };
    fetchFormData();
  }, []);

  useEffect(() => {
    const mappedSelectedOptions =
      formData.appLanguages
        .find((app) => app.app_name === selectedApp)
        ?.app_languages.map((language) => ({
          label: language.language_name, // Use correct property based on actual data
          value: language.language_id, // Convert ID to string
        })) || [];

    setSelectedOptions(mappedSelectedOptions);

    const selectedLanguageIds = mappedSelectedOptions.map(
      (option) => option.value
    );
    setSelectedLanguages(selectedLanguageIds);
  }, [formData.appLanguages, selectedApp]);

  const handleModalClose = () => setShowModal(false);

  const updateAppLangs = async () => {
    setShowModal(false);
    let res = await ApiCall.service(
      LINK_APP_LANG,
      "POST",
      { appLangs: selectedLanguages, app_name: selectedApp },
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (res.status === 200) {
      CustomNotify({ type: "success", message: t(res.message) });
      navigate("/translation/manage");
    } else {
      CustomNotify({ type: "error", message: t(res.message) });
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value) {
      setSelectedApp(event.target.value);
      setError(false);
    } else {
      setError(true);
    }
  };
  

  const languageOptions = formData.languages.map((language) => ({
    label: language.name,
    value: language.id, // Convert ID to string
  }));

  const handleSelectedChange = (selected: Option[]) => {
    setSelectedOptions(selected);
    const selectedLanguageIds = selected.map((option) => option.value);

    setSelectedLanguages(selectedLanguageIds);
  };

  const handleRemoveSelected = (
    event: React.MouseEvent<Element, MouseEvent>,
    value: number | null
  ) => {
    event.stopPropagation();
    const updatedSelectedLanguages = selectedLanguages.filter(
      (langId) => langId !== value
    );
    setSelectedLanguages(updatedSelectedLanguages);
    const updatedSelectedOptions = selectedOptions.filter(
      (option) => option.value !== value
    );
    setSelectedOptions(updatedSelectedOptions);

    formData.appLanguages.map((app) => {
      if (app.app_name === selectedApp) {
        // Update the app_languages property for the selected app_name
        const updatedAppLanguages = app.app_languages.filter(
          (language) => language.language_id !== value
        );
        return { ...app, app_languages: updatedAppLanguages };
      }
      return app;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedApp) {
      setShowModal(true);
    } else {
      setError(true);
    }
  };

  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Translation",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <Header
        headerName={t("Link application languages")}
        headerClassName="myAccountCardTitle"
      />
      <form onSubmit={handleSubmit} className="manageContentBg">
        <div className="row">
          <BackLink backLink="/translation/manage" />
          <div className="col-md-12 applicationName mb-3">
            <LabelWithSelectField
              options={optionList}
              label={t("Application name")}
              isMandatory={true}
              placeholder={t("Select")}
              selectedValue={selectedApp || ""}
              handleChange={handleSelectChange}
              name="app_name"
              className="text-capitalize mb-1"
              error={error ? t("Please select application name") : ""}
            />
          </div>
          <div className="col-md-12 pb-5 linkLanguagesSelect">
            <LabelField title={t("Select languages")} isMandatory={false} />
            <div className="custom-multi-select w-100">
              <MultiSelect
                options={languageOptions}
                className={`select-field inputFieldColor`}
                value={selectedOptions}
                onChange={handleSelectedChange}
                labelledBy="Select"
                hasSelectAll={false}
                disabled={!selectedApp}
                disableSearch={false}
                // isOpen ={true}
                overrideStrings={{ selectSomeItems: t("Select options") }}
                valueRenderer={(selected: Option[], options: Option[]) => (
                  <div className="selected-options-container cursor-pointer">
                    {selected.map((selectedOption) => (
                      <span
                        key={selectedOption.value}
                        className="me-2 my-2 border px-3 py-1 selectedLanguages"
                      >
                        <span className="me-2 color-dark-purple">
                          {selectedOption.label}
                        </span>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="cursor-pointer"
                          onClick={(event) =>
                            handleRemoveSelected(event, selectedOption.value)
                          }
                        />
                      </span>
                    ))}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="col-12">
            <Button
              title={t("Save")}
              handleClick={handleSubmit}
              type="submit"
              className="searchBtn float-end"
            />
          </div>
        </div>
      </form>
      <div className="row">
        <ModalPopup
          //handleClose={function (): void {
          //   throw new Error("Function not implemented.");
          // } }
          show={showModal}
          handleClose={handleModalClose}
          modalTitle={`${t("Link/unlink")} ${selectedApp} ${t("languages")}`}
          body={`${t("Are you sure you want to link/unlink the language?")}`} //${deleteLanguage.language_name}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={() => updateAppLangs()}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </div>
      {/* </AccessControl> */}
    </>
  );
};

export default translate(LinkAppLanguages);
