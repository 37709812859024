import React, { useState } from "react";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import AccordionCustom from "../common/atoms/AccordionCustom";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";

interface RadioOption {
  id: string;
  label: string;
}

interface RadioGroupProps {
  name: string;
  options: RadioOption[];
  title?: string;
  containerClassName?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  options,
  title,
  containerClassName,
}) => (
  <div className={`col-3 mb-3 ${containerClassName}`}>
    <div className="mb-2">{title && <LabelField title={t(title)} />}</div>
    {options.map((option) => (
      <RadioField
        key={option.id}
        name={name}
        label={option.label}
        className="radioBtn"
        ischecked={false}
        handleChange={() => {}}
        id={option.id}
        containerClassName="companyLanguage me-3"
      />
    ))}
  </div>
);

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  id: string;
  value: string;
  error?: string;
  className?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  id,
  value,
  error,
  className,
}) => (
  <div className="col-3 mb-3">
    <LabelWithInputField
      type={type}
      label={t(label)}
      value={value}
      name={name}
      id={id}
      handleChange={() => {}}
      error={error}
      className={`ps-2 ${className}`}
    />
  </div>
);

const ContactPerson: React.FC = () => {
  const radioOptionsYesNo: RadioOption[] = [
    { id: "yes", label: t("Yes") },
    { id: "no", label: t("No") },
  ];

  const languageOptions: RadioOption[] = [
    { id: "langDutch", label: t("Dutch") },
    { id: "langFrench", label: t("French") },
  ];

  const [isLastTab, setIsLastTab] = useState(false);
  const handleNext = () => {
    console.log("Next clicked");
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };

  return (
    <>
      <div style={{minHeight:"calc(100vh - 22vw)"}}>
        <div className="d-flex">
          <AccordionCustom
            accordionTitle={t("Name")}
            accordionTitleClassName="w-100 poppins-medium dashboardAccordionTitle"
            accordionButton="dashboardAccordionHeader shadow-none"
          >
            <div className="row">
              <RadioGroup
                name="yesNo"
                options={radioOptionsYesNo}
                title="Title"
                containerClassName="companyLanguage"
              />
              <InputField
                label="First name"
                type="text"
                name="firstName"
                id="firstName"
                value=""
                error=""
                className="ps-2"
              />
              <InputField
                label="Last name"
                type="text"
                name="lastName"
                id="lastName"
                value=""
                error=""
                className="ps-2"
              />
              <InputField
                label="Function title"
                type="text"
                name="functionTitle"
                id="functionTitle"
                value=""
                error=""
                className="ps-2"
              />
              <InputField
                label="Phone number"
                type="tel"
                name="phoneNo"
                id="phoneNo"
                value=""
                error=""
                className="ps-2"
              />
              <InputField
                label="Mobile number"
                type="tel"
                name="mobileNo"
                id="mobileNo"
                value=""
                error=""
                className="ps-2"
              />
              <InputField
                label="Email address"
                type="email"
                name="email"
                id="email"
                value=""
                error=""
                className="ps-2"
              />
              <div className="col-3 position-relative agreementStartDate">
                <Calender onChange={() => {}} label={t("Date of birth")} />
              </div>
              <div className="col-3 multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel={`${t("Location")}`}
                  title={t("Location")}
                  name="country"
                  search
                  options={[]}
                  value={() => {}}
                  onChange={() => {}}
                  isMulti={false}
                  className="select-field"
                  error=""
                  isTranslate
                  placeHolder={t("Select")}
                />
              </div>
              <RadioGroup
                name="language"
                options={languageOptions}
                title="Language"
                containerClassName="companyLanguage"
              />
              <InputField
                label="To be contacted for"
                type="text"
                name="contactedFor"
                id="contactedFor"
                value=""
                error=""
                className="ps-2"
              />
              <RadioGroup
                name="decisionMaker"
                options={radioOptionsYesNo}
                title="Decision maker"
                containerClassName="companyLanguage"
              />
              <RadioGroup
                name="influencer"
                options={radioOptionsYesNo}
                title="Influencer"
                containerClassName="companyLanguage"
              />
              <RadioGroup
                name="canBeCalled"
                options={radioOptionsYesNo}
                title="Contact person can be called"
                containerClassName="companyLanguage"
              />
              <RadioGroup
                name="canBeMailed"
                options={radioOptionsYesNo}
                title="Contact person can be mailed"
                containerClassName="companyLanguage"
              />
            </div>
          </AccordionCustom>
          <span
            onClick={() => {}}
            title={t("Delete contact person")}
            className="secondaryColorHoverEffect mt-2 pt-1"
          >
            <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
          </span>
        </div>

        <div className="multiSelectWrapper mt-3">
          <SelectWithSearch
            fieldLabel={`${t("Select contact person")}:`}
            title={t("Select contact person")}
            name="contactPerson"
            search
            options={[]}
            value={() => {}}
            onChange={() => {}}
            isMulti={false}
            className="select-field"
            error=""
            isTranslate
            placeHolder={t("Select")}
          />
        </div>
      </div>

      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </>
  );
};

export default ContactPerson;
