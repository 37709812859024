import React from "react";
import Icon from "../../../Icon";
import AutoCompleteDropdown from "../atoms/AutoCompleteDropdown";

interface AutoCompleteWithSearchProps {
  placeholder?: string;

}
const AutoCompleteWithSearch:React.FC<AutoCompleteWithSearchProps> = ({
  placeholder,
}) => {
  return (
    <div className="d-flex justify-content-end align-items-center autoCompleteWithSearchWrapper">
      <AutoCompleteDropdown
        onChange={() => {}}
        microService=""
        value={""}
        modelKey=""
        placeholder={placeholder}
        className=""
      />

      <Icon
        name="searchIcon"
        fillColor="#8571b9"
        width="1vw"
        height="1vw"
        className="ms-3"
        isIcon={true}
      />
    </div>
  );
};

export default AutoCompleteWithSearch;
