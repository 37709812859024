import React, { useEffect, useState } from "react";
import LabelField from "../common/atoms/LabelField";
import Header from "../common/layout/Header";
import "./css/invoices.css";
import InvoicesDataCard from "./InvoicesDataCard";
import InvoiceSettings from "./InvoiceSettings";
import InvoicesEnquiry from "./InvoicesEnquiry";
import OverFlowWrapper from "../common/OverFlowWrapper";
import PdfViewer from "../common/molecules/PdfViewer";
import { ApiCall } from "../../services/ApiServices";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { GET_ALL_INVOICES, GET_INVOICE } from "../../routes/ApiEndpoints";
import CommonServices from "../../services/common/CommonServices";
import AccordionCustom from "../common/atoms/AccordionCustom";
import payHereIcon from "../../static/images/payHereIcon.png";
import Image from "../common/atoms/Image";

const InvoiceOverview = () => {
  interface Data {
    paid_invoices: Array<any>;
    unpaid_invoices: Array<any>;
    outstanding_amount: string;
    company: object;
  }

  const [data, setData] = useState<Data>({
    paid_invoices: [],
    unpaid_invoices: [],
    outstanding_amount: "",
    company: {}
  });

  const [pdfUrls, setPdfUrls] = useState<Record<number, string | any>>({});
  const [showPdf, setShowPdf] = useState<string | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string | null>(null);
  const id = 15879;

  useEffect(() => {
    async function fetchData() {
      const url = `${GET_ALL_INVOICES}/${id}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        false
      );
      setData({
        paid_invoices: Object.values(response.data.paid_invoices),
        unpaid_invoices: Object.values(response.data.unpaid_invoices),
        outstanding_amount: new Intl.NumberFormat('de-DE').format(response.data.outstanding_amount),
        company: response.data.unpaid_invoices[0]?.company || {}
      });
    }
    fetchData();
  }, []);

  const fetchInvoicePdf = async (invoiceId: number) => {
    if (pdfUrls[invoiceId]) {
      return pdfUrls[invoiceId];
    }
    try {
      const url = `${GET_INVOICE}/${invoiceId}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        false
      );
      setPdfUrls(prevUrls => ({ ...prevUrls, [invoiceId]: response.data.url }));
      return response.data.url;
    } catch (error) {
      console.error("Error fetching invoice:", error);
      return null;
    }
  };

  const viewInvoice = async (invoiceId: number) => {
    const url = await fetchInvoicePdf(invoiceId);
    if (url) {
      const invoice = [...data.paid_invoices, ...data.unpaid_invoices].find(invoice => invoice.bright_id === invoiceId);
      setInvoiceNumber(invoice?.invoice_number || '');
      setShowPdf(url);
    }
  };

  const downloadInvoice = async (invoiceId: number) => {
    const url = await fetchInvoicePdf(invoiceId);
    if (url) {
      try {
        const invoice = [...data.paid_invoices, ...data.unpaid_invoices].find(invoice => invoice.bright_id === invoiceId);
        const invoiceNumber = invoice?.invoice_number || 'Unknown';
        await CommonServices.downloadFile(url, invoiceNumber);
      } catch (error) {
        console.error('Error downloading the invoice:', error);
      }
    }
  };

  return (
    <>
      <Header headerName={t("Invoices")} headerClassName="inVoiceHeader" />

      <div className="row equal-cols">
        <div className="col-8">
          {showPdf ? (
            <div className="contentBg">
              <OverFlowWrapper className="invoiceLeftWrapper">
                <PdfViewer fileUrl={showPdf} setShowPdf={setShowPdf} invoiceNumber={invoiceNumber} />
              </OverFlowWrapper>
            </div>
          ) : (
            <>
              <div className="contentBg ">
                <OverFlowWrapper className="invoiceLeftWrapper">
                  <LabelField
                    title={t("Outstanding invoices")}
                    className="inVoiceHeader colorPrimary poppins-semibold mb-3"
                  />
                  <div className="mb-3">
                    {[...data.unpaid_invoices].reverse().map((invoice: any) => (
                      <InvoicesDataCard
                        key={invoice.id}
                        isOutstanding={true}
                        invoice={invoice}
                        company={data.company}
                        viewInvoice={() => viewInvoice(invoice.bright_id)}
                        downloadInvoice={downloadInvoice}
                      />
                    ))}
                  </div>
                  <AccordionCustom
                    accordionTitle={t("Show paid invoices")}
                    accordionTitleClassName="colorPrimary poppins-semibold"
                    accordionButton="bg-transparent shadow-none align-items-center px-0"
                  >
                    <div className="mb-3">
                      {data.paid_invoices.map((invoice: any) => (
                        <InvoicesDataCard
                          key={invoice.id}
                          isOutstanding={false}
                          invoice={invoice}
                          company={data.company}
                          viewInvoice={() => viewInvoice(invoice.bright_id)}
                          downloadInvoice={downloadInvoice}
                        />
                      ))}
                    </div>
                  </AccordionCustom>
                </OverFlowWrapper>
              </div>
            </>
          )}
        </div>
        <div className="col-4">
          <div className="contentBg">
            <LabelField
              title={t("Total Amount")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
            />
            <div>
              <div className="colorPrimary me-4 mb-3">
                <span className="totalInvoiceAmount">€{data.outstanding_amount}</span>
                <span className="poppins-semibold ms-2" style={{ fontSize: "0.9vw" }}>incl btw</span>
              </div>
              <div>
                <span className="payHere me-3">{t("Pay here")}</span>
                <span className="">
                  <Image src={payHereIcon} imageHeight="1vw" />
                </span>
              </div>
            </div>
          </div>
          <div className="contentBg mt-5">
            <LabelField
              title={t("Manage your invoices")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
            />
            <InvoiceSettings invoiceTitle={t("Manage invoice settings")} />
            <InvoicesEnquiry
              documentsTitle={
                t("Do you have questions about your invoice") + "?"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(InvoiceOverview);
