import React, { useState } from "react";
import Image from "../common/atoms/Image";
import userIcon from "../../static/images/User.svg";
// import { t } from "../common/atoms/translation";
import Button from "../common/atoms/Button";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import LabelField from "../common/atoms/LabelField";
import ToggleButton from "../common/atoms/ToggleButton";
import { t , translate} from "../CentralDataMangement/translation/Translation";

const EmployeeProfile = () => {
  return (
    <>
      <div className="row mb-3">
        {/* Profile image */}
        <div className="col-2 align-self-center">
          <div
            className="addCompanyImage d-flex align-items-center justify-content-center cursor-pointer"
            onClick={() => {}}
          >
            <Image src={userIcon} imageWidth="3vw" imageHeight="3vw" />
          </div>
        </div>

        {/* User details */}
        <div className="col-10">
          <div className="row">
            <div className="col-4">
              <div className="row multiSelectWrapper mb-3">
                <SelectWithSearch
                  fieldLabel={t("Initials") + ":"}
                  title={t("Initials")}
                  name="Initials"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={[]}
                  value={() => {}}
                  onChange={() => {}}
                  isMulti={false}
                  className="select-field"
                  error=""
                  isTranslate={true}
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="row mb-3">
                <LabelWithInputField
                  label={t("First name") + ":"}
                  className="inputFieldColor"
                  colClassName="col-8"
                  type="text"
                  value={""}
                  name=""
                  isMandatory={true}
                  id="firstName"
                  labelWrapperClassName="col-4 align-self-center"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="row mb-3">
                <LabelWithInputField
                  label={t("Last name") + ":"}
                  className="inputFieldColor"
                  colClassName="col-8"
                  type="text"
                  value={""}
                  name=""
                  isMandatory={true}
                  id="lastName"
                  labelWrapperClassName="col-4 align-self-center"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="row mb-3">
                <div className="col-4 align-self-center">
                  <LabelField
                    title={t("Date of birth") + ":"}
                    isMandatory={true}
                  />
                </div>
                <div className="col-8 position-relative">
                  <Calender onChange={() => {}}  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row mb-3 multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel={t("Gender") + ":"}
                  title={t("Gender")}
                  name="gender"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={[]}
                  value={() => {}}
                  onChange={() => {}}
                  isMulti={false}
                  className="select-field"
                  error=""
                  isTranslate={true}
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="row mb-3 multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel={t("Language") + ":"}
                  title={t("Language")}
                  name="gender"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={[]}
                  value={() => {}}
                  onChange={() => {}}
                  isMulti={false}
                  className="select-field"
                  error=""
                  isTranslate={true}
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 multiSelectWrapper">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Nationality") + ":"}
              title={t("Nationality")}
              name="nationality"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={[]}
              value={() => {}}
              onChange={() => {}}
              isMulti={false}
              className="select-field"
              error=""
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <LabelWithInputField
              label={t("National registry number") + ":"}
              className="inputFieldColor"
              colClassName="col-8 d-flex align-items-center"
              type="text"
              value={""}
              name=""
              id="firstName"
              labelWrapperClassName="col-4 align-self-center"
            >
              <Button
                title={t("Verify")}
                className="saveBtn ms-3"
                handleClick={() => {}}
              />
            </LabelWithInputField>
          </div>
        </div>
        <div className="col-4 d-flex align-items-center">
          <LabelField
            title={
              t("Does the employee have a nationality within the EU") + ":"
            }
            className="mb-0 colorPrimary poppins-medium me-3"
          />
          <ToggleButton />
        </div>
      </div>
      <div className="row">
        <div className="col-4 multiSelectWrapper mb-3">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Date of birth") + ":"}
              title={t("Date of birth")}
              name="date of birth"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={[]}
              value={() => {}}
              onChange={() => {}}
              isMulti={false}
              className="select-field"
              error=""
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Place of birth") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={""}
              name=""
              id="pob"
              labelWrapperClassName="col-4 align-self-center"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Country of birth") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={""}
              name=""
              id="lastName"
              labelWrapperClassName="col-4 align-self-center"
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper mb-3">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Marital status") + ":"}
              title={t("Marital status")}
              name="MaritalStatus"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={[]}
              value={() => {}}
              onChange={() => {}}
              isMulti={false}
              className="select-field"
              error=""
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper mb-3">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Dependant spouse") + ":"}
              title={t("Dependant spouse")}
              name="DependantSpouse"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={[]}
              value={() => {}}
              onChange={() => {}}
              isMulti={false}
              className="select-field"
              error=""
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper mb-3">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Dependant children") + ":"}
              title={t("Dependant children")}
              name="DependantChildren"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={[]}
              value={() => {}}
              onChange={() => {}}
              isMulti={false}
              className="select-field"
              error=""
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Account number") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={""}
              name=""
              id="accountNo"
              labelWrapperClassName="col-4 align-self-center"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={t("IBAN country") + ":"}
              title={t("IBAN country")}
              name="DependantChildren"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={[]}
              value={() => {}}
              onChange={() => {}}
              isMulti={false}
              className="select-field"
              error=""
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("IBAN number") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={""}
              name=""
              id="ibanNumber"
              labelWrapperClassName="col-4 align-self-center"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("BIC") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={""}
              name=""
              id="bic"
              labelWrapperClassName="col-4 align-self-center"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(EmployeeProfile);
