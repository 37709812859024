import React from "react";
import Button from "../common/atoms/Button";
import { t } from "../common/atoms/Constants";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { Option } from "../common/utlis/TypeAnnotations";
import PCTable from "./PCTable";

interface SectionProps {
  optionsList: any[];
  selectedValue: Option | null;
  selectedEmpType: Option | null;
  handlePCFields: (e: React.ChangeEvent, name: string) => void;
  state: any; // Add the state type
  addParitiarComitee: (type: string) => void;
  pcChangeHandler: (id: number, type: string) => void;
  pcDeleteHandler: (id: number, type: string) => void;
  employeeTypes: any[];
  isBlueCollar: boolean;
  isWhiteCollar: boolean;
}

const ParitiarCommitteeSection: React.FC<SectionProps> = ({
  optionsList,
  selectedValue,
  selectedEmpType,
  handlePCFields,
  state,
  addParitiarComitee,
  pcChangeHandler,
  pcDeleteHandler,
  employeeTypes,
  isBlueCollar,
  isWhiteCollar,
}) => {
  return (
    <div>
      {isBlueCollar && (
        <>
          <h5 className="mb-1">
            <LabelField title={t("Paritair committee for blue collar")} />
          </h5>
          <PCTable
            data={[]}
            pcChangeHandler={pcChangeHandler}
            type="blueCollar"
            pcDeleteHandler={pcDeleteHandler}
          />
        </>
      )}
      
      {isWhiteCollar && (
        <>
          <h5 className="mb-1">
            <LabelField title={t("Paritair committee for white collar")} />
          </h5>
          <PCTable
            data={[]}
            pcChangeHandler={pcChangeHandler}
            type="whiteCollar"
            pcDeleteHandler={pcDeleteHandler}
          />
        </>
      )}
      
      {/* {state.fieldError.paritairBlue && isBlueCollar && (
        <div className="text-danger">{state.fieldError.paritairBlue}</div>
      )}
      {state.fieldError.paritairWhite && isWhiteCollar && (
        <div className="text-danger">{state.fieldError.paritairWhite}</div>
      )} */}

      <div className="row">
        {isBlueCollar && (
          <div className="mb-3">
            <SelectWithSearch
              isMulti={false}
              isMandatory={true}
              search={true}
              options={optionsList}
              value={selectedValue}
              onChange={() => {}}
              name="PC2"
              title={t("Paritair committee (PC) 2")}
              error=""
              isTranslate={true}
            />
          </div>
        )}

        {isWhiteCollar && (
          <div className="mb-3">
            <SelectWithSearch
              isMulti={false}
              isMandatory={true}
              search={true}
              options={optionsList}
              value={selectedValue}
              onChange={() => {}}
              name="PC"
              title={t("Paritair committee (PC) 1")}
              error=""
              isTranslate={true}
            />
          </div>
        )}

        <div className="mb-3">
          <SelectWithSearch
            isMulti={true}
            isMandatory={true}
            search={true}
            options={employeeTypes}
            value={selectedEmpType}
            onChange={() => {}}
            name={isBlueCollar ? "employeeType2" : "employeeType"}
            title={t("Selection of employee types (statuut) that can be used")}
            error=""
            isTranslate={true}
          />
        </div>

        <div className="col-md-12 text-end">
          <Button
            title={t(
              isBlueCollar
                ? "Add Paritair committee for blue collar"
                : "Add Paritair committee for white collar"
            )}
            handleClick={() =>
              addParitiarComitee(isBlueCollar ? "blueCollar" : "whiteCollar")
            }
            className="searchBtn px-3"
          />
        </div>
      </div>
    </div>
  );
};

export default ParitiarCommitteeSection;
