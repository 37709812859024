import React, { useEffect, useState } from 'react';
import Table from './Table';
import styles from './Pc.module.css';
import { translate } from '../translation/Translation';

const ListView = (props) => {
    const [header, setHeader] = useState([]);
    const [tval, setTval] = useState([]);
    const [count, setCount] = useState(props.index);
    const [sectype, setSectype] = useState();
    const [secId, setSecId] = useState();
    const [type, setType] = useState('');


    useEffect(

        () => {
            console.log(props)
            if (props.theader !== undefined) {
                setHeader(props.theader);
            }
            if (props.tvalue !== undefined) {
                setTval(props.tvalue);
            }
            if (props.sectype) {
                setSectype(props.sectype);
            }
            if (props.secId) {
                setSecId(props.secId);
            }
            if (props.type) {
                setType(props.type);
            }
        },
        [props]

    );

    useEffect(() => { }, []);
    const SecInfo = (sectype, secid) => {
        props.secInfoFromLst(sectype, secid);
    };
    return (
        <li key={props.index} className={`list-inline-item ${props.level === '3' ? styles.section_func_li : styles.sectionli} ${props.level === '3' ? styles.section_funct : styles.section_cat} pcAccordion`}>
            {header && (
                <div
                    className="accordion accordion-flush"
                    id={`accordionFlush ${props.pc_number.replace(".", "")}pc${props.index}`}
                >
                    <div className="accordion-item">
                        <h4 className="accordion-header"
                            id={`flush-heading${props.pc_number.replace(".", "")}pc${props.index}`}>
                            <button
                                className={`accordion-button shadow-none bodyBg collapsed ${styles.sectioncolor} `}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapse${props.pc_number.replace(".", "")}pc${props.index}`}
                                aria-expanded="false"
                                aria-controls={`flush-collapse${props.pc_number.replace(".", "")}pc${props.index}`}
                            >
                                {props.sectype === 'pc' && <span> {props.pc_number}</span>}
                                <span>{props.title}</span>
                            </button>
                        </h4>
                        <div
                            id={`flush-collapse${props.pc_number.replace(".", "")}pc${props.index}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`flush-collapse${props.pc_number.replace(".", "")}pc${props.index}`}
                            data-bs-parent={`#accordionFlush${props.pc_number.replace(".", "")}pc${props.index}`}
                        >
                            <div className="accordion-body bodyBg">
                                <Table
                                    header={header}
                                    tvalue={tval}
                                    sectype={sectype}
                                    secId={secId}
                                    type={type}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </li>
    );

};

export default translate(ListView);
