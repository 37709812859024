import React from "react";

interface Option {
  value?: number;
  name?: string;
}

interface MultiSelectFieldProps {
  options: Option[];
  placeholder: string;
  selectedValue?: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  isMultiSelect?: boolean;
  isMandatory?: boolean;
  name: string;
  className?: string;
  error?: string;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  options,
  placeholder,
  selectedValue,
  handleChange,
  disabled = false,
  isMultiSelect = false,
  name,
  className = "",
  error,
}) => {
  return (
    <div>
      <select
        className={`form-select ${className} field-shadow inputFieldColor ps-3 form-control shadow-none`}
        name={name}
        value={selectedValue}
        onChange={handleChange}
        disabled={disabled}
        multiple={isMultiSelect}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="mt-2 mb-2 text-danger">{error}</div>}
    </div>
  );
};

export default MultiSelectField;
