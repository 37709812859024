import React, { useContext, useEffect } from 'react';
import LinkCoeffEmpContext from './LinkCoeffEmp/LinkCoeffEmpContext';
import { helpers } from './LinkCoeffEmpHelper';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';

const EmployeeTypeSecondPart = () => {
    const { state, updateStateChanges } = useContext(LinkCoeffEmpContext);
    const {
        employeeTypeArray
        , valueTypeArray
        , coefficientTypeArray
        , pclinkingValueobj
        , lowHighValidation
        , inputRef
        , valueErrorArray
        , defaultValueError
        , selectedPc
        , lowKey, highKey, defaultKey, minValue, maxValue
    } = state;


    useEffect(() => {
        const current = state.inputRef.current['secondpart'];
        const trigger = () => {
            const hasOverflow = current.scrollWidth > current.clientWidth;
            updateStateChanges({
                isOverflow: hasOverflow,
                scrollRight: true, scrollLeft: false, tableWidth: '97%'
            });
        };
        if (current) {
            trigger();
        }
    }, [selectedPc])


    const handleValueChange = (target, _EmpId, _Coeffid, _ValId) => {
        let value = target.value;

        if (!value.match(state.regexp) || checkDecimalPointError(value)) return;

        let updatedValueObj = {
            ...state.pclinkingValueobj,
            [_EmpId]: {
                ...(state.pclinkingValueobj[_EmpId] || {}),
                [_Coeffid]: {
                    ...(state.pclinkingValueobj[_EmpId]?.[_Coeffid] || {}),
                    [_ValId]: value
                }
            }
        };

        updateStateChanges((prevState) => ({
            ...prevState,
            pclinkingValueobj: updatedValueObj,
            valueErrorArray: valueValidation(_EmpId, _Coeffid, _ValId, value),
            defaultValueError: validationForDefaultValue(updatedValueObj, _EmpId, _Coeffid),
            lowHighValidation: handleValidation(updatedValueObj, _EmpId, _Coeffid, _ValId),
            emptyDataWarning: false
        }));
    };


    const checkDecimalPointError = (value) => {
        let status = false;
        if (value) {
            let inputVal = value.replace(',', '.');
            let decimals = inputVal.split('.')[1];
            status = decimals && decimals.length > 4 ? true : false;
        }
        return status;
    }

    const validationForDefaultValue = (valueDataObj, _EmpId, _Coeffid) => {
        let lowHighDef = valueDataObj[_EmpId][_Coeffid] || {};
        let refkey = `${_EmpId}_${_Coeffid}_${defaultKey}`;
        let lowVal = lowHighDef[lowKey] || '';
        let highVal = lowHighDef[highKey] || '';
        let defaultVal = lowHighDef[defaultKey] || '';
        let addWarning = false;

        if (lowVal && highVal && defaultVal) {
            lowVal = Number(lowVal.replace(',', '.'));
            highVal = Number(highVal.replace(',', '.'));
            defaultVal = Number(defaultVal.replace(',', '.'));
            if (defaultVal < lowVal || defaultVal > highVal) {
                addWarning = true;
            }
        }

        if (addWarning) {
            if (!defaultValueError.includes(refkey)) {
                defaultValueError.push(refkey);
                helpers.toggleWarningClass(inputRef, refkey);
            }
        } else {
            const index = defaultValueError.indexOf(refkey);
            if (index > -1) {
                defaultValueError.splice(index, 1);
            }
            helpers.toggleWarningClass(inputRef, refkey, 0);
        }
        return defaultValueError;
    };


    const lowHighDefaultChanges = (_EmpId, _Coeffid, _ValId, valueDataObj) => { //might needed in future NOSONAR
        if (_ValId === lowKey || _ValId === highKey) {
            valueDataObj[_EmpId][_Coeffid][defaultKey] = '';
        }
        if (_ValId === defaultKey) {
            valueDataObj[_EmpId][_Coeffid][lowKey] = '';
            valueDataObj[_EmpId][_Coeffid][highKey] = '';
        }
    }


    const valueValidation = (_EmpId, _Coeffid, _ValId, inputVal) => {
        let value = Number(inputVal.replace(',', '.'));
        let refkey = `${_EmpId}_${_Coeffid}_${_ValId}`;

        if ((value < minValue) || (value > maxValue)) {
            if (!valueErrorArray.includes(refkey)) {
                valueErrorArray.push(refkey);
                helpers.toggleWarningClass(inputRef, refkey);
            }
        } else {
            const index = valueErrorArray.indexOf(refkey);
            if (index > -1) {
                valueErrorArray.splice(index, 1);
            }
            helpers.toggleWarningClass(inputRef, refkey, 0);
        }
        return valueErrorArray;
    };

    const handleValidation = (valueDataObj, _EmpId, _Coeffid, _ValId) => {
        let valObj = valueDataObj[_EmpId][_Coeffid];
        let lowVal = valObj[lowKey]
        let highVal = valObj[highKey];
        if (highVal && lowVal) {
            return compareAndShowTootTip(Number(lowVal.replace(',', '.')), Number(highVal.replace(',', '.')), `${_EmpId}_${_Coeffid}`);
        } else {
            return compareAndShowTootTip(lowVal, highVal, `${_EmpId}_${_Coeffid}`, 0);
        }
    }

    const compareAndShowTootTip = (lowVal, highVal, refkey, type = 1) => {
        let lowRef = inputRef.current[`${refkey}_1`];
        let highRef = inputRef.current[`${refkey}_3`];
        let title = '';
        if (lowVal >= highVal && type) {
            title = 'Low value should be less than high value (Low < High)';
            if (!lowHighValidation.includes(refkey)) {
                lowHighValidation.push(refkey);
                lowRef.classList.add("warning");
                highRef.classList.add("warning");
            }
        } else {
            const index = lowHighValidation.indexOf(refkey);
            if (index > -1) {
                lowHighValidation.splice(index, 1);
            }
            lowRef.classList.remove("warning");
            highRef.classList.remove("warning");
        }
        lowRef.title = title;
        highRef.title = title;
        return lowHighValidation;
    };

    const getEmployeeTypeTableContent = () => {
        let htmlContent = [];
        coefficientTypeArray.map(coefficient => {
            valueTypeArray.map(valueType => {
                htmlContent.push(<tr key={`${coefficient.id}-${valueType.id}`} className="table-second-part-tbody-tr">{
                    employeeTypeArray.map(employeeType => {
                        let _EmpId = employeeType.id, _ValId = valueType.id, _Coeffid = coefficient.id;
                        let { matrixKey, value } = getPcLinkingValue(_EmpId, _Coeffid, _ValId);
                        return (<td key={matrixKey} id={matrixKey} className="pc-linking-td poppins-regular-16px">
                            <input
                                type="text"
                                className="value-input-cell"
                                value={value}
                                id={`${_EmpId}_${_Coeffid}_${_ValId}`}
                                ref={ref => inputRef.current[`${_EmpId}_${_Coeffid}_${_ValId}`] = ref}
                                onChange={(e) => handleValueChange(e.target, _EmpId, _Coeffid, _ValId)}
                            />
                        </td>)
                    })
                }</tr>
                )
            })
        })
        return htmlContent;
    }

    const getPcLinkingValue = (_EmpId, _Coeffid, _ValId) => {
        let matrixKey = `${_EmpId}-${_Coeffid}-${_ValId}`;
        let valueObj = pclinkingValueobj[_EmpId] ? pclinkingValueobj[_EmpId][_Coeffid] ?
            pclinkingValueobj[_EmpId][_Coeffid] : {} : {};
        let lowVal = valueObj[lowKey] || '';
        let highVal = valueObj[highKey] || '';
        return {
            matrixKey,
            value: valueObj[_ValId] ? valueObj[_ValId].replace('.', ',') : '',
            lowVal,
            highVal
        }
    }

    return (
        <div className="second-part-parent-div" ref={ref => inputRef.current['secondpart'] = ref}>
            <table className="table pclinking-table table-second-part">
                <thead className="pclinking-table-thead table-second-part-thead">
                    <tr className="table-second-part-thead-tr-class poppins-regular-18px">{
                        employeeTypeArray.map(emp => <th height="67.7" key={emp.id} className="table-second-part-th-class" title={emp.name}>
                            <div className="header-div-tag"> {emp.name} </div> </th>)
                    }</tr>
                </thead>
                <tbody className="pclinking-table-tbody table-second-part-tbody">
                    {getEmployeeTypeTableContent()}
                </tbody>
            </table>
        </div>
    )

};

export default translate(EmployeeTypeSecondPart);
