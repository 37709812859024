import React, { CSSProperties, useState, useEffect } from "react";
// import "static/css/file-upload.css";
// import { AxiosCall } from "../services/AxiosService";
import { AxiosCall } from "../../../services/AxiosService";
import LoadingIcon from "../utlis/LoadingIcon";
import axios from "axios";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import LabelField from "../atoms/LabelField";
import { t } from "../../CentralDataMangement/translation/Translation";
import Icon from "../../../Icon";
type fileUploadErrorsType = (errorInfo: object) => void;
type fileUploadResponseType = (uploadResponse: object, index: number) => void;
type deleteResponseType = (field: string, index: number) => void;

interface fileObject {
  fileName: string | null;
  filePath: string | null;
  fileId: number | null;
}

interface FileUploadProps {
  label?: string;
  isMandatory?: boolean;
  id: string;
  name: string;
  edit: boolean;
  fileId: number | null;
  filePath: string | null;
  fileName: string | null;
  style?: CSSProperties;
  multiple: boolean;
  formats: string;
  index?: number;
  uploadPath: string;
  returnFileUploadErrors: fileUploadErrorsType;
  fileUploadResponse: fileUploadResponseType;
  deleteResponse: deleteResponseType;
  maxFileSize: number;
  placeholder?: string;
  className?: string;
  microserviceURL?: string;
  file_type?: string | number;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const {
    label,
    isMandatory,
    id,
    name,
    style,
    multiple,
    formats,
    index,
    fileId,
    filePath,
    fileName,
    uploadPath,
    returnFileUploadErrors,
    fileUploadResponse,
    deleteResponse,
    maxFileSize,
    className,
    placeholder = t("Upload file"),
    microserviceURL,
    file_type,
  } = props;
  const [fileObject, setFileObject] = useState<fileObject>({
    fileName: "",
    filePath: "",
    fileId: null,
  });

  useEffect(() => {
    setFileObject((prevObject) => ({
      ...prevObject,
      fileName: fileName,
      filePath: filePath,
      fileId: fileId,
    }));
  }, [props]);

  const [loading, setLoading] = useState(false);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formats: string,
    id: string,
    index?: number,
    file_type?: string | number
  ) => {
    const file = event.target.files && event.target.files[0];
    const allowedFormats = formats.split(",");
    let errorString = "";
    if (file) {
      let fileExtension = file.name.split(".").pop()?.toLowerCase();
      fileExtension = `.${fileExtension}`;
      if (fileExtension && !allowedFormats.includes(fileExtension)) {
        errorString = `${t("Invalid file format")}. ${t(
          "Only files with the extensions"
        )} ${formats} ${t("are allowed")}`;
        buildErrorObject(id, index, errorString);
        return;
      }
      if (file.size > maxFileSize) {
        errorString = t("File size is greater than 10mb");
        if (file.type.startsWith("image")) {
          errorString = t("Pic size is greater than 2mb");
        }
        buildErrorObject(id, index, errorString);
        return;
      }
      resolveErrors(id, index, "");
      uploadFileToS3(file, id, file_type);
    } else {
      errorString = t("There is some issue with file uploading");
      buildErrorObject(id, index, errorString);
    }
  };

  const uploadFileToS3 = async (file: File, name: string , file_type:any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file_type", file_type.toString());
    formData.append("file", file);
    formData.append("name", name);
    const response = await AxiosCall.fileUploadService(
      formData,
      uploadPath,
      microserviceURL
    );
    setFileObject((prevObject) => ({
      ...prevObject,
      fileName: response[1],
      filePath: response[2],
      fileId: response[0],
    }));
    const responseObj = {
      step: id,
      response: response,
      file: file,
    };
    fileUploadResponse(responseObj, index ? index : 0);
    setLoading(false);
  };

  const removeFile = async (
    event: React.MouseEvent<HTMLSpanElement>,
    id: string
  ) => {
    setLoading(true);
    try {
      if (fileObject.filePath !== null && fileObject.fileId !== null) {
        await AxiosCall.fileDeleteService(
          fileObject.filePath,
          fileObject.fileId,
          microserviceURL
        );
        setFileObject({
          fileName: null,
          filePath: null,
          fileId: null,
        });
      }
      setLoading(false);
      deleteResponse(id, index ? index : 0);
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const downloadFile = async (
    id: number | null,
    name: string | null,
    path: string | null,
    uploadPath: string | null
  ) => {
    try {
      const microService: string =
        microserviceURL !== null &&
        microserviceURL !== "" &&
        microserviceURL !== undefined
          ? microserviceURL
          : `${process.env.REACT_APP_BACKEND_SERVICE}`;
      const response = await axios.get(
        `${microService}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
      );
      if (response.status === 200) {
        const fileUrl = response.data.url;
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = fileUrl;
        link.target = "_blank";
        link.download = response.data.filename; //Trigger a click event on the anchor element
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const resolveErrors = (
    id: string,
    index: number | undefined,
    error: string
  ) => {
    const errorObject = {
      step: id,
      index: index ? index : 0,
      error: "",
    };
    returnFileUploadErrors(errorObject);
  };

  const buildErrorObject = (
    id: string,
    index: number | undefined,
    error: string
  ) => {
    const errorObject = {
      step: id,
      index: index ? index : 0,
      error: error,
    };
    returnFileUploadErrors(errorObject);
  };
  return (
    <>
      <LabelField title={label} isMandatory={isMandatory} />
      {!loading ? (
        fileObject.fileName ? (
          <div className="d-flex justify-content-between align-items-center h-38">
            <span className="text-truncate file-name d-inline-block certificate-name">
              <span className="color-dark-purple file-upload">
                {fileObject.fileName}
              </span>
            </span>
            <div className="d-flex justify-content-end">
              <span
                onClick={(event) =>
                  downloadFile(
                    fileObject.fileId,
                    fileObject.fileName,
                    fileObject.filePath,
                    uploadPath
                  )
                }
                className="table-action-icons cursor-pointer"
              >
                <span className="secondaryColorHoverEffect" title={t("View")}>
                  <Icon
                    isIcon={true}
                    width="1vw"
                    height="1vw"
                    name="viewIcon"
                  />
                </span>
              </span>
              <span
                className="secondaryColorHoverEffect"
                onClick={(event) => removeFile(event, id)}
                title={t("Delete")}
              >
                <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
              </span>
            </div>
          </div>
        ) : (
          <div
            className={`m-auto d-flex align-items-center file-upload-wrapper-input cursor-pointer rounded-3`}
          >
            <span className="secondaryColorHoverEffect">
              <Icon isIcon={true} width="1vw" height="1vw" name="uploadIcon" />
            </span>
            <span className={`ms-3`}>{placeholder}</span>
            <input
              type="file"
              id={id}
              name={name}
              className="cursor-pointer"
              multiple={multiple}
              accept={formats}
              // onChange={(event) => handleFileChange(event, formats, id, index)}
              onChange={(event) => handleFileChange(event, formats, id, index, file_type)}
            />
          </div>
        )
      ) : (
        <>
          <LoadingIcon
            iconType="bars"
            color="#ff4dae"
            className=""
            height={38}
          />
        </>
      )}
    </>
  );
};

export default FileUpload;
