import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { addUserDetails, selectAuth } from "../../../features/auth/AuthSlice";
import { useDispatch } from "react-redux";
import Header from "./Header";
import Sidebar from "../atoms/Sidebar";
import { ApiCall } from "../../../services/ApiServices";
import { GET_USER_PERMISSIONS } from "../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";

export const NewRootLayout = () => {
  const authData = useSelector(selectAuth);
  useEffect(() => {
    // if (authData.isAuthenticated) {
      getUserPermissions();
    // }
  }, [])

  const getUserPermissions = async () => {
    const userId = { id: authData.userId };
    await ApiCall.service(
      GET_USER_PERMISSIONS,
      "POST",
      userId && -999,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    ).then((response) => {
      if (response && response.status === 200) {
        const userPermissions = {
          userPermissions: response.data.permission,
          isSuperAdmin: response.data.isSuperAdmin
        }
        dispatch(addUserDetails(userPermissions));
      }
      else {
        console.error(response.message);
      }
    })
  }
  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div
        style={{ marginLeft: "9.8vw", width: "100%", marginRight: "1.8vw" }}
        className="container-fluid"
      >
        <Outlet /> {/* Renders the matched child route components */}
      </div>
    </div>
  );
};

export default NewRootLayout;
