import { useState } from "react";
import EmployeeTypeList from "./EmployeeTypesList";
import { EMPLOYEE_TYPES } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { t } from '../../common/atoms/translation';

export default function ManageEmployeeType() {

    const [employeeTypes, setEmployeeTypes] = useState([]);

    const headers = [
        t('Employee types'),
        t('Actions')
    ];


    const fetchdata = async () => {
        const response = await ApiCall.getService(EMPLOYEE_TYPES, "GET", "central-data-management");
        setEmployeeTypes(response.data)
    }
    useState(() => {
        fetchdata()

    }, []);



    return (
        <>
            <EmployeeTypeList employeeTypes={employeeTypes}
                headers={headers}
            />
        </>
    )
}