import React from "react";
import Icon from "../../../Icon";
import { useDispatch } from "react-redux";
import { selectContractType, setContractType, clearAllSchedules, clearDates } from "../../../features/planning/planningSlice";
import { useSelector } from "react-redux";


interface calendarIconProps {
  isCalendarIcon?: boolean;
  page?: string
}
const CalendarIcon: React.FC<calendarIconProps> = ({
  isCalendarIcon = false,
  page
}) => {
  // const [viewType, setViewType] = useState<"day" | "week" | "month">();
  const viewType = useSelector(selectContractType);

  // const se

  const dispatch = useDispatch();


  const handleIconClick = (viewType: "day" | "week" | "month") => {
    const newStartDate = new Date();

    if (viewType === "day") {

      newStartDate.setDate(newStartDate.getDate());
    } else if (viewType === "week") {
      const startOfWeek = newStartDate.getDate() - newStartDate.getDay();
      newStartDate.setDate(startOfWeek);
    } else if (viewType === "month") {
      newStartDate.setDate(1);
    } if (page == "planning") {
      console.log("I am here");
      dispatch(clearAllSchedules);
      dispatch(clearDates);
      dispatch(setContractType(viewType));
    }

    // setActiveStartDate(newStartDate);
    // setViewType(viewType);
  };

  const getClassName = (iconViewType: "day" | "week" | "month") => {
    return iconViewType === viewType
      ? "active-icon ms-2 cursor-pointer"
      : " nonActiveIcon ms-2 cursor-pointer";
  };

  const CalendarIconProps = {
    width: "1vw",
    height: "1vw",
    isIcon: true,
  };

  return (
    <>
      {isCalendarIcon && (
        <div className="row py-1">
          <div className="col-12">
            <div className="float-end">
              <Icon
                name="dayPlanning"
                {...CalendarIconProps}
                onClick={() => handleIconClick("day")}
                className={getClassName("day")}
              />
              <Icon
                name="weeklyPlanning"
                {...CalendarIconProps}
                onClick={() => handleIconClick("week")}
                className={getClassName("week")}
              />
              <Icon
                name="MonthlyPlanning"
                {...CalendarIconProps}
                onClick={() => handleIconClick("month")}
                className={getClassName("month")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarIcon;
