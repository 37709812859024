import React, { useContext, useEffect } from 'react';
import LinkCoeffEmpContext from './LinkCoeffEmp/LinkCoeffEmpContext';
import CoeffcientValuesFirstPart from './CoeffcientValuesFirstPart';
import EmployeeTypeSecondPart from './EmployeeTypeSecondPart';
import { helpers } from './LinkCoeffEmpHelper';
import MultiSelect from './SelectComponent';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';
import { ApiCall } from '../../../../services/ApiServices';
import { FETCH_ALL_EMP_COEFF_VALUES, SAVE_PC_LINKING_DATA } from '../../../../routes/ApiEndpoints';

var SERVER_SIDE_RENDERING = 1;

const LinkCoeffEmpComponent = (props) => {

    const { state, updateStateChanges } = useContext(LinkCoeffEmpContext);
    const { inputRef, isOverflow } = state;
    useEffect(() => {
        SERVER_SIDE_RENDERING ? fetchEmpCoeffValueTypesData() : SERVER_SIDE_RENDERING += 1;
    }, [])


    const fetchEmpCoeffValueTypesData = async (url = `${FETCH_ALL_EMP_COEFF_VALUES}`, type = 0, pcid = props.pcid) => {
        try {
            const response = await ApiCall.service(url, "POST", { pcid: pcid, })

            if (response.success) {
                if (type === 1) {
                    return response;
                } else {
                    assignDataToStateVariables(response.data); // Otherwise, assign data to state
                }
            } else {
                console.error("API call failed:", response.message);
                return null;
            }
        } catch (error) {
            console.error("Error in fetchEmpCoeffValueTypesData:", error);
            return null;
        }
    };


    const assignDataToStateVariables = (data) => {
        const { employeeTypes, coefficientTypes, valueTypes, pcLinkedValueData, pcArray } = data;
        updateStateChanges({
            employeeTypeArray: employeeTypes
            , coefficientTypeArray: coefficientTypes
            , valueTypeArray: valueTypes
            , pclinkingValueobj: pcLinkedValueData || {}
            , pcArray: [{ value: false, label: t('--- Select ---') }, ...pcArray]
            , selectedPc: parseInt(props.pcid),
        });
    }

    const handleSubmit = async () => {
        if (proceedToSubmit()) {

            await ApiCall.service(SAVE_PC_LINKING_DATA, "POST", postdata())
                .then(response => {
                    if (response.success)
                        window.location.reload();
                })
        }
    }



    const proceedToSubmit = () => {
        let proceed = true;
        if (!state.selectedPc) {
            proceed = false;
            updateStateChanges({ pcWarning: true })
        }
        const { status, regExpressionStatus } = helpers.checkCoefficientsFilledOrNot(state.coefficientTypeArray, state.employeeTypeArray, state.pclinkingValueobj);
        console.log(status, regExpressionStatus, "check to proceed");
        if (state.selectedPc && (!status || !regExpressionStatus)) {
            proceed = false;
            updateStateChanges({ emptyDataWarning: !status, regExpWarning: !regExpressionStatus })
        }
        if (state.lowHighValidation.length)
            proceed = false;
        if (state.valueErrorArray.length)
            proceed = false;
        if (state.defaultValueError.length)
            proceed = false;
        return proceed;
    }


    const postdata = () => {
        return { pclinkingValueobj: state.pclinkingValueobj, selectedPc: state.selectedPc }
    }


    const onSelect = async (e) => {
        removeWarningClass();
        const url = `${FETCH_ALL_EMP_COEFF_VALUES}`;
        const response = await fetchEmpCoeffValueTypesData(url, 1, e.value);

        if (response) {
            const { employeeTypes, coefficientTypes, valueTypes, pcLinkedValueData, pcArray } = response.data;
            updateStateChanges({
                employeeTypeArray: employeeTypes,
                selectedPc: e.value,
                pcWarning: false,
                emptyDataWarning: false,
                regExpWarning: false,
                pclinkingValueobj: pcLinkedValueData,
                lowHighValidation: [],
                valueErrorArray: [],
                defaultValueError: [],
            });
        } else {
            console.error("Failed to fetch data");
        }
    };


    const removeWarningClass = () => {
        state.lowHighValidation.map(val => {
            let lowRef = inputRef.current[`${val}_1`];
            let highRef = inputRef.current[`${val}_3`];
            lowRef.classList.remove("warning");
            highRef.classList.remove("warning");
        })
    }

    const addMultiSelectTag = () => {
        return (
            <>
                <p className='my-2 poppins-light-18px custom_astrick'> {t('Select paritair comite')} </p>
                <MultiSelect
                    options={state.pcArray}
                    standards={state.pcArray.filter(val => val.value === state.selectedPc)}
                    disabled={parseInt(props.pcid) && state.selectedPc ? true : false}
                    handleChange={onSelect}
                    isMulti={false}
                    className="pc-single-select  input-border-lightgray poppins-regular-18px mh-50 rounded-0 linkcoe"
                    placeholder={t('Select paritair comite')}
                />
            </>
        )
    }


    if (SERVER_SIDE_RENDERING)
        return <>
            {console.log(state, "sdf")}
            <div className="row">
                <div className="col-md-12 m-0">
                    <div className='py-4 px-0 position-sticky-pc'>
                        {/* <h4 className={`sv-cp-page-title  page-title font-weight-bold  bitter-italic-normal-medium-24 px-0`}> Link coefficients to employee types</h4> */}
                        <h4 className={`page-title font-weight-bold  bitter-italic-normal-medium-24 px-0`}> {t('Link coefficients to employee types')}</h4>
                    </div>
                    <div className='row position-sticky-config-link pb-3 position-static-mobile'>
                        <div className="col-md-12 col-lg-5 col-xl-4"> {addMultiSelectTag()}
                            {state.pcWarning ? <small style={{ color: 'red' }} className='error_text mt-3 text-danger'> {t('Choose paritair comite')} </small> : null}
                        </div>
                        <div className='col-md-12 col-lg-7 col-xl-8'>

                            <div className='row'>
                                {state.lowHighValidation !== undefined && state.lowHighValidation.length > 0 &&
                                    <small className="col-md-12 my-2 text-danger text-left error_text">
                                        {t('Please change the highlighted low and high values, low value should be less than high value Low < High.')}
                                    </small>}
                                {state.emptyDataWarning &&
                                    <small className="col-md-12 my-2 text-danger text-left error_text">
                                        {t(`Please fill all coefficient fields.`)}
                                    </small>}
                                {state.valueErrorArray.length > 0 &&
                                    <small className="col-md-12 my-2 text-danger text-left error_text">
                                        {t(`Value should be in between 0 to 10.`)}
                                    </small>}
                                {state.defaultValueError.length > 0 &&
                                    <small className="col-md-12 my-2 text-danger text-left error_text">
                                        {t(`Default value should be in between low and high values.`)}
                                    </small>}
                                {state.regExpWarning === true && <small className="col-md-10 my-2 text-danger text-left error_text" id="reg-expression-div">
                                    {t(`Please enter proper values.`)}
                                </small>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 m-0 p-0 relative-div linkcoeffempcomponents-table right_arrow_align ">

                        <div className={`row link-emp-coeff-tableparent link-employee-coefficient-parent ${isOverflow ? 'table-overflow' : ''}`} id="linkempCoeffDivId">
                            <div className="col-lg-4 col-md-6 m-0 p-0 pc-linking-div firstpart">
                                <CoeffcientValuesFirstPart />
                            </div>
                            <div className="col-lg-8 col-md-6 m-0 p-0 pc-linking-div secondpart">
                                <EmployeeTypeSecondPart />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 row m-0 mt-5 mb-2' style={{}}>
                        <div className='col-md-6 p-0 align-self-center back-btn-mobile'>
                            {/* <button onClick={() => parseInt(props.pcid) ? props.router.back() : props.router.push('/manage')} type="button" className=" col-2 bg-white  text-start border-0 poppins-regular-18px  float-sm-right text-left p-0 md-5 text-decoration-underline shadow-none text-uppercase">
                                {t(`BACK`)}
                            </button> */}
                        </div>
                        <div className='col-md-6 p-0 back-btn-mobile'>
                            <button onClick={() => handleSubmit()} type="button" className=" btn rounded-0  custom-btn px-3  btn-block float-end poppins-medium-18px-next-button shadow-none text-uppercase">
                                {t(`SAVE`)}
                            </button></div>
                    </div>
                </div>

            </div>
        </>
    else
        return <></>

}


export default translate(LinkCoeffEmpComponent);