import React, { useEffect, useState } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import ShiftTiming from "../Planning/ShiftTiming";
import "./css/timeRegistration.css";
import userImage from "../../static/images/User.svg";
import OverFlowWrapper from "../common/OverFlowWrapper";
import DateAndTime from "../common/atoms/DateWithTime";
import { useLocation } from "react-router-dom";
import Icon from "../../Icon";

const TimeRegistrationOverview = () => {
  const [date, setDate] = useState(new Date());
  const [activeTab, setActiveTab] = useState("home"); // State to track the active tab

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  const location = useLocation();

  useEffect(() => {
    // Extract `activeTab` from location state
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const [openEmployees] = useState([
    {
      id: 1,
      name: "John Doe",
      position: "Developer",
      location: "Vestiging 1",
      role: "Winkelbediende CATII",
      pc: "PC201",
      salary: "€12,50",
      image: userImage,
    },
  ]);

  const [closedEmployees] = useState([
    {
      id: 1,
      name: "John Doe",
      position: "Developer",
      location: "Vestiging 1",
      role: "Winkelbediende CATII",
      pc: "PC201",
      salary: "€12,50",
      image: userImage,
    },
  ]);

  return (
    <>
      <Header
        headerName={t("Time registration per employee")}
        headerClassName="myAccountCardTitle"
      />
      <TableHeaderWithIcon
        title={t("Location Name")}
        TableHeaderWithIconClassName="plannedLocationHeader px-3"
        TableHeaderTitle="poppins-semibold text-white"
      />
      <div className="contentBg rounded-top-0 p-3">
        <div className="row mb-3">
          <div className="col-4 align-self-center">
            <ul className="nav nav-pills timeRegistrationTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                  data-bs-toggle="pill"
                  href="#home"
                  onClick={() => setActiveTab("home")}
                >
                  {t("Open")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "menu1" ? "active" : ""
                  }`}
                  data-bs-toggle="pill"
                  href="#menu1"
                  onClick={() => setActiveTab("menu1")}
                >
                  {t("Closed")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-4 align-self-center text-center">
            <DateAndTime
              isWeek={true}
              isIcon={true}
              monthFormat="long"
              dataTimeWrapperClassName="d-inline-flex align-items-center justify-content-center timeRegistrationDateWrapper"
              dataTimeClassName="mx-3 planningPlannedDate"
              dayClassName="me-1"
              isWeekDate={true}
              iconFillColor="#8571b9"
              onDateChange={handleDateChange}
            />
          </div>
          <div className="col-4 align-self-center">
            {activeTab === "menu1" && (
              <div className="me-3 totalValidatedHours">
                {t("Total validated hours") + ":"}
              </div>
            )}
            <span
              onClick={() => {}}
              title={t("Print")}
              className="secondaryColorHoverEffect float-end"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="printIcon" />
            </span>
            <span
              onClick={() => {}}
              title={t("Search")}
              className="secondaryColorHoverEffect me-3 float-end"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="searchIcon" />
            </span>
          </div>
        </div>
        {/* Tab panes */}
        <div className="tab-content">
          <div
            id="home"
            className={`tab-pane ${activeTab === "home" ? "active" : ""}`}
          >
            <OverFlowWrapper className="timeRegistrationPerEmployee">
              {openEmployees.map((employee) => (
                <div className="row mb-3" key={employee.id}>
                  <div className="col-2 align-self-center">
                    <EmployeeDetailsCard
                      cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                      employeeInformationClass="planningEmployeeDetails w-75"
                      employeeName={employee.name}
                      employeePosition={employee.position}
                      employeeLocation={employee.location}
                      employeeRole={employee.role}
                      employeePC={employee.pc}
                      employeeSalary={employee.salary}
                      employeeImage={employee.image}
                    />
                  </div>
                  <div className="col-10 align-self-center ps-0">
                    <ShiftTiming
                      showLastAction={true}
                      isTimeRegistration={true}
                    />
                  </div>
                </div>
              ))}
            </OverFlowWrapper>
          </div>
          <div
            id="menu1"
            className={`tab-pane ${activeTab === "menu1" ? "active" : ""}`}
          >
            <OverFlowWrapper className="timeRegistrationPerEmployee">
              {closedEmployees.map((employee) => (
                <div className="row mb-3" key={employee.id}>
                  <div className="col-2 align-self-center">
                    <EmployeeDetailsCard
                      cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                      employeeInformationClass="planningEmployeeDetails w-75"
                      employeeName={employee.name}
                      employeePosition={employee.position}
                      employeeLocation={employee.location}
                      employeeRole={employee.role}
                      employeePC={employee.pc}
                      employeeSalary={employee.salary}
                      employeeImage={employee.image}
                    />
                  </div>
                  <div className="col-10 align-self-center ps-0">
                    <ShiftTiming
                      showLastAction={true}
                      isTimeRegistration={true}
                      category="closed"
                    />
                  </div>
                </div>
              ))}
            </OverFlowWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(TimeRegistrationOverview);
