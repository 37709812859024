// components/LoadingOverlay.tsx
import React from 'react'; // Add this line if it's missing
import LoadingIcon from './LoadingIcon';
// import '';
interface Props {
  display?: string
}

const LoadingOverlay: React.FC<Props> = ({ display }) => {
  const divStyle: React.CSSProperties = {
    display: display || 'none', // Set a default value for display if not provided
  };
  return (
    <>
      {/* <div id="overlay" style={divStyle} className="fullscreen-loading-container">
        <LoadingIcon width={100} />
      </div> */}
    </>
  );
};

export default LoadingOverlay;