import React, { useContext, useEffect } from 'react';
import MultiSelect from './SelectComponent';
import MultiSelectField from './MultiSelectField';
import LinkCoeffDeviationEmpContext from './LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext';
import { ApiCall } from '../../../../services/ApiServices';
import { helpers } from './LinkCoeffEmpHelper';
import CoeffcientValuesFirstPart from './CoeffcientValuesFirstPart';
import EmployeeTypeSecondPart from './EmployeeTypeSecondPart';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';

import {
    FETCH_COEFFICIENT_DEVIATIONS,
    FETCH_ALL_EMP_COEFF_VALUES,
    STORE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
    UPDATE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
    FETCH_COEFFICIENT_DEVIATION_EMPLOYEE_LINKING_PER_PC
} from '../../../../routes/ApiEndpoints';
var SERVER_SIDE_RENDERING = 1;

const LinkCoeffDeviationEmpComponent = (props) => {

    const { state, updateStateChanges } = useContext(LinkCoeffDeviationEmpContext);
    const { inputRef, isOverflow } = state;
    useEffect(() => {
        SERVER_SIDE_RENDERING ? fetchEmpCoeffValueTypesData() : SERVER_SIDE_RENDERING += 1;
    }, [])



    const fetchEmpCoeffValueTypesData = async (url = `${FETCH_ALL_EMP_COEFF_VALUES}`, type = 0, pcid = props.pcid, edit = 0) => {
        let res;
        try {
            const response = await ApiCall.service(
                url,
                "POST",
                { pcid: pcid, edit: edit },
                false,
                "central-data-management"
            );
            if (response.success) {
                if (type) {
                    res = response;
                } else {
                    assignDataToStateVariables(response.data)
                }
            } else {
                console.error("API call failed:", response.message);
            }
        } catch (error) {
            console.error("Error in fetchEmpCoeffValueTypesData:", error);
        }


        try {
            const response = await ApiCall.getService(
                FETCH_COEFFICIENT_DEVIATIONS,
                "GET",
                "central-data-management"
            );
            if (response.success) {
                updateStateChanges({
                    ConstructedCoefficientData: response.data ? ConstructedCoefficientData(response.data) : [],
                    coefficientDeviationObj: response.data ? ConstructedCoefficientDeviation(response.data) : [],
                    selectedDeviation: [parseInt(props.pcid)],
                    selectedDevaitionObj: []
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }

        if (props.pcid > 0) {
            try {
                const response = await ApiCall.getService(
                    FETCH_COEFFICIENT_DEVIATION_EMPLOYEE_LINKING_PER_PC + "/" + props.pcid,
                    "GET",
                    "central-data-management"
                );
                if (response.success) {
                    console.log(response.data);
                    updateStateChanges({
                        selectedDeviation: response.data.selectedDeviation,
                        CoefficientDeviationlinkingValueobj: response.data.CoefficientDeviationlinkingValueobj,
                        selectedDevaitionObj: Object.values(response.data.selectedDevaitionObj)
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }

        }

        return res;
    }

    const ConstructedCoefficientData = (data) => {
        const { overviewData } = data;
        let dataObj = {};
        data.map(eachObj =>
            dataObj[eachObj.id] = eachObj
        );
        return dataObj;
    }

    const ConstructedCoefficientDeviation = (data) => {
        // let dataObj = {};
        var dataObj = new Array();
        data.map(eachObj =>
            dataObj.push({ 'label': eachObj.label, 'value': eachObj.value })
        );

        return dataObj;
    }


    const assignDataToStateVariables = (data) => {
        const { employeeTypes, coefficientTypes, valueTypes, pcLinkedValueData, pcArray } = data;
        updateStateChanges({
            employeeTypeArray: employeeTypes
            , coefficientTypeArray: coefficientTypes
            , valueTypeArray: valueTypes
            , pclinkingValueobj: pcLinkedValueData || {}
            , pcArray: [{ value: false, label: t('--- Select ---') }, ...pcArray]
            , selectedPc: parseInt(props.pcid),
        });
    }


    const handleSubmit = async () => {
        if (proceedToSubmit()) {
            if (state.selectedPc > 0) {
                try {
                    const response = await ApiCall.service(
                        UPDATE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
                        "POST",
                        postdata(),
                        false,
                        "central-data-management"
                    );
                    if (response.success) {
                        window.location.reload();
                    } else {
                        console.error("API call failed:", response.message);
                    }
                } catch (error) {
                    console.error("Error in storing", error);
                }
            }
            else {
                // this code is not there 
                // try {
                //     const response = await ApiCall.service(
                //         STORE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
                //         "POST",
                //         postdata(),
                //         false,
                //         "central-data-management"
                //     );
                //     if (response.success) {
                //         alert("stored successfully")

                //     } else {
                //         console.error("API call failed:", response.message);
                //     }
                // } catch (error) {
                //     console.error("Error in storing", error);
                // }
            }

        }
    }


    const proceedToSubmit = () => {
        let proceed = true;
        if (!state.selectedPc) {
            proceed = false;
            updateStateChanges({ pcWarning: true })
        }
        return proceed;
    }


    const postdata = () => {
        return {
            CoefficientDeviationlinkingValueobj: state.CoefficientDeviationlinkingValueobj,
            selectedPc: state.selectedPc,
            selectedDeviation: state.selectedDeviation,
            ConstructedCoefficientData: state.ConstructedCoefficientData
        }
    }


    const onSelect = async (e) => {
        removeWarningClass()
        let response = await fetchEmpCoeffValueTypesData(`${FETCH_ALL_EMP_COEFF_VALUES}`, 1, e.value, 1);
        if (response) {
            const { employeeTypes = [] } = response.data;
            updateStateChanges({
                employeeTypeArray: employeeTypes,
                selectedPc: e.value,
                pcWarning: false,
                emptyDataWarning: false,
                regExpWarning: false,
                pclinkingValueobj: {},
                lowHighValidation: [],
                valueErrorArray: [],
                defaultValueError: [],
            });
        } else {
            console.error("Failed to fetch data");
        }
    };

    let onSelectDeviation = (values) => {
        var pc1 = [];


        values.map((val, key) => {
            pc1.push(val.value)
        })


        updateStateChanges({
            selectedDeviation: pc1,
            selectedDevaitionObj: values
        });
    }


    const removeWarningClass = () => {
        state.lowHighValidation.map(val => {
            let lowRef = inputRef.current[`${val}_1`];
            let highRef = inputRef.current[`${val}_3`];
            lowRef.classList.remove("warning");
            highRef.classList.remove("warning");
        })
    }

    const addMultiSelectTag = () => {
        return (
            <>
                <p className='my-2 poppins-light-18px custom_astrick'> {t('Select paritair comite')} </p>
                <MultiSelect
                    options={state.pcArray}
                    standards={state.pcArray.filter(val => val.value === state.selectedPc)}
                    disabled={(parseInt(props.pcid) && props.pcid > 0) && state.selectedPc ? true : false}
                    handleChange={onSelect}
                    isMulti={false}
                    className="pc-single-select  input-border-lightgray poppins-regular-18px mh-50 rounded-0 linkcoe"
                    placeholder={t('Select paritair comite')}
                />
            </>
        )
    }

    const addMultiSelectDeviationTag = () => {
        return (
            <>
                <p className='my-2 poppins-light-18px custom_astrick'> {t('Select coefficient deviation')} </p>
                <MultiSelectField
                    options={state.coefficientDeviationObj}
                    standards={state.coefficientDeviationObj.filter(val => state.selectedDeviation.includes(val.value))}
                    disabled={false}
                    handleChange={onSelectDeviation}
                    isMulti={true}
                    className="col-md-12"
                    placeholder={t('Select coefficient deviation')}
                />
            </>
        )
    }

    if (SERVER_SIDE_RENDERING)
        return <>
            <div className="row">
                <div className="col-md-12 m-0">
                    <div className='py-4 px-0 position-sticky-pc'>
                        <h4 className={`page-title font-weight-bold  bitter-italic-normal-medium-24 px-0`}> {t('Link coefficients deviations to employee types')}</h4>
                    </div>
                    <div className='row position-sticky-config-link pb-3 position-static-mobile'>
                        <div className="col-md-12 col-xl-4 col-lg-5"> {addMultiSelectTag()}
                            {state.pcWarning ? <small style={{ color: 'red' }} className='error_text mt-3 text-danger'> {t('Choose paritair comite')} </small> : null}
                        </div>

                        <div className="col-md-12 col-xl-8 col-lg-7 indexation-salary"> {addMultiSelectDeviationTag()}
                            {state.pcWarning ? <small style={{ color: 'red' }} className='error_text mt-3 text-danger'> {t('Choose paritair comite')} </small> : null}
                        </div>
                    </div>


                    <div className="col-md-12 m-0 p-0 relative-div right_arrow_align">
                        <div className={`row link-emp-coeff-tableparent link-employee-coefficient-parent ${isOverflow ? 'table-overflow' : ''}`} id="linkempCoeffDivId">
                            <div className="col-lg-4 col-md-6 m-0 p-0 pc-linking-div firstpart">
                                <CoeffcientValuesFirstPart />
                            </div>
                            <div className="col-lg-8 col-md-6 m-0 p-0 pc-linking-div secondpart">
                                <EmployeeTypeSecondPart />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 row m-0 mt-5 mb-2' style={{}}>
                        <div className='col-md-6 p-0 align-self-center back-btn-mobile'>
                            {/* <button onClick={() => parseInt(props.pcid) ? props.router.back() : props.router.push('/manage')} type="button" className=" col-2 bg-white  text-start border-0 poppins-regular-18px  float-sm-right text-left p-0 md-5 text-decoration-underline shadow-none text-uppercase">
                                {t(`BACK`)}
                            </button> */}
                        </div>
                        <div className='col-md-6 p-0 back-btn-mobile'>
                            <button onClick={() => handleSubmit()} type="button" className=" btn rounded-0  custom-btn px-3  btn-block float-end poppins-medium-18px-next-button shadow-none text-uppercase">
                                {t(`SAVE`)}
                            </button></div>
                    </div>
                </div>

            </div>
        </>
    else
        return <></>
}

export default translate(LinkCoeffDeviationEmpComponent);
