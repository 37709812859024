// import EncryptDecryptService from "cen/common/services/EncryptDecryptService';
import EncryptDecryptService from "../services/EncryptDecryptService";
import { createTransform } from 'redux-persist';

const encryptTransform = createTransform(
  // Transform state on its way to being serialized and stored.
  (inboundState, key) => {
    return EncryptDecryptService.encryptData(JSON.stringify(inboundState));
  },
  // Transform state being rehydrated
  (outboundState, key) => {
    return JSON.parse(EncryptDecryptService.decryptData(outboundState));
  },
  // Configuration options
  { whitelist: ['auth'] } // Included 'auth' data for encryption, customize as needed
);

export default encryptTransform;