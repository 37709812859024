import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { OptionProps } from "../../components/common/utlis/TypeAnnotations";
// import { DateInfoProps } from '../../components/common/layout/Header';

// Interface for the authentication state
interface AuthState {
    token: string;
    email: string;
    isAuthenticated: boolean;
    refreshToken: string | undefined;
    userId: number | null;
    role: string;
    name: string;
    userPermissions: any;
    isSuperAdmin: boolean;
    profilePicName: string;
    candidateId: number | null;
    profilePicId: number | null;
    profilePicPath: string;
    lastLogin: Date | null;
    // dateInfo: DateInfoProps;
    office: string;
    officeId: number | null;
}

// Initial date used for default dateInfo
const initialDate = new Date();

// Initial state for the authentication slice
const initialState: AuthState = {
    token: "",
    email: "",
    isAuthenticated: false,
    refreshToken: "",
    userId: null,
    role: "",
    name: "",
    userPermissions: [],
    isSuperAdmin: false,
    profilePicName: "",
    candidateId: null,
    profilePicId: null,
    profilePicPath: "",
    lastLogin: null,
    // dateInfo: {
    //     type: 'month',
    //     value: initialDate.getMonth() + 1, // Month value (1-12)
    //     label: initialDate.toLocaleString('default', { month: 'long' }) + " " + initialDate.getFullYear(), // e.g., "August 2024"
    //     year: initialDate.getFullYear() // Year value
    // },
    office: "",
    officeId: null
}

// Creating the authentication slice
const AuthSlice = createSlice({
    name: 'auth', // Name of the slice
    initialState, // Initial state for the slice
    reducers: { // Reducer functions
        // Reducer to add user details
        addUserDetails: (state, action: PayloadAction<object>) => {
            return { ...state, ...action.payload }; // Merge the current state with the payload
        },
        // Reducer to remove user details and reset to initial state
        removeUserDetails: (state, action: PayloadAction<void>) => {
            return initialState; // Reset state to initial state
        },
        // Reducer to add user profile picture (currently empty implementation)
        addUserProfilePic: (state, action: PayloadAction<string>) => {
            // Implementation can be added here
        }
    }
});

// Exporting the actions created by the slice
export const { addUserDetails, removeUserDetails } = AuthSlice.actions;

// Selector to access the authentication state
export const selectAuth = (state: RootState) => state.auth;

// Exporting the reducer function created by the slice
export default AuthSlice.reducer;
