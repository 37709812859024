import React, { useEffect, useState } from "react";
import { ApiCall } from "../../../services/ApiServices";
import Button from "../../common/atoms/Button";
import Pagination from "../../common/atoms/Pagination";
import CustomNotify from "../../common/atoms/CustomNotify";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import { Spinner } from "react-bootstrap";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import SearchBtn from "../../common/atoms/SearchBtn";
import ResetBtn from "../../common/atoms/ResetBtn";
import BackLink from "../../common/atoms/BackLink";
import OverFlowWrapper from "../../common/OverFlowWrapper";

interface manageLogData {
  requestId: number;
  requestData: string;
  to: string;
  status: number;
  count: number;
  createdAt: string;
  response: string;
}

interface formData {
  to: string;
  status: string;
}

const ManageEmailLogs: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formData>({
    to: "",
    status: "",
  });

  const [emailLogs, setEmailLogs] = useState([] as manageLogData[]);
  const [loading, setLoading] = useState(true);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchEmailLogs();
  }, []);

  //page index based on page number
  // let pageIndex = (currentPage - 1) * 20 + 1;

  //API call for to fetch email logs
  const fetchEmailLogs = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_EMAIL_LOGS,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      setEmailLogs(response.data);
      setTotalPages(response.totalPages);
      setLoading(false);
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.to.trim() === "" && formData.status.trim() === "") {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchEmailLogs();
    } else {
      fetchEmailLogs(formData, 1);
    }
  };

  const handleReset = () => {
    setFormData({
      to: "",
      status: "",
    });
    setCurrentPage(1);
    fetchEmailLogs();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchEmailLogs(formData, newPage);
  };

  const handleResendEmail = async (requestId: number) => {
    const data = {};
    const url = `${ENDPOINTS.SEND_EMAIL}/${requestId}`;
    const response = await ApiCall.service(
      url,
      "POST",
      data,
      true,
      "central-data-management"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      fetchEmailLogs();
    }
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Email templates",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Header
          headerName={t("Manage email logs")}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-3">
          <div className="row">
            <BackLink backLink="/" />
          </div>
          <div className="row">
            <div className="col-4">
              <LabelWithInputField
                isMandatory={false}
                name="to"
                handleChange={handleFieldChange}
                value={formData.to}
                id="to"
                label={t("To")}
                placeholder={t("To")}
              />
            </div>
            <div className="col-md-4">
              <LabelWithInputField
                isMandatory={false}
                name="status"
                handleChange={handleFieldChange}
                value={formData.status}
                id="status"
                label={t("Status")}
                placeholder={t("Status")}
              />
            </div>
            <div className="col-4">
              <div className="d-flex align-items-center seachBtnWrapper">
                <ResetBtn handleResetClick={handleReset} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="managepagesBg">
          <OverFlowWrapper className="table-responsive manageEmailLogs">
            <table className="table table-hover customTableProperties">
              <thead>
                <tr className="TableHeader">
                  <th>{t("Request data")}</th>
                  <th>{t("To")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Count")}</th>
                  <th>{t("Response")}</th>
                  <th>{t("Created at")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="border-0">
                    <td
                      className="text-center border-0 spinner-wrapper"
                      colSpan={7}
                    >
                      <Spinner size="sm" className="me-2" />
                      <span>{t("Loading")}</span>
                    </td>
                  </tr>
                ) : (
                  <>
                    {emailLogs && emailLogs.length > 0 ? (
                      emailLogs.map((emailLog) => (
                        <tr
                          key={emailLog.requestId}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          <td data-label={t("Request data")}>
                            {emailLog.requestData}
                          </td>
                          <td data-label={t("To")}>{emailLog.to}</td>
                          <td data-label={t("Status")}>{emailLog.status}</td>
                          <td data-label={t("Count")}>{emailLog.count}</td>
                          <td data-label={t("Response")}>
                            {emailLog.response}
                          </td>
                          <td data-label={t("Created at")}>
                            {emailLog.createdAt}
                          </td>
                          <td className="table-action-icons px-2">
                            <div className="d-none d-md-none d-lg-block">
                              {emailLog.status === 500 && (
                                <>
                                  <Button
                                    title={t("Resend")}
                                    handleClick={() =>
                                      handleResendEmail(emailLog.requestId)
                                    }
                                    className="btn form-button rounded-3 shadow-none"
                                  />
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={5}
                          className="border text-danger text-center"
                        >
                          {t("No records")}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </OverFlowWrapper>
          {totalPages > 1 && (
            <div className="pagination justify-content-center align-items-center mt-4 px-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(newPage) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
      </AccessControl>
    </>
  );
};

export default translate(ManageEmailLogs);
