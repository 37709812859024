import React, { useState, useEffect, useRef } from "react";
import InputTextfield from "../../common/atoms/InputTextField";
import {
  Fields,
  TimeStates,
  Weekdays,
  calculateTotalTime,
  calculateWeekTotal,
  convertTimeToPercentage,
  defaultFields,
  defaultWeekdays,
  fieldLabels,
  formatTime,
  formatTotalValue,
  isEqual,
  isValidTime,
  weekDaysLabels,
} from "./comon";
import { t } from "../translation/Translation";


interface WeeklyPlanningProps {
  weekdays?: Weekdays[];
  fields?: Fields[];
  onChange?: (timeStates: TimeStates) => void;
  value: TimeStates;
  //value?: any; // Make value mandatory
}

const TimeTable: React.FC<WeeklyPlanningProps> = ({
  weekdays = defaultWeekdays,
  fields = defaultFields,
  onChange,
  value,
}) => {
  const [quickEntries, setQuickEntries] = useState<Record<Fields, string>>(
    fields.reduce(
      (acc, field) => ({ ...acc, [field]: "" }),
      {} as Record<Fields, string>
    )
  );

  const [timeStates, setTimeStates] = useState<TimeStates>(value);

  const prevValueRef = useRef<TimeStates>(value);

  useEffect(() => {
    // Update state only if value has changed
    if (!isEqual(prevValueRef.current, value)) {
      setTimeStates(value);
      prevValueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    const updatedNumberTimes = weekdays.reduce((acc, day) => {
      const { from, to, breakFrom, breakUntil } = timeStates[day] || {};
      const totalTime = calculateTotalTime(from, to, breakFrom, breakUntil);
      const updatedTotal = convertTimeToPercentage(totalTime);

      acc[day] = {
        ...timeStates[day],
        total: updatedTotal,
      };

      return acc;
    }, {} as Record<Weekdays, Record<Fields, string>>);

    // Update state if there are changes in total time
    if (!isEqual(updatedNumberTimes, timeStates)) {
      setTimeStates(updatedNumberTimes);
      if (onChange) {
        onChange(updatedNumberTimes);
      }
    }
  }, [weekdays, timeStates, calculateTotalTime, convertTimeToPercentage]);

  const handleTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    day: Weekdays,
    field: Fields
  ) => {
    const value =
      field === "total"
        ? formatTotalValue(e.target.value)
        : formatTime(e.target.value);

    const updatedTimeStates = {
      ...timeStates,
      [day]: { ...timeStates[day], [field]: value },
    };

    setTimeStates(updatedTimeStates);

    if (onChange) {
      onChange(updatedTimeStates);
    }
  };

  const handleQuickEntryChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: Fields
  ) => {
    let value: string;
    if (field === "total") {
      value = formatTotalValue(e.target.value);
      applyQuickEntry(value, field);
    } else {
      value = formatTime(e.target.value);
      if (value.length === 5 && isValidTime(value)) {
        applyQuickEntry(value, field);
      } else if (value.length === 0) {
        applyQuickEntry("00:00", field);
      }
    }

    setQuickEntries((prev) => ({ ...prev, [field]: value }));
  };

  const applyQuickEntry = (value: string, field: Fields) => {
    const updatedTimeStates = weekdays
      .filter((day) => day !== 0 && day !== 6) // Exclude Saturday and Sunday
      .reduce(
        (acc, day) => ({
          ...acc,
          [day]: { ...timeStates[day], [field]: value },
        }),
        {} as Record<Weekdays, Record<Fields, string>>
      );

    setTimeStates((prev) => ({
      ...prev,
      ...updatedTimeStates,
    }));

    if (onChange) {
      onChange({
        ...timeStates,
        ...updatedTimeStates,
      });
    }
  };

  const getCellStyleForInvalidTime = (time: string, field: Fields) => {
    if (field === "total") return {}; // No validation styling for total field
    return !isValidTime(time) && time ? "error-border" : "";
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="weeklyPlanning">
            <table className="table table-bordered mb-0">
              <thead>
                <tr className="TableHeader">
                  <th></th>
                  <th>{t("Quick Entry")}</th>
                  {weekdays.map((day) => (
                    <th key={day}>{t(weekDaysLabels[day])}</th>
                  ))}
                  <th>{t("Week total")}</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index: number) => (
                  <tr key={field} className="border-bottom align-middle">
                    <td className="align-middle weeklyPlanningData">{t(fieldLabels[field])}</td>
                    <td className="p-0 position-relative align-middle">
                      <InputTextfield
                        type="text"
                        value={quickEntries[field]}
                        handleChange={(e) => handleQuickEntryChange(e, field)}
                        isDisabled={field === "total"}
                        // maxLength={field === "total" ? null : 5}
                        className={`position-absolute top-0 left-0 h-100 w-100 outline-0 text-center color-dark-purple ${getCellStyleForInvalidTime(
                          quickEntries[field],
                          field
                        )}`}
                      />
                    </td>
                    {weekdays.map((day) => (
                      <td
                        key={day}
                        className="p-0 position-relative align-middle"
                      >
                        <InputTextfield
                          type="text"
                          value={timeStates[day][field]}
                          handleChange={(e) => handleTimeChange(e, day, field)}
                          isDisabled={field === "total"}
                        //   maxLength={field === "total" ? null : 5}
                          className={`position-absolute top-0 left-0 h-100 w-100 outline-0 text-center color-dark-purple ${getCellStyleForInvalidTime(
                            timeStates[day][field],
                            field
                          )}`}
                        />
                      </td>
                    ))}
                    {index === 0 && (
                      <td
                        className="text-center disabled"
                        rowSpan={fields.length}
                      >
                        {calculateWeekTotal(timeStates)}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;



