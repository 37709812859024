import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import AccordionCustom from "../common/atoms/AccordionCustom";
import CheckBoxField from "../common/atoms/CheckBoxField";
import InputTextfield from "../common/atoms/InputTextField";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import Navigation from "./Navigation";

const SalaryBenefits = () => {
  const [isLastTab, setIsLastTab] = useState(false);
  const handleNext = () => {
    console.log("Next clicked");
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };
  return (
    <>
      <div style={{ minHeight: "calc(100vh - 22vw)" }}>
        <AccordionCustom
          accordionTitle={t("Name")}
          accordionTitleClassName="w-100 poppins-medium dashboardAccordionTitle"
          accordionButton="dashboardAccordionHeader shadow-none "
        >
          <div className="row">
            <div className="col-12 mb-2">
              <LabelField title={t("Bediende")} />
            </div>
            <div className="col-12">
              <table className="table mb-0 table-bordered">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }} className="align-middle">
                      <CheckBoxField
                        name="salaryBenefit"
                        label={t("Toeslag nachtwerk (bedrag)")}
                        className="my-checkbox"
                        isChecked={false}
                        onChangeHandler={() => {}}
                        id="salaryBenefit"
                        lineHeight="1vw"
                      />
                    </td>
                    <td>
                      <InputTextfield value="" handleChange={() => {}} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </AccordionCustom>
      </div>
      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </>
  );
};

export default SalaryBenefits;
