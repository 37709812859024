import React, { useState, useEffect } from "react";
import HeaderWIthFilter from "./HeaderWIthFilter";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DateAndTime from "../common/atoms/DateWithTime";
// import { t } from "../common/atoms/translation";
import EmployeeScheduling from "./EmployeeScheduling";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import WeeklyPlanning from "./WeeklyPlanning";
import PlanningTabs from "./PlanningTabs";
import {t , translate } from "../CentralDataMangement/translation/Translation";

interface CustomEvent {
  title: string;
  start: Date;
  end: Date;
  image?: string;
  allDay?: boolean;
}

const MonthlyPlanningOverview: React.FC = () => {
  const localizer = momentLocalizer(moment);
  const show = useSelector(selectPlanningShow); // Access the `show` state

  const [events] = useState<CustomEvent[]>([
    {
      title: "Meeting with Team",
      start: new Date(2024, 7, 10, 10, 0),
      end: new Date(2024, 7, 10, 11, 0),
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Project Deadline",
      start: new Date(2024, 7, 15),
      end: new Date(2024, 7, 15),
      allDay: true,
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Client Presentation",
      start: new Date(2024, 7, 20, 14, 0),
      end: new Date(2024, 7, 20, 15, 0),
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Client Presentation",
      start: new Date(2024, 7, 20, 14, 0),
      end: new Date(2024, 7, 20, 15, 0),
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Client Presentation",
      start: new Date(2024, 7, 20, 14, 0),
      end: new Date(2024, 7, 20, 15, 0),
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Client Presentation",
      start: new Date(2024, 7, 20, 14, 0),
      end: new Date(2024, 7, 20, 15, 0),
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Client Presentation",
      start: new Date(2024, 7, 20, 14, 0),
      end: new Date(2024, 7, 20, 15, 0),
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Client Presentation",
      start: new Date(2024, 7, 20, 14, 0),
      end: new Date(2024, 7, 20, 15, 0),
      image: "https://via.placeholder.com/150",
    },
  ]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      document
        .querySelectorAll<HTMLElement>(".rbc-event-content")
        .forEach((element) => {
          element.removeAttribute("title");
        });
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const Event: React.FC<{ event: CustomEvent }> = ({ event }) => (
    <div className="event-container">
      <span className="event-image">
        <img src={event.image} alt={event.title} />
      </span>
      <div className="eventTooltip">
        <div className="event-name">{event.title}</div>
      </div>
    </div>
  );

  return (
    <div className="container-fluid">
      <HeaderWIthFilter headerName={t("Planning - Month")} type="month">
        <DateAndTime
          showYear={true}
          isMonth={true}
          isIcon={true}
          monthFormat="long"
          dataTimeWrapperClassName="d-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
          dataTimeClassName="mx-3 planningPlannedDate"
          dayClassName="me-1"
          iconFillColor="#8571b9"
        />
      </HeaderWIthFilter>
      <div className="row">
        <div className="col-12">
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            views={["month"]}
            events={events}
            style={{ height: "100vh" }}
            toolbar={false}
            className="planningMonthCalendar"
            components={{
              event: Event,
            }}
          />
        </div>
      </div>
      {show && <PlanningTabs company={2} location={3}/>}        {/* Conditionally render the modal based on the `show` state */}

    </div>
  );
};

export default translate(MonthlyPlanningOverview);
