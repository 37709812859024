import React from "react";
import { BsDownload } from "react-icons/bs";
import Button from "../atoms/Button";
import CommonServices from "../../../services/common/CommonServices";

interface DownloadButtonProps {
    fileUrl: string;
    invoiceNumber: string | null;
}

const DownloadFile: React.FC<DownloadButtonProps> = ({ fileUrl, invoiceNumber }) => {
    const handleDownload = () => {
        CommonServices.downloadFile(fileUrl, invoiceNumber);
    }
    return (
        <Button
            handleClick={handleDownload}
            className="icon-btn btn btn-link text-white mx-1 p-1 fs-6"
            title={<BsDownload />}
        />
    );
};

export default DownloadFile;