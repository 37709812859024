import React, { useRef } from "react";
import Select from "react-select";
import LabelField from "./LabelField";
import { t } from "./translation";

interface Option {
  value?: number | string | null;
  label?: string;
}

interface DropdownSelectProps {
  search: boolean;
  options: Option[];
  isMulti?: boolean;
  value: any;
  onChange: (value: any) => void;
  placeHolder?: string;
  name?: string;
  id?: string;
  error?: number | null | undefined | string;
  className?: string;
  title?: string;
  isMandatory?: boolean;
  isDisabled?: boolean;
  isMenuPlacement?: boolean;
  isSelectOption?: boolean;
  style?: any;
  isTranslate?: boolean;
  isTop?: boolean;
  colClassName?: string;
  labelWrapperClassName?: string;
  fieldLabel?: string;
  labelClassName?: string;
  selectClassName?: string;
}

const SelectWithSearch: React.FC<DropdownSelectProps> = ({
  search,
  options,
  isMulti,
  value,
  id,
  onChange,
  placeHolder,
  name,
  error,
  className,
  title,
  isMandatory,
  isDisabled = false,
  isMenuPlacement = false,
  isSelectOption = true,
  style,
  isTranslate = false,
  isTop = false,
  colClassName,
  labelWrapperClassName,
  fieldLabel,
  labelClassName,
}) => {
  const shouldUseFullWidth = !fieldLabel || (fieldLabel && isTop);

  const defaultPlaceholder = placeHolder || t("Select");
  const emptyOption = isSelectOption ? { value: null, label: "Select" } : null;
  const updatedOptions = emptyOption ? [emptyOption, ...options] : options;

  const transformedLabel = (label: string): string => {
    if (label === "") {
      return "";
    }

    // Check labels having parentheses (), in the starting of a string
    const match = label.match(/^\s*\((.*?)\)\s*(.*)/);
    if (match) {
      const contentInParentheses = match[1]; // Extract the content within parentheses
      const restLabel = match[2].trim(); // Extract the rest of the label after the parentheses

      // Apply translation to the second part of the label
      const translatedRestLabel = restLabel ? `${t(restLabel)}` : "";

      return `(${contentInParentheses}) ${translatedRestLabel}`;
    } else {
      const [numericPart, ...rest] = label.split(" - ");
      const restLabel = rest.join(" - ");
      return rest.length > 0 ? `${numericPart} - ${t(restLabel)}` : numericPart;
    }
  };

  let translateOptions;
  if (
    name === "city" ||
    name === "modalCity" ||
    name === "pc" ||
    name === "domCity" ||
    name === "biCity" ||
    name === "PC2" ||
    name === "PC" ||
    name === "lowCity" ||
    name === "mediumCity" ||
    name === "highCity"
  ) {
    translateOptions = (opts: Option[]) =>
      opts.map((option) => {
        const label = option.label || "";
        return {
          ...option,
          label: transformedLabel(label),
        };
      });
  } else {
    translateOptions = (opts: Option[]) =>
      opts.map((option) => ({
        ...option,
        label: t(option.label || ""),
      }));
  }

  const translatedOptions = isTranslate
    ? translateOptions(updatedOptions)
    : updatedOptions;
  const translatedMultiOptions = isTranslate
    ? translateOptions(options)
    : options;

  let translateValue;
  if (
    name === "city" ||
    name === "modalCity" ||
    name === "pc" ||
    name === "domCity" ||
    name === "biCity" ||
    name === "PC2" ||
    name === "PC" ||
    name === "lowCity" ||
    name === "mediumCity" ||
    name === "highCity"
  ) {
    translateValue = (val: any) => {
      if (Array.isArray(val)) {
        return val.map((item) => {
          const label = item.label || "";
          return {
            ...item,
            label: transformedLabel(label),
          };
        });
      } else if (val) {
        const label = val.label || "";
        return {
          ...val,
          label: transformedLabel(label),
        };
      }
      return val;
    };
  } else {
    translateValue = (val: any) => {
      if (Array.isArray(val)) {
        return val.map((item) => ({
          ...item,
          label: t(item.label || ""),
        }));
      } else if (val) {
        return {
          ...val,
          label: t(val.label || ""),
        };
      }
      return val;
    };
  }

  const translatedValue = isTranslate ? translateValue(value) : value;
  const menuPortalTarget = useRef(document.body);

  // Determine the class names to apply
  const selectClassName = className
    ? className
    : "align-self-center"; // Apply default class if className is not provided

  return (
    <>
      {fieldLabel && (
        <div
          className={`text-start ${
            isTop
              ? "col-12"
              : ` ${labelWrapperClassName} pe-0 ${selectClassName}`
          }`}
        >
          <LabelField title={title} isMandatory={isMandatory} className={labelClassName}/>
        </div>
      )}
      <div
        className={`text-start ${
          shouldUseFullWidth ? "col-12" : `${colClassName}`
        }`}
      >
        <Select
          isSearchable={search}
          id={id}
          className={`form-control border-0 p-0 form-select field-shadow rounded-3 select-field ${selectClassName}`}
          options={isMulti ? translatedMultiOptions : translatedOptions}
          isMulti={isMulti}
          value={translatedValue}
          placeholder={defaultPlaceholder}
          onChange={onChange}
          name={name}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...style,
            }),
            menu: (provided: any, state) => ({
              ...provided,
              overflow: "visible",
            }),
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            menuList: (provided: any, state) => ({
              ...provided,
              maxHeight: "200px",
              overflowY: "auto",
            }),
            singleValue: (baseStyles: any, state) => ({
              ...baseStyles,
              color: isDisabled ? "#212529" : baseStyles.color,
              fontSize: "0.9vw"
            }),
            multiValue: (baseStyles: any, state) => ({
              ...baseStyles,
              color: isDisabled ? "#212529" : baseStyles.color,
              fontSize: "0.9vw"
            }),
            indicatorSeparator: (baseStyles: any) => ({
              ...baseStyles,
              display: "none",
            }),
            noOptionsMessage: (baseStyles: any) => ({
              ...baseStyles,
              color: "red",
              fontSize: "0.8vw",
            }),
          }}
          isDisabled={isDisabled}
          menuPlacement={isMenuPlacement ? "top" : "auto"}
          classNamePrefix="customSelect multiSelectDropdown"
          menuPortalTarget={menuPortalTarget.current} // Set the target for rendering the menu outside the DOM hierarchy
          noOptionsMessage={() => t("No records")}
        />
      </div>
      <div className="mt-2 mb-2">
        {error && (
          <div className="text-danger">{error !== undefined ? error : ""}</div>
        )}
      </div>
    </>
  );
};

export default SelectWithSearch;
