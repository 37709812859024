import React, { useEffect, useState, useContext } from 'react';
import { ApiCall } from '../../../../services/ApiServices';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';
import { FETCH_COEFFICIENT_PCS } from '../../../../routes/ApiEndpoints';
import TableRenderer from './TableRenderer';

const ManageCoefficientPcs = (props) => {
    const [state, setState] = useState({ rows: [], headers: [], loaded: false });

    useEffect(() => {
        loadData();
    }, []);
    const loadData = async () => {
        ApiCall.getService(
            FETCH_COEFFICIENT_PCS,
            "GET",
            "central-data-management",
            true
        )
            .then((response) => {
                setState({
                    ...state,
                    rows: response.success ? response.data : [],
                    loaded: true,
                    headers: ['Paritair comite number', 'Paritair comite name', 'Actions']
                });
            })
            .catch((error) => console.error('Error occurred'));
    };
    return (
        <div>
            <div className="container-fluid man px-0">
                <div className=" md-3">
                    {state.loaded === true ? (
                        <TableRenderer {...state} />
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default translate(ManageCoefficientPcs);
