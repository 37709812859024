import React from "react";
import DateAndTime from "../common/atoms/DateWithTime";
// import { t } from "../common/atoms/translation";
import HeaderWIthFilter from "./HeaderWIthFilter";
import PlanningTable from "./PlanningTable";
import { useSelector } from "react-redux";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import WeeklyPlanning from "./WeeklyPlanning";
import PlanningTabs from "./PlanningTabs";
import { t ,translate } from "../CentralDataMangement/translation/Translation";


const DayPlanningOverview = () => {
  const show = useSelector(selectPlanningShow); // Access the `show` state
  return (
    <div className="container-fluid">
      <div className="headerWrapper">
        <HeaderWIthFilter headerName={t("Planning - Day")} type={'day'}>
          <DateAndTime
            isDate={true}
            showDate={true}
            showMonth={true}
            showYear={true}
            isDay={true}
            isIcon={true}
            monthFormat="long"
            dataTimeWrapperClassName="d-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
            dataTimeClassName="mx-3 planningPlannedDate"
            dayClassName="me-1"
            iconFillColor="#8571b9"
          />
        </HeaderWIthFilter>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="dayPlanningOverviewTable">
            <PlanningTable />
          </div>
        </div>
      </div>

      {show && <PlanningTabs />} {/* Conditionally render the modal based on the `show` state */}
    </div>
  );
};

export default translate(DayPlanningOverview);
