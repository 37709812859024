import React from "react";
import Icon from "../../../Icon";
import { t ,translate } from "../../CentralDataMangement/translation/Translation";
// import { t } from "../atoms/translation";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footerContainer footerContainerBg px-0">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-11 mx-auto h-100">
            <div className="row h-100">
              <div className="col-3 footerContent text-white align-self-center">
                {t("Copyright ") + currentYear + t(" AbsoluteYOU")}
              </div>
              <div className="col-5 offset-2 align-self-center">
                <div className="d-grid text-white">
                  <div className="footerContent">{t("Contact us")}</div>
                  <div className="footerContent">{t("Privacy policy")}</div>
                  <div className="footerContent">{t("Disclaimer")}</div>
                  <div>
                    <Icon name="instagram" width="1.3vw" height="1.3vw" className="me-3" isIcon={true} />
                    <Icon name="facebook" width="1.3vw" height="1.3vw" isIcon={true} />
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center">
                <div className="text-white poweredBy">{t("Powered by")}:</div>
                <Icon name="logoWhite" width="7vw" height="1.5vw" isIcon={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(Footer);
