import React from "react";
import {
  t,
  translate,
} from "../../components/CentralDataMangement/translation/Translation";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";
import { CANDIDATE_ROLE } from "../../Constants";
import Header from "../../components/common/layout/Header";

const PageNotFound: React.FC = () => {
  const userAuth = useSelector(selectAuth);

  return (
    <>
      <Header />
      <div className="row accessDeniedWrapper">
        <div className="col-12 text-center h-100">
          <div className="contentBg h-100 d-flex align-items-center justify-content-center flex-column">
            <div className="access-denied-code">404</div>
            <div className="access-denied-title pb-4">
              {t("Page not found")}
            </div>
            <div className="access-denied-content mb-4">
              {t(
                "It looks like the page you were searching for is missing or doesn't exist. Let's find a different path to explore together."
              )}
            </div>
            {userAuth.role === CANDIDATE_ROLE ? (
              <Link
                to="/dashboard"
                className="form-button rounded-3 p-3 page-not-found-back-link"
              >
                {t("Go to homepage")}
              </Link>
            ) : (
              <Link
                to="/"
                className="form-button rounded-3 p-3 page-not-found-back-link"
              >
                {t("Go to dashboard")}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(PageNotFound);
