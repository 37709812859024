import React from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { formatDate, formatDateTime } from "../utlis/dateHelper";

interface CalendarProps {
  selectedDates: string[];
  onDateChange: (date: string[]) => void;
  minDate: Date;
  calendarType: "week" | "month"; // New prop to specify week or month view
}

const Calendar: React.FC<CalendarProps> = ({
  selectedDates,
  onDateChange,
  minDate,
  calendarType,
}) => {

  // Helper function to get the start of the week
  const getStartOfWeek = (date: Date) => {
    const dayOfWeek = date.getDay();
    const start = new Date(date);
    start.setDate(date.getDate() - dayOfWeek);
    return start;
  };

  // Helper function to get the end of the week
  const getEndOfWeek = (date: Date) => {
    const dayOfWeek = date.getDay();
    const end = new Date(date);
    end.setDate(date.getDate() + (6 - dayOfWeek));
    return end;
  };

  // Handle multiple date selection
  const handleDateChange = (date: Date | Date[] | null) => {
    if (date instanceof Date) {
      const newSelectedDates = [...selectedDates];

      // Check if the selected date is already in the array, remove it if so
      if (newSelectedDates.some((selectedDate) => new Date(selectedDate).getTime() === date.getTime())) {
        const updatedDates = newSelectedDates.filter(
          (selectedDate) => new Date(selectedDate).getTime() !== date.getTime()
        );
        onDateChange(updatedDates); // Return updated dates via props function
      } else {
        // Add the new selected date
        const updatedDates = [...newSelectedDates, formatDateTime(date)];
        onDateChange(updatedDates); // Return updated dates via props function
      }
    } else if (date === null) {
      // Handle the case when no date is selected
      onDateChange([]);
    }
  };

  // Conditionally disable dates outside the current week if the calendarType is "week"
  const tileDisabled = ({ date }: { date: Date }) => {
    if (calendarType === "week") {
      const startOfWeek = getStartOfWeek(new Date());
      const endOfWeek = getEndOfWeek(new Date());
      return date < startOfWeek || date > endOfWeek; // Disable dates outside the current week
    }
    return false;
  };

  return (
    <div className="calendarWrapper">
      <ReactCalendar
        onClickDay={handleDateChange} // Handles date clicks
        className="mx-auto"
        minDate={minDate ?? ""}
        tileDisabled={tileDisabled} // Disable dates based on the week or month
        tileClassName={({ date }) =>
          selectedDates.some(
            (selectedDate) => formatDate(selectedDate) === formatDateTime(date)
          )
            ? "react-calendar__tile--active" // Adds class for selected dates
            : ""
        }
        showWeekNumbers={true}
      />
    </div>
  );
};

export default Calendar;
