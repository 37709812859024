import React from "react";
import LabelField from "../atoms/LabelField";
import SelectWithSearch from "../atoms/SelectWithSearch";
import Title from "../atoms/Title";
import ToggleButton from "../atoms/ToggleButton";
import { t } from "../atoms/translation";
import { LabelWithInputField } from "./LabelWithInputField";

const Address = () => {
  const inputFieldSharedProps = {
    isTop: false,
    labelClassName: "poppins-medium colorPrimary ",
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <Title
            title={t("Address") + ":"}
            className="bodyTitle colorPrimary tab-title"
          />
        </div>
      </div>
      <div className="row">
          {/* Domicilary address */}
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("Street name") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"streetName"}
                id="streetName"
                isMandatory={true}
                {...inputFieldSharedProps}
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
              />
            </div>
          </div>
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("House no") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"houseNo"}
                id="houseNo"
                isMandatory={true}
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
                {...inputFieldSharedProps}
              />
            </div>
          </div>
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("Postal code") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"postalCode"}
                isMandatory={true}
                id="postalcode"
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
                {...inputFieldSharedProps}
              />
            </div>
          </div>
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("Country") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"country"}
                id="country"
                isMandatory={true}
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
                {...inputFieldSharedProps}
              />
            </div>
          </div>
          {/* checkbox */}
          <div className="col-12 d-flex align-items-center mb-3">
            <LabelField
              title={
                t(
                  "Is the residential address the same as the domiciliary address"
                ) + ":"
              }
              className="mb-0 colorPrimary poppins-medium me-3"
            />
            <ToggleButton />
          </div>
          {/* Residential address */}
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("Street name") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"streetName"}
                id="streetName"
                {...inputFieldSharedProps}
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
              />
            </div>
          </div>
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("House no") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"houseNo"}
                id="houseNo"
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
                {...inputFieldSharedProps}
              />
            </div>
          </div>
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("Postal code") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"postalCode"}
                id="postalcode"
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
                {...inputFieldSharedProps}
              />
            </div>
          </div>
          <div className="col-4 mb-3">
            <div className="row">
              <LabelWithInputField
                label={t("Country") + ":"}
                type="text"
                value={""}
                handleChange={() => {}}
                name={"country"}
                id="country"
                className="inputFieldColor"
                colClassName="col-8"
                labelWrapperClassName="col-4 align-self-center"
                {...inputFieldSharedProps}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 mb-3 multiSelectWrapper">
            <div className="row">
              <SelectWithSearch
                fieldLabel={t("Means of transport") + ":"}
                title={t("Means of transport")}
                name="meansOfTransport"
                placeHolder={t("Select")}
                search={true}
                options={[]}
                value={() => {}}
                onChange={() => {}}
                isMulti={false}
                className="select-field"
                error=""
                isTranslate={true}
                labelWrapperClassName="col-4 align-self-center"
                colClassName="col-8"
              />
            </div>
          </div>
          <div className="col-4 mb-3 multiSelectWrapper">
            <div className="row">
              <SelectWithSearch
                fieldLabel={t("Driving licence") + ":"}
                title={t("Driving licence")}
                name="drivingLicence"
                placeHolder={t("Select")}
                isMandatory={true}
                search={true}
                options={[]}
                value={() => {}}
                onChange={() => {}}
                isMulti={false}
                className="select-field"
                error=""
                isTranslate={true}
                labelWrapperClassName="col-4 align-self-center"
                colClassName="col-8"
              />
            </div>
          </div>
          <div className="col-4 mb-3 multiSelectWrapper">
            <div className="row">
              <SelectWithSearch
                fieldLabel={t("Transport to work") + ":"}
                title={t("Transport to work")}
                name="transportToWork"
                placeHolder={t("Select")}
                search={true}
                options={[]}
                value={() => {}}
                onChange={() => {}}
                isMulti={false}
                className="select-field"
                error=""
                isTranslate={true}
                labelWrapperClassName="col-4 align-self-center"
                colClassName="col-8"
              />
            </div>
          </div>
        </div>
    </>
  );
};

export default Address;
