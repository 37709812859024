import React, { useState } from "react";
import Icon from "../../Icon";
// import { t } from "../common/atoms/translation";
import { t, translate }  from "../CentralDataMangement/translation/Translation"
import IconWithTitle from "../common/molecules/IconWithTitle";
import "./css/welcomeDashboard.css";
import imageOne from "../../static/images/DummyImage.jpg";
import imageTwo from "../../static/images/DummyImage1.png";
import ModalPopup from "../common/atoms/ModalPopup";
import Button from "../common/atoms/Button";
import Footer from "../common/layout/Footer";
import Title from "../common/atoms/Title";
import Image from "../common/atoms/Image";
import InputTextFieldWithLabel from "../common/molecules/InputTextFieldWithLabel";
import LabelField from "../common/atoms/LabelField";
import InputTextField from "../common/atoms/InputTextField";
import userIcon from "../../static/images/User.svg";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Address from "../common/molecules/Address";
import { useNavigate } from "react-router-dom";
import AddCompanyModal from "./AddCompany";
import Header from "../common/layout/Header";
type OptionType = { label: string; value: string };

export const icons = [
  {
    src: imageOne,
    title: t("De sandwich"),
  },
  {
    src: imageTwo,
    title: t("Agreement"),
  },
  {
    src: imageTwo,
    title: t("Company"),
  },
];

const WelcomeDashboard = () => {

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const navigator = useNavigate();
  const [pcValue, setPcValue] = useState<OptionType[] | null>(null);

  const handlePcChange = (selectedOption: OptionType[] | null) => {
    setPcValue(selectedOption);
  };
  const handleCompanyClick = () => {
    navigator('/location-dashboard')
  }
  return (
    <>
    {/* <Header/> */}
      <div className="container-fluid">
        <div className="welcomeDashboardWrapper">
          <div className="bg-white rounded-5">
            <div className="row">
              <div className="col-12 text-center logoSection">
                <Icon name="Logo" width="15vw" height="3vw" isIcon={true} />
              </div>
              <div className="col-12 text-center welcomeText colorPrimary">
                {t("Welcome")}!
                <br />
                {t("Click on a company to start")}
              </div>
              <div className="col-12 companyWrapper">
                <div className="d-grid companyWrapperGrid">
                  {icons.map((iconProps, index) => (
                    <div key={index}>
                      <IconWithTitle
                        isImage={true}
                        isTitle={true}
                        isSubIcon={true}
                        // image properties
                        imageBorderRadius="50%"
                        imageWidth="7vw"
                        imageHeight="7vw"
                        imageWrapperClassName="text-center"
                        // label properties
                        labelClassName="colorPrimary companyName"
                        titleImageWrapperClassName="text-center mt-3"
                        // icon properties
                        subIconWidth="1vw"
                        subIconHeight="1vw"
                        subIconImageWrapperClassName="text-center mt-2"
                        subIconname="Settings"
                        {...iconProps}
                        handleCompanyClick={handleCompanyClick}
                      />
                    </div>
                  ))}
                  <div className="addCompany text-center">
                    <Button
                      handleClick={handleShowModal}
                      className="bg-transparent border-0 cursor-pointer"
                    >
                      <IconWithTitle
                        isIcon={true}
                        isTitle={true}
                        // icon properties
                        name="add"
                        borderRadius="50%"
                        width="7vw"
                        height="7vw"
                        iconImageWrapperClassName="text-center"
                        className="p-4"
                        iconBackgroundColor="#898989"
                        fillColor="#fff"
                        // label properties
                        labelClassName="colorPrimary companyName cursor-pointer"
                        title={t("Add company ranjith 007")}
                        titleImageWrapperClassName="text-center mt-3"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <AddCompanyModal
            show={showModal}
            handleClose={handleCloseModal}
            pcValue={pcValue}
            handlePcChange={handlePcChange}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default translate(WelcomeDashboard);
