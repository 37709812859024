import React, { useState } from "react";
import Icon from "../../Icon";
import Image from "../common/atoms/Image";
import { t } from "../common/atoms/translation";
import UserImage from "../../static/images/User.svg";
import "./css/contracts.css";

const ContractTable = () => {
  const IconProps = {
    isIcon: true,
  };
  const [isShift, setIsShift] = useState(false);
  return (
    <>
      <table className="table contractTable table-borderless mb-0">
        <tbody>
          <tr className="spaced-row">
            <td className="p-0 align-middle text-center">
              <Image
                src={UserImage}
                imageWidth="2vw"
                imageHeight="2vw"
                imageBorderRadius="50%"
              />
            </td>
            <td className="align-middle">Naam medewerker</td>
            <td className="align-middle">From 19/04 to 19/04	</td>
            <td className="align-middle">
              <div className="d-flex">
                <span
                  data-bs-toggle="tooltip"
                  title={t("View")}
                  onClick={() => {}}
                >
                  <Icon
                    name="viewIcon"
                    {...IconProps}
                    className="me-2 cursor-pointer secondaryColorHoverEffect"
                    width="1vw"
                    height="1vw"
                  />
                </span>
                <span
                  data-bs-toggle="tooltip"
                  title={t("Download")}
                  onClick={() => {}}
                >
                  <Icon
                    name="downloadIcon"
                    {...IconProps}
                    className="cursor-pointer secondaryColorHoverEffect"
                    width="0.8vw"
                    height="0.8vw"
                  />
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td
              colSpan={4}
              style={{ height: "0.7vw" }}
              className="emptyRow p-0"
            ></td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ContractTable;
