import React, { ReactNode } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
// import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";
import PlanningFilterSection from "./PlanningFilterSection";
import { t, translate } from "../CentralDataMangement/translation/Translation";

interface HeaderWithFilterProps {
  children?:ReactNode;
  headerName?:string;
  type?:string;
  page?:string;
}
const HeaderWIthFilter:React.FC<HeaderWithFilterProps> = ( {
  children,
  headerName,
  type,
  page,
}) => {
  return (
    <div className="row">
      <div className="col-12 ">
        <Header headerName={headerName} />
      </div>

      <div className="col-12">
        <TableHeaderWithIcon
          title={t("Location Name")}
          TableHeaderWithIconClassName="plannedLocationHeader px-3"
          TableHeaderTitle="poppins-semibold text-white"
        />
      </div>
      <PlanningFilterSection type={type} page={page} >
       {children}
      </PlanningFilterSection>
    </div>
  );
};

export default translate(HeaderWIthFilter);
