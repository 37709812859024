import React, { useState } from "react";
import Icon from "../../Icon";
import DateAndTime from "../common/atoms/DateWithTime";
import TimePicker from "../common/atoms/TimePicker";
// import { t } from "../common/atoms/translation";
import {t , translate } from "../CentralDataMangement/translation/Translation";

interface Props {
  showLastAction?: boolean;
  isTimeRegistration?: boolean;
  category?: "open" | "closed";
  activeEmployee?:any
}

const ShiftTiming: React.FC<Props> = ({
  showLastAction = false,
  isTimeRegistration = false,
  category = "open",
  activeEmployee,
}) => {
  const IconProps = {
    width: "0.8vw",
    height: "0.8vw",
    isIcon: true,
  };
  const [isShift, setIsShift] = useState(false);

  const shiftCells = (index: number) => (
    <>
      <td key={`shift1-${index}`} className="bodyBg align-middle">
        <div className="d-flex align-items-center justify-content-center">
          <TimePicker
            setHourMin={() => {}}
            type={""}
            index={index}
            value={""}
            hour={""}
            minute={""}
            timePickerClassName="d-flex align-items-center justify-content-center me-2"
          />
          <TimePicker
            setHourMin={() => {}}
            type={""}
            index={index}
            value={""}
            hour={""}
            minute={""}
            timePickerClassName="d-flex align-items-center justify-content-center"
          />
        </div>
      </td>
      <td key={`shift2-${index}`} className="bodyBg align-middle">
        {isShift ? (
          <div className="d-flex align-items-center justify-content-center">
            <TimePicker
              setHourMin={() => {}}
              type={""}
              index={index + 1}
              value={""}
              hour={""}
              minute={""}
              timePickerClassName="d-flex align-items-center justify-content-center"
            />
            <TimePicker
              setHourMin={() => {}}
              type={""}
              index={index}
              value={""}
              hour={""}
              minute={""}
              timePickerClassName="d-flex align-items-center justify-content-center"
            />
          </div>
        ) : (
          <span
            data-bs-toggle="tooltip"
            title={t("Add")}
            onClick={() => setIsShift(true)}
          >
            <Icon
              name="add"
              width="0.8vw"
              height="0.8vw"
              isIcon={true}
              fillColor="#8571b9"
            />
          </span>
        )}
      </td>
      <td key={`total-${index}`} className="bodyBg align-middle">
        <div className="totalHours text-white poppins-medium">0 : 0</div>
      </td>
    </>
  );

  return (
    <table
      className={`${
        isTimeRegistration ? "timeRegistrationTable" : "shiftTimingTable"
      } table table-borderless mb-0`}
    >
      <thead>
        {isTimeRegistration ? (
          <>
            <tr>
              <th></th>
              {[
                "Planned hours",
                "Clocked-in hours",
                "Effective hours worked",
              ].map((category) => (
                <th
                  key={category}
                  colSpan={3}
                  className="text-center categorySection"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <Icon name="lockIcon" {...IconProps} fillColor="#87abb8" />
                    <span className="ms-2">{t(category)}</span>
                  </div>
                </th>
              ))}
              <th></th>
            </tr>
            <tr>
              <th></th>
              {[...Array(3)].map((_, index) => (
                <React.Fragment key={index}>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 1"}
                  </th>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 2"}
                  </th>
                  <th className="text-start text-center shiftNumber">
                    {t("Total")}
                  </th>
                </React.Fragment>
              ))}
              <th></th>
            </tr>
          </>
        ) : (
          <tr>
            <th></th>
            <th>{t("Shift") + " 1"}</th>
            <th>{t("Shift3") + " 2"}</th>
            <th className="text-start">{t("Total")}</th>
            <th></th>
          </tr>
        )}
      </thead>
      <tbody>
        <tr className="spaced-row">
          <td className="p-0">
            <DateAndTime
              isDay={true}
              isDate={true}
              showDate={true}
              dataTimeClassName="d-flex flex-column align-items-center shiftDate"
              monthFormat="short"
            />
          </td>
          {isTimeRegistration
            ? [...Array(3)].map((_, index) => shiftCells(index))
            : shiftCells(0)}
          <td className="actionWrapper bodyBg align-middle">
            <div className="d-flex">
              {isTimeRegistration ? (
                category === "closed" ? (
                  ""
                ) : (
                  <>
                    <div className="ms-2">
                      <div
                        style={{
                          width: "0.8vw",
                          height: "0.8vw",
                          backgroundColor: "#8571b9",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                    <div className="ms-2">
                      <div
                        style={{
                          width: "0.8vw",
                          height: "0.8vw",
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                  </>
                )
              ) : (
                <>
                  <span
                    data-bs-toggle="tooltip"
                    title={t("Edit")}
                    onClick={() => {}}
                  >
                    <Icon
                      name="EditIcon"
                      {...IconProps}
                      className="me-2 cursor-pointer"
                    />
                  </span>
                  <span
                    data-bs-toggle="tooltip"
                    title={t("Remove")}
                    onClick={() => {}}
                  >
                    <Icon
                      name="RemoveIcon"
                      {...IconProps}
                      className="cursor-pointer"
                    />
                  </span>
                </>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td
            colSpan={isTimeRegistration ? 11 : 5}
            style={{ height: "0.7vw" }}
            className="emptyRow p-0"
          ></td>
        </tr>
      </tbody>
    </table>
  );
};

export default translate(ShiftTiming);
