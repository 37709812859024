export  interface InputField {
    label: string;
    name: keyof LocationFormData;
    id: string;
    placeholder: string;
    isMandatory?: boolean;
    type: string;
}

export interface LocationFormData {
    company_id : number[] | any;
    location_pic:string;
    location_name: string;
    street: string;
    number: string;
    postal_code: string;
    box: string;
    city: string;
    country: string;
}

export interface fileData {
    fileId : number | null,
    filePath: string,
    fileName:string
}

export  const intialFileData:fileData =  {
    fileId : null,
    filePath: '',
    fileName:''
}

export const inputFields: InputField[] = [
    { label: "Location name", name: "location_name", id: "locationName", placeholder: "Enter location name", isMandatory: true , type: "text"},
    { label: "Street name", name: "street", id: "streetName", placeholder: "Enter street name", isMandatory: true ,  type: "text"},
    { label: "House number", name: "number", id: "houseNumber", placeholder: "Enter house number", isMandatory: true ,  type: "text"},
    { label: "Postal code", name: "postal_code", id: "postalCode", placeholder: "Enter postal code", isMandatory: true ,  type: "text"},
    { label: "Box", name: "box", id: "box", placeholder: "Enter box" ,  type: "text"},
    { label: "City", name: "city", id: "city", placeholder: "Enter city", isMandatory: true ,  type: "text"},
    { label: "Country", name: "country", id: "country", placeholder: "Select country", isMandatory: true , type: "select" },
];

export const formatPostalCode = (value: string) => {
    // Replace all non-digit characters with an empty string
    return value.replace(/\D+/g, "");
};

export const formatHouseNumber = (value :string) =>{
     // Remove any character that is not alphanumeric, space, or dot
     const cleanedValue = value.replace(/[^\w\s.]/g, '');
     // Trim the cleanedValue to ensure it does not exceed 50 characters
     return cleanedValue.slice(0, 50);
}

export const formatStreet = (value :string) =>{
    // Remove any character that is not alphanumeric, space, or dot
    const cleanedValue = value.replace(/[^\w\s.]/g, '');
    // Trim the cleanedValue to ensure it does not exceed 50 characters
    return cleanedValue.slice(0, 50);
}