import LoadingIcon from './LoadingIcon';
import React from 'react'; // Add this line if it's missing


export const FullScreenLoading = () => {
  return (
    <div className="fullscreen-loading-container">
        <LoadingIcon width={100} />
    </div>
  )
}
