import { classNames } from "primereact/utils";
import React, { ReactNode, useEffect, useRef, useState } from "react";

interface OverflowWrapperProps {
  children?: ReactNode;
  className?: string;
}

const OverFlowWrapper: React.FC<OverflowWrapperProps> = ({ children, className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      setIsOverflowing(wrapper.scrollHeight > wrapper.clientHeight);
    }
  };

  useEffect(() => {
    checkOverflow(); // Initial check for overflow

    // Listener for window resize events
    window.addEventListener("resize", checkOverflow);

    // Observer to monitor changes in the wrapper
    const observer = new MutationObserver(() => {
      checkOverflow();
    });

    // Observer to monitor visibility of the wrapper
    const visibilityObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            checkOverflow();
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observing changes when the component mounts
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true, // Track attribute changes, useful for styles affecting size
      });

      visibilityObserver.observe(wrapperRef.current);
    }

    // Cleanup listeners and observers
    return () => {
      window.removeEventListener("resize", checkOverflow);
      observer.disconnect();
      visibilityObserver.disconnect();
    };
  }, []);

  return (
    <div
      className={classNames("scrollBar", className, {
        "has-scroll": isOverflowing,
      })}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
};

export default OverFlowWrapper;
