import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { MdEdit, MdDelete } from 'react-icons/md';
import { ApiCall } from '../../../../services/ApiServices';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";


import ReactPaginate from 'react-paginate';
import { DELETE_COEFFICIENT_PCS } from '../../../../routes/ApiEndpoints';
const itemsPerPage = 8;

const TableRenderer = ({ headers, rows, manageType, t, ...props }) => {
    const [state, setState] = useState({
        searchTermNumber: '',
        searchTermName: '',
        deleteUrl: DELETE_COEFFICIENT_PCS,
        filterRows: rows,
        currentItems: [],
        pageCount: 0,
        itemOffset: 0,
        currentPage: 0,
    })

    useEffect(() => {
        setState({ ...state, filterRows: rows, deleteUrl: DELETE_COEFFICIENT_PCS })
    }, [rows.length])

    const navigate = useNavigate();


    const getNeededActions = (eachRow) => {
        return (
            <>
                {/* DESKTOP */}
                <div className='d-none d-md-none d-lg-block'>
                    <span title={t('Edit')} className="actions-span text-dark" onClick={() => handleActionClick('edit', eachRow)}> <MdEdit className=' color-skyblue' /> </span>
                    <span title={t('Delete')} className="actions-span text-dark" onClick={() => handleActionClick('delete', eachRow)}> <MdDelete className='color-skyblue' /> </span>
                </div>
                {/* IPAD */}
                <div className='d-block d-md-block d-lg-none text-center row'>
                    <span title={t('Edit')} className="actions-span text-dark" onClick={() => handleActionClick('edit', eachRow)}>
                        <button type='button' name='Edit' className='btn-design-sm text-uppercase'>{t('Edit')}</button>
                    </span>
                    <span title={t('Delete')} className="actions-span text-dark" onClick={() => handleActionClick('delete', eachRow)}>
                        <button type='button' name='Delete' className='btn-design-sm-delete text-uppercase mt-3'>{t('Delete')}</button>
                    </span>
                </div>
            </>
        )
    }
    const handleActionClick = (action, eachRow) => {
        if (action === 'delete') {
            confirmAlert({
                message: t('Do you want to delete the coefficients per PC?'),
                buttons: [
                    { label: t('No') },
                    { label: t('Yes'), onClick: () => handleDelete(eachRow.id) }
                ]
            });
        } else {
            navigate(`/manage/coefficient-employee-type-linking/?pcid=${eachRow.id}`);
        }
    }
    const handleDelete = async (id) => {
        try {
            const result = await ApiCall.service(`${state.deleteUrl}/${id}`, "DELETE");
            if (result.success) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSearch = (search = 1) => {
        let filterRows = [];
        let { searchTermName = '', searchTermNumber = '' } = state;
        if (search && (searchTermName || searchTermNumber)) {
            filterRows = rows.filter((item) => {
                let status = true;
                if (searchTermName)
                    status = `${item['name']}`.toLowerCase().toString().indexOf(searchTermName.toLowerCase().toString()) !== -1;
                if (status && searchTermNumber)
                    status = `${item['pc_number']}`.toLowerCase().toString().indexOf(searchTermNumber.toLowerCase().toString()) !== -1;
                return status;
            })
        } else {
            filterRows = rows;
        }
        setState({
            ...state,
            searchTermName: searchTermName,
            searchTermNumber: searchTermNumber,
            filterRows: filterRows,
            currentPage: 0,
            itemOffset: 0,
            ...updatePaginationData(filterRows, 0)
        });
    }

    const handleSearchClick = (search = 0) => {
        handleSearch(search);
    }


    //------------------- Pagination code -------------------------//
    //-------------------
    useEffect(() => {
        setState({ ...state, ...updatePaginationData(state.filterRows, state.itemOffset || 0) })
    }, [state.itemOffset]);

    const updatePaginationData = (filterRows, offset) => {
        let items = [...filterRows];
        const endOffset = offset + itemsPerPage;
        return {
            currentItems: items.slice(offset, endOffset),
            pageCount: Math.ceil(items.length / itemsPerPage)
        };
    }

    const handlePageClick = (event) => {
        let items = [...state.filterRows];
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setState({ ...state, itemOffset: newOffset, currentPage: event.selected });
    };
    //------------------- Pagination code -------------------------//
    //-------------------
    return (
        <>

            <div className='py-4 position-sticky-pc px-0'>
                <h4 className='font-weight-bold px-0  bitter-italic-normal-medium-24'> {t(`Manage coefficients per PC`)} </h4>
            </div>
            <div className='searchbox m-0 pt-4 pb-3 position-sticky-config-link position-sticky-pc' style={{ margin: '10px 0' }}>
                <div className='row'>
                    <div className='col-md-12 col-xl-8 col-xxl-9 col-lg-7'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <input
                                    type="text"
                                    value={state.searchTerm}
                                    className="form-control mt-2 mb-2 input-border-lightgray poppins-regular-18px mh-50 rounded-0 shadow-none"
                                    onChange={(e) => setState({ ...state, searchTermNumber: e.target.value })}
                                    placeholder={t('Paritair comite number')}
                                    onKeyUp={(e) => e.key === 'Enter' ? handleSearchClick(1) : null}
                                />
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <input
                                    type="text"
                                    value={state.searchTerm}
                                    className="form-control mt-2 mb-2 input-border-lightgray poppins-regular-18px mh-50 rounded-0 shadow-none"
                                    onChange={(e) => setState({ ...state, searchTermName: e.target.value })}
                                    placeholder={t('Paritair comite name')}
                                    onKeyUp={(e) => e.key === 'Enter' ? handleSearchClick(1) : null}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-7 ms-auto col-xxl-3 col-xl-4 col-lg-5'>
                        <div className='row'>
                            <div className='col-md-6 col-lg-6 back-btn-mobile'>
                                <button
                                    type="button"
                                    className="btn  btn-block border-0 rounded-0 float-right mt-2 mb-2 skyblue-bg-color w-100 shadow-none text-uppercase"
                                    onClick={() => handleSearchClick(1)}
                                >
                                    {t('SEARCH')}
                                </button>
                            </div>
                            <div className='col-md-6 col-lg-6 back-btn-mobile'>
                                <button
                                    type="button"
                                    className="btn border-0 btn-block rounded-0 float-right mt-2 mb-2 reset_skyblue_button w-100 shadow-none text-uppercase px-0"
                                    onClick={() => handleSearchClick(0)}
                                >
                                    {t('RESET')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-render-parent-div manage_coefficient_table">
                <table className="table pwa table-hover manage-types-table  mb-3 ">
                    <thead className="table-render-thead">
                        <tr className='btn-bg-gray-medium table-sticky-bg-gray border-bottom-0' key={'header-row-tr'}>{headers.map((eachHeader, index) =>
                            <th className='poppins-medium-18px justify-content-center align-items-center btn-bg-gray-medium ps-lg-4' key={`tablecol${index}`} scope="col"> {t(eachHeader)} </th>)} </tr>
                    </thead>
                    {state.currentItems && state.currentItems.length > 0 ?
                        <tbody className='manage_coefficient_table_body'>
                            {state.currentItems.map(eachRow => <tr className='border poppins-regular-18px p-2 mb-4 box-shadow-sm' key={eachRow.id} id={eachRow.id}>
                                <td className="poppins-regular-16px ps-lg-4" data-label="PC number"> <div> {eachRow.pc_number} </div> </td>
                                <td className="poppins-regular-16px ps-lg-4" data-label="PC name"> <div> {eachRow.name} </div> </td>
                                <td className="poppins-regular-16px ps-4"> <div> {getNeededActions(eachRow)} </div> </td>
                            </tr>)}
                        </tbody>
                        : <p style={{ paddingTop: '10px' }}> {t('No records')} </p>}
                </table>
            </div>
            <div>
                {state.filterRows.length > itemsPerPage && <ReactPaginate
                    breakLabel="..."
                    nextLabel={<AiOutlineArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={state.pageCount}
                    forcePage={state.currentPage}
                    previousLabel={<AiOutlineArrowLeft />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    itemClass="page-item"
                    linkClass="page-link"
                    subContainerClassName={"pages pagination justify-content-center project-pagination"}
                    activeClassName={"active"}
                />}
                <div className="text-start col-md-6">
                    {/* <button onClick={() => navigate('/manage')} type="button" className="bg-white border-0 poppins-regular-18px float-sm-right mt-5 mb-1 ps-0 text-decoration-underline text-uppercase">
                        {t(`BACK`)}
                    </button> */}
                </div>
            </div>
        </>
    );
}

export default translate(TableRenderer);
