import React, { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import OverFlowWrapper from "../common/OverFlowWrapper";

interface OverlayTooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  placement?: "top" | "right" | "bottom" | "left";
}

const OverlayTooltip: React.FC<OverlayTooltipProps> = ({
  children,
  tooltipText,
  placement = "right",
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [type, setType] = useState<string | null>('Projects');
  const target = useRef<HTMLSpanElement | null>(null);

  const renderTooltipContent = () => {
    switch (type) {
      case "Projects":
        return (
          <>
            <div className="bg-white rounded-3 p-1 ps-3 mb-2">
              <div className="row">
                <div className="col-8 text-start text-break">
                  <span className="locationName">Projects</span>
                </div>
                <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">All</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <span
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add")}
                    onClick={() => {}}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </span>
                </div>
                <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">All</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "Cost center":
        return (
          <>
            <div className="bg-white rounded-3 p-1 ps-3 mb-2">
              <div className="row">
                <div className="col-8 text-start text-break">
                  <span className="locationName">Cost center</span>
                </div>
                <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">All</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <span
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add")}
                    onClick={() => {}}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </span>
                </div>
                <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">All</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <div className="text-danger">{t("No data available")}</div>;
    }
  };

  return (
    <>
      {showTooltip && (
        <div
          className="overlay-backdrop"
          onClick={() => setShowTooltip(false)}
        />
      )}
      <span
        onClick={() => setShowTooltip(!showTooltip)}
        ref={target}
        className="me-2 cursor-pointer"
      >
        {children}
      </span>

      <Overlay target={target.current} show={showTooltip} placement={placement}>
        {(props) => (
          <Tooltip id="overlay-tooltip" {...props}>
            <div className="d-flex align-items-center">
              <button
                onClick={() => setType("Projects")}
                className={`searchBtn me-2 ${
                  type === "Projects" ? "active" : ""
                }`}
              >
                {t("Projects")}
              </button>
              <button
                onClick={() => setType("Cost center")}
                className={`searchBtn me-2 ${
                  type === "Cost center" ? "active" : ""
                }`}
              >
                {t("Cost center")}
              </button>
              <span
                className="secondaryColorHoverEffect me-2"
                title={t("Edit")}
                onClick={() => {}}
              >
                <Icon isIcon={true} name="EditIcon" width="1vw" height="1vw" />
              </span>
              <span
                className="secondaryColorHoverEffect me-2"
                title={t("Add")}
                onClick={() => {}}
              >
                <Icon isIcon={true} name="add" width="1vw" height="1vw" />
              </span>
              <span
                className="secondaryColorHoverEffect me-2"
                title={t("Settings")}
                onClick={() => {}}
              >
                <Icon isIcon={true} name="Settings" width="1vw" height="1vw" />
              </span>
            </div>
            <OverFlowWrapper className="employeeSchedulingTooltip">
              <div className="mt-2">{renderTooltipContent()}</div>
            </OverFlowWrapper>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default OverlayTooltip;
