import React, { useState } from "react";

const ToggleButton = () => {
  var ToggleButton = require("react-toggle-button");
  const [isActive, setIsActive] = useState(false);
  return (
    <ToggleButton
      value={isActive}
      onToggle={() => {}}
      inactiveLabel=""
      colors={{
        active: {
          base: "#8571b9",
        },
        inactive: {
          base: "#898989",
        },
        activeThumb: {
          base: "#fff",
        },
        inactiveThumb: {
          base: "#fff",
        },
      }}
      inactiveThumbStyle={{
        boxShadow: "none",
      }}
    />
  );
};

export default ToggleButton;
