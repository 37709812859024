import React from "react";

const RightPart = () => {
  return (
    <table className="table">
      <thead>
        <tr className="TableHeader" style={{height:"4.575vw"}}>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default RightPart;
