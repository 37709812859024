import { t, translate } from "../../CentralDataMangement/translation/Translation";
import CardCircle from "../../common/atoms/CardCircle";
import CardTitle from "../../common/atoms/CardTitle";
import DashboardCard from "../../common/atoms/DashboardCard";
import { Link } from "react-router-dom";
import tiles from "./configurationtiles.json";
import React from "react"; // Add this line if it's missing
import Header from "../../common/layout/Header";



const ConfigurationDashboard = () => {
    return (
        <>
            <Header />
            <div className="row equal-cols adminDashboard pt-1">
                {tiles &&
                    tiles.map((item: any, index: number) => (
                        <div className="col-4 mb-4" key={index}>
                            <Link key={item.id} to={item.path} className="text-decoration-none">
                                <DashboardCard className="card rounded-3 p-4 border-0 card-shadow justify-content-center h-100">
                                    <div className="row">
                                        <div className="col-12">
                                            <CardTitle
                                                title={t(item.title)}
                                                className="cardTitle"
                                            />
                                        </div>
                                    </div>
                                </DashboardCard>
                            </Link>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default translate(ConfigurationDashboard);