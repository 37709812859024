import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import Icon from "../../../Icon";
// import profileImage from "../../../static/images/DummyImage.jpg";
import { icons } from "../../Dashboard/WelcomeDashboard";
// import { t } from "../atoms/Constants";
// import React, { useEffect, useState } from "react";e
import Icon from "../../../Icon";
// import LabelField from "./LabelField";
import DateAndTime from "../atoms/DateWithTime";
import LabelField from "../atoms/LabelField";
import Link from "../atoms/Link";
import IconWithTitle from "../molecules/IconWithTitle";
import Image from "../atoms/Image";
import profileImage from "../../../static/images/DummyImage.jpg";
import { Nav } from "react-bootstrap";
// import Translate
import secureLocalStorage from "react-secure-storage";
import {
  t,
  setLanguage,
  getLanguage,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  ABSOLUTE_CORE_APP,
} from "../../../Constants";
import { GET_APP_LANG } from "../../../routes/ApiEndpoints";
import AddCompanyModal from "../../Dashboard/AddCompany";
import * as ROUTE from "../../../routes/RouteConstants";
type OptionType = { label: string; value: string };

interface HeaderProps {
  headerName?: string;
  headerClassName?: string;
}

const Header: React.FC<HeaderProps> = ({ headerName, headerClassName }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [formShow, setFormShow] = useState(false);
  const [pcValue, setPcValue] = useState<OptionType[] | null>(null);
  const navigator = useNavigate();

  const handlePcChange = (selectedOption: OptionType[] | null) => {
    setPcValue(selectedOption);
  };

  const handleToggle = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  };

  const handleItemClick = (itemName: string) => {
    if (itemName == 'addCompany') {
      setFormShow(true)
    } else {
      navigator('/location-dashboard');
    }
  };
  const handleCloseModal = () => setFormShow(false);

  const profileDropdownIconProps = {
    isIcon: true,
    isTitle: true,
    borderRadius: "50%",
    width: "2vw",
    height: "2vw",
    iconImageWrapperClassName: "text-center d-flex align-items-center",
    iconBackgroundColor: "#EEECF3",
    className: "profileDropdownMenuIcon",
    fillColor: "#8571b9",
    labelClassName: "colorPrimary companyName cursor-pointer",
    titleImageWrapperClassName: "text-center ms-2",
  };

  const [languages, setLanguages] = useState<
    Array<{ id: number; key: string; name: string }>
  >([]);
  const [activeLanguage, setActiveLanguage] = useState(getLanguage());
  // console.log("Dropdown debug","Header is called "+ activeLanguage + " language is set ")

  useEffect(() => {
    setActiveLanguage(getLanguage());
  }, [getLanguage()]); // Ensure it runs after the language is set

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    const response = await ApiCall.getService(
      `${GET_APP_LANG}/${ABSOLUTE_CORE_APP}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguages(response.app_langs);
    }
  };

  const handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLang = e.target.value;
    setLanguage(selectedLang); // Call the language change callback
    setActiveLanguage(selectedLang); //it will not work because of re-render
    secureLocalStorage.setItem("site_lang", selectedLang);
  };

  return (
    <>
      {isDropdownOpen && <div className="overlayEffect" />}
      <div
        className={`pb-4 ${isDropdownOpen ? "profileDropdownOpen" : "headerWrapper"
          }`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Link href="/">
              <Icon name="Logo" width="15vw" height="3vw" isIcon={true} />
            </Link>
            <sub className="dateTimePosition">
              <DateAndTime
                dataTimeWrapperClassName="d-inline-block ms-4"
                dataTimeClassName="colorSkyBlue poppins-medium headerDateTime"
                isDate={true}
                isTime={true}
                isPipe={true}
                showYear={true}
                showMonth={true}
                showDate={true}
              />
            </sub>
          </div>
          <div className="pe-5 me-5">
            <LabelField
              title={headerName}
              className={`colorPrimary poppins-semibold pe-5 me-5 ${headerClassName}`}
            />
          </div>
          <div className="d-inline-flex align-items-center py-2">
            <Nav>
              <div className=" d-flex align-items-center mb-3 mb-lg-0 me-lg-3">
                <div className="d-block d-lg-none me-4">
                  {/* <Translate /> */}
                </div>
                <select
                  id="lang-select"
                  className="border rounded-3 cursor-pointer bg-transparent p-2 lang-options "
                  onChange={handleLangChange}
                  value={activeLanguage}
                >
                  {languages &&
                    languages.map((language) => (
                      <option key={language.id} value={language.key}>
                        {language.key}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="pe-0 position-relative mb-3 mb-lg-0 me-lg-3">
            <div className="d-flex align-items-center notification-section h-100">
              <Notification />
              <div className="d-block d-lg-none ms-4 color-dark-pink">
                {t("Notification")}
              </div>
            </div>
          </div> */}
            </Nav>
            <LabelField
              title={t("Welcome THE BREAD")}
              className="colorPrimary poppins-semibold"
            />
            <Icon
              name="notification"
              className="mx-3"
              fillColor="#EDE681"
              width="1vw"
              height="1vw"
              isIcon={true}
            />
            <Dropdown onToggle={handleToggle}>
              <Dropdown.Toggle
                className="bg-transparent border-0 p-0"
                id="dropdown-basic"
              >
                <div className="d-inline-flex imageBorder align-items-center justify-content-center">
                  <Image
                    src={profileImage}
                    imageHeight="2.5vw"
                    imageWidth="2.5vw"
                    imageBorderRadius="50%"
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="profile-dropdown-links">
                <div className="contentBg p-0">
                  {icons.map((icon, index) => (
                    <Dropdown.Item
                      key={index}
                      className={activeItem === "profile" ? "active" : ""}
                      onClick={() => navigator(ROUTE.LOCATION_DASHBOARD)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="d-flex imageBorder align-items-center justify-content-center">
                          <Image
                            src={icon.src}
                            imageHeight="80.5%"
                            imageWidth="84%"
                            imageBorderRadius="50%"
                          />
                        </div>
                        <LabelField
                          title={icon.title}
                          className="ms-2 colorPrimary poppins-medium mb-0"
                        />
                      </div>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item
                    className={activeItem === "addCompany" ? "active" : ""}
                    onClick={() => handleItemClick("addCompany")}
                  >
                    <IconWithTitle
                      isIcon={true}
                      isTitle={true}
                      name="add"
                      borderRadius="50%"
                      width="2vw"
                      height="2vw"
                      iconImageWrapperClassName="text-center d-flex align-items-center"
                      iconBackgroundColor="#898989"
                      className="p-2"
                      fillColor="#fff"
                      labelClassName="colorPrimary companyName cursor-pointer"
                      title={t("Add company")}
                      titleImageWrapperClassName="text-center ms-2"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "viewCompanies" ? "active" : ""}
                    onClick={() => navigator(ROUTE.WELCOME_DSHBOARD)}
                    style={{
                      height: "2.5vw",
                      borderRadius: "1vw",
                      border: "0.052vw solid #fff",
                      padding: "0",
                    }}
                  >
                    <div className="d-flex align-items-center mx-3 viewCompaniesBg">
                      <IconWithTitle
                        isIcon={true}
                        isTitle={true}
                        name="arrowLeftRight"
                        width="2vw"
                        height="2vw"
                        iconImageWrapperClassName="text-center d-flex align-items-center"
                        className="p-2"
                        fillColor="#8571b9"
                        labelClassName="colorPrimary companyName cursor-pointer"
                        title={t("View all companies")}
                        titleImageWrapperClassName="text-center ms-2"
                      />
                    </div>
                  </Dropdown.Item>
                </div>
                <div className="contentBg p-0 mt-4">
                  <Dropdown.Item
                    className={activeItem === "myAccount" ? "active" : ""}
                    onClick={() => navigator(ROUTE.MY_ACCOUNT)}
                  >
                    <IconWithTitle
                      name="user"
                      title={t("My account")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "myDocuments" ? "active" : ""}
                    onClick={() => navigator(ROUTE.DOCUMENTS_OVERVIEW)}
                  >
                    <IconWithTitle
                      name="documents"
                      title={t("My documents")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "managePool" ? "active" : ""}
                    onClick={() => navigator(ROUTE.EMPLOYEE_OVERVIEW)}
                  >
                    <IconWithTitle
                      name="employeeGroup"
                      title={t("Managing your pool")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={activeItem === "signOut" ? "active" : ""}
                    onClick={() => navigator(ROUTE.LOGIN)}
                  >
                    <IconWithTitle
                      name="logoutIcon"
                      title={t("Sign out")}
                      {...profileDropdownIconProps}
                    />
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <><AddCompanyModal
        show={formShow}
        handleClose={handleCloseModal}
        pcValue={pcValue}
        handlePcChange={handlePcChange} />
      </>
    </>
  );
};

export default translate(Header);
