import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ApiCall } from '../../../../services/ApiServices';
import { t } from '../../../CentralDataMangement/translation/Translation';
import { ADD_COEFFICIENT_TYPES, EDIT_COEFFICIENT_TYPES } from '../../../../routes/ApiEndpoints';

const OperationCoefficientTypes = ({ rows }) => {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract id from query parameters
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    // Determine if the operation is an edit or an add based on the URL
    const isEditing = location.pathname.includes('edit');

    const initialRow = rows.find((row) => row.id == id) || {};
    const [state, setState] = useState({
        name: isEditing ? initialRow.name : '',
        rows,
        editFlow: isEditing,
        editUrl: EDIT_COEFFICIENT_TYPES,
        createUrl: ADD_COEFFICIENT_TYPES,
        newItems: isEditing ? [initialRow] : [],
        nameWarning: false,
        typeName: t('coefficient'),
        uniqueError: false,
        duplicates: [],
    });

    const handleInputChange = (event) => {
        setState((prevState) => ({
            ...prevState,
            name: event.target.value,
            nameWarning: false,
            uniqueError: false,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { name, createUrl, editUrl, newItems, editFlow } = state;

        if (!name.trim()) {
            setState((prevState) => ({ ...prevState, nameWarning: true }));
            inputRef.current.focus();
            return;
        }

        try {
            const apiUrl = editFlow ? `${editUrl}/${newItems[0]?.id}` : createUrl;
            const method = editFlow ? "PUT" : "POST"
            const result = await ApiCall.service(apiUrl, method, { name: name });
            if (result.success) {
                // navigate('/manage/coefficient-types');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    return (
        <div className='container p-0'>
            <div className='col-md-12 text-end'>
                <button
                    type='button'
                    className="btn btn-block mt-2 mb-2 border-0 rounded-0 ms-2 skyblue-bg-color py-2 px-3 shadow-none text-uppercase"
                    onClick={() => navigate('/manage/coefficient-types')}
                >
                    {t('Back')}
                </button>
            </div>
            <div className='row'>
                {/* Heading to indicate whether adding or editing */}
                <h2 className='col-md-12'>
                    {state.editFlow ? t('Edit Coefficient Type') : t('Add Coefficient Type')}
                </h2>
                <form onSubmit={handleSubmit} className='needs-validation' noValidate>
                    <div className='col-md-12'>
                        <label htmlFor='name'>{t('Name')}</label>
                        <input
                            ref={inputRef}
                            type='text'
                            value={state.name}
                            className={`form-control ${state.nameWarning ? 'border-danger' : ''}`}
                            onChange={handleInputChange}
                            placeholder={t('Coefficient Name')}
                        />
                        {state.nameWarning && (
                            <small className='text-danger'>{t('Please provide the name')}</small>
                        )}
                    </div>
                    <div className='col-md-12 text-end mt-3'>
                        <button type='submit' className='btn btn-primary'>
                            {state.editFlow ? t('Update') : t('Add')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OperationCoefficientTypes;
