import React, {
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react";

interface CheckBoxFieldProps {
  name: string;
  label?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  isChecked?: boolean;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  isClicked?: MouseEventHandler<HTMLInputElement>;
  disable?: boolean;
  value?: string | number;
  lineHeight?: string | number;
  checkBoxWrapper?: string;
  wrapperClass?: string;
  children?: ReactNode;
  isChildren?: boolean;
  pcViewType?: string; // Added to handle view type
  fontSize?:string;
}

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  name,
  label,
  customStyle = {},
  className,
  id = "",
  value,
  isChecked = false,
  onChangeHandler,
  isClicked,
  disable = false,
  lineHeight,
  checkBoxWrapper = "",
  wrapperClass = "d-inline-block",
  children,
  isChildren = false,
  pcViewType, // Added to handle view type
  fontSize,
}) => {
  const [checked, setChecked] = useState(isChecked);

  // Sync internal state with isChecked prop
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setChecked(newChecked);

    if (onChangeHandler) {
      onChangeHandler(e);
    }
  };

  // Determine CSS classes based on view type
  const checkBoxClass =
    pcViewType === "addpc"
      ? "form-check-input rounded-0"
      : "form-check-input rounded-0";

  return (
    <div className={`${checkBoxWrapper} form-check ${wrapperClass}`}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        id={id}
        value={value}
        disabled={disable}
        onChange={handleToggle}
        onClick={isClicked}
        className={`${checkBoxClass} shadow-none cursor-pointer ${className}`}
      />

      {isChildren && (
        <div onClick={handleToggle as any} className="clickable-container cursor-pointer">
          {children}
        </div>
      )}
      <label
        style={{ ...customStyle, lineHeight, fontSize : "0.9vw" }}
        className={`${disable ? "" : "cursor-pointer"}`}
        htmlFor={id}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setChecked(!checked);
        }}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBoxField;
