import React from "react";
import LabelField from "../common/atoms/LabelField";
// import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import Address from "../common/molecules/Address";
import DocumentsUpload from "../common/molecules/DocumentsUpload";
import EmployeeCommunication from "./EmployeeCommunication";
import EmployeeOthers from "./EmployeeOthers";
import EmployeeProfile from "./EmployeeProfile";
import { useLocation } from 'react-router-dom';
import { t , translate} from "../CentralDataMangement/translation/Translation";



const Registration = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const classname = searchParams.get('type') == 'login' ? 'mx-5' : "";
  const id = searchParams.get('id');
  const pageTitle = (id!= null) ? 'Profile' : "Employee registration";
  return (
    <div className={classname}>
      <Header />
      <div className="contentBg mb-4">
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <LabelField
                title={t(pageTitle) + ":"}
                customStyle={{ fontSize: "1.2vw" }}
                className="pageTitle"
              />
            </div>
            <div className="mb-3">
              <LabelField
                title={t("Profile")}
                className="tab-title"
              />
            </div>
          </div>
        </div>
        <EmployeeProfile />
        <EmployeeCommunication />
        <Address />
        <EmployeeOthers />
        <div className="row pb-3">
          <div className="col-4">
            <DocumentsUpload documentTitle={t("Front ID") + ":"} />
          </div>
          <div className="col-4">
            <DocumentsUpload documentTitle={t("Back ID") + ":"} />
          </div>
          <div className="col-4">
            <DocumentsUpload documentTitle={t("Residence title") + ":"} infoIconWrapper={true} helpText={t("Help text")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(Registration);
