import React, { useEffect, useState, useCallback, useMemo } from "react";
import Icon from "../../Icon";
import DateAndTime from "../common/atoms/DateWithTime";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import InputTextfield from "../common/atoms/InputTextField";
import OverlayTooltip from "./TypeBasedPopup";
import { useDispatch, useSelector } from "react-redux";
import { selectLocationId, selectTab, updateEmployeeSchedule } from "../../features/planning/planningSlice";

interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center?: number;
  shifts: Shifts[];
}

interface ScheduleMap {
  [date: string]: Schedule;
}

interface Props {
  employee: number;
  planningSchedule: ScheduleMap;
}

const PlanningShiftsTimings: React.FC<Props> = ({ employee, planningSchedule }) => {
  const defaultShift = useMemo(() => ({ start_time: "", end_time: "", sequence: 2 }), []);
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState<ScheduleMap>(planningSchedule || {});
  const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({});
  const locationId = useSelector(selectLocationId);
  const tab = useSelector(selectTab);

  useEffect(() => {
    setSchedule(planningSchedule || {});
  }, [planningSchedule]);

  const formatTimeInput = (input: string): string => {
    const sanitizedInput = input.replace(/[^0-9]/g, "");
    if (sanitizedInput.length === 4) {
      const hours = sanitizedInput.slice(0, 2);
      const minutes = sanitizedInput.slice(2);
      return `${hours}:${minutes}`;
    }
    return input;
  };

  const isValidTime = (time: string): boolean =>
    /^([01]\d|2[0-3]):([0-5]\d)$/.test(time) && time.length === 5;

  const validateShift = (shift: Shifts): boolean => {
    const { start_time, end_time } = shift;
    if (!isValidTime(start_time) || !isValidTime(end_time)) return false;
    if (start_time === "" || end_time === "") return true;
    const [startHours, startMinutes] = start_time.split(":").map(Number);
    const [endHours, endMinutes] = end_time.split(":").map(Number);
    return (
      startHours < endHours ||
      (startHours === endHours && startMinutes < endMinutes)
    );
  };

  const updateSchedule = (date: string, newSchedule: Schedule) => {
    const updatedSchedule: ScheduleMap = { ...schedule, [date]: newSchedule };
    setSchedule(updatedSchedule);

    if (employee) {
      dispatch(
        updateEmployeeSchedule({
          employee_id: employee,
          scheduleMap: updatedSchedule,
        })
      );
    }
  };

  const handleShiftChange = (date: string, index: number, key: keyof Shifts, value: string) => {
    const formattedValue = formatTimeInput(value);
    setSchedule((prevSchedule) => {
      const newSchedule = prevSchedule[date].shifts.map((shift, i) => {
        if (i === index) {
          const updatedShift = { ...shift, [key]: formattedValue };
          const isValid = validateShift(updatedShift);
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [`${date}-${index}`]: !isValid,
          }));
          return updatedShift;
        }
        return shift;
      });
      updateSchedule(date, { ...prevSchedule[date], shifts: newSchedule });
      return prevSchedule;
    });
  };

  const handleAddShift = (date: string) => {
    setSchedule((prevSchedule) => {
      const currentShifts = prevSchedule[date]?.shifts || [];
      const lastShiftLocationId = prevSchedule[date]?.location_id || locationId;

      const newShift: Shifts = {
        start_time: "",
        end_time: "",
        sequence: currentShifts.length + 1,
      };

      const newSchedule: Schedule = {
        shifts: [...currentShifts, newShift],
        location_id: lastShiftLocationId,
      };

      if (newSchedule.shifts.length <= 2) {
        updateSchedule(date, newSchedule);
      }

      return { ...prevSchedule, [date]: newSchedule };
    });
  };

  const handleRemoveShift = (date: string, index: number) => {
    setSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      if (newSchedule[date]) {
        newSchedule[date].shifts = newSchedule[date].shifts.filter((_, i) => i !== index);
        if (newSchedule[date].shifts.length === 0) delete newSchedule[date];
      }

      if (employee) {
        dispatch(
          updateEmployeeSchedule({
            employee_id: employee,
            scheduleMap: newSchedule,
          })
        );
      }

      return newSchedule;
    });
  };

  const calculateTotalTime = (shifts: Shifts[]): string => {
    let totalMinutes = 0;
    shifts.forEach((shift) => {
      const [startHours, startMinutes] = shift.start_time
        .split(":")
        .map(Number);
      const [endHours, endMinutes] = shift.end_time.split(":").map(Number);
      if (
        !isNaN(startHours) &&
        !isNaN(startMinutes) &&
        !isNaN(endHours) &&
        !isNaN(endMinutes)
      ) {
        const startTime = startHours * 60 + startMinutes;
        const endTime = endHours * 60 + endMinutes;
        if (endTime > startTime) totalMinutes += endTime - startTime;
      }
    });
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const shiftCells = (daySchedule: Shifts[], date: string) => (
    <>
      {daySchedule.map((shift, index) => (
        <td key={index} className="bodyBg align-middle">
          <div className="col-12 d-flex align-items-center">
            <InputTextfield
              value={shift.start_time}
              className={`form-control me-2 ${validationErrors[`${date}-${index}`] ? "error-border" : ""}`}
              handleChange={(e) => handleShiftChange(date, index, "start_time", e.target.value)}
              isDisabled={tab === "tab_3"}
            />
            {" - "}
            <InputTextfield
              value={shift.end_time}
              className={`form-control ms-2 ${validationErrors[`${date}-${index}`] ? "error-border" : ""}`}
              handleChange={(e) => handleShiftChange(date, index, "end_time", e.target.value)}
              isDisabled={tab === "tab_3"}
            />
          </div>
        </td>
      ))}
      {daySchedule.length < 2 && (
        <td className="bodyBg align-middle">
          <span
            data-bs-toggle="tooltip"
            title={t("Add")}
            onClick={() => handleAddShift(date)}
          >
            <Icon
              name="add"
              width="0.8vw"
              height="0.8vw"
              fillColor="#8571b9"
              isIcon={true}
            />
          </span>
        </td>
      )}
      <td className="bodyBg align-middle">
        <div className="totalHours text-white poppins-medium">
          {calculateTotalTime(daySchedule)}
        </div>
      </td>
    </>
  );

  return (
    <table className="shiftTimingTable table table-borderless mb-0">
      <thead>
        <tr>
          <th></th>
          <th>{t("Shift 1")}</th>
          <th>{t("Shift 2")}</th>
          <th className="text-start">{t("Total")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(schedule).map(([day, daySchedule]) => (
          <React.Fragment key={day}>
            <tr className="spaced-row">
              <td className="p-0">
                <DateAndTime
                  isDay={true}
                  isDate={true}
                  showDate={true}
                  dataTimeClassName="d-flex flex-column align-items-center shiftDate"
                  monthFormat="short"
                  value={day}
                />
              </td>
              {shiftCells(daySchedule.shifts, day)}
              <td className="actionWrapper bodyBg align-middle">
                <div className="d-flex">
                  {tab === "tab_3" ? (
                    <>
                      <span data-bs-toggle="tooltip" title={t("Edit")}>
                        <Icon
                          name="EditIcon"
                          width="0.8vw"
                          height="0.8vw"
                          className="me-2 cursor-pointer"
                          isIcon={true}
                        />
                      </span>
                      <span
                        data-bs-toggle="tooltip"
                        title={t("Remove shift")}
                        onClick={() =>
                          handleRemoveShift(day, daySchedule.shifts.length - 1)
                        }
                        className="me-2"
                      >
                        <Icon
                          name="RemoveIcon"
                          width="0.8vw"
                          height="0.8vw"
                          className="cursor-pointer"
                          isIcon={true}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <OverlayTooltip tooltipText="Location and Projects">
                        <Icon
                          name="locationIcon"
                          width="0.8vw"
                          height="0.8vw"
                          className="cursor-pointer"
                          isIcon={true}
                        />
                      </OverlayTooltip>

                      <OverlayTooltip tooltipText="Clone">
                        <Icon
                          name="CloneIcon"
                          width="0.8vw"
                          height="0.8vw"
                          className="cursor-pointer"
                          isIcon={true}
                        />
                      </OverlayTooltip>

                      <OverlayTooltip tooltipText="Shifts">
                        <Icon
                          name="shiftsIcon"
                          width="0.8vw"
                          height="0.8vw"
                          className="cursor-pointer"
                          isIcon={true}
                        />
                      </OverlayTooltip>

                      <OverlayTooltip tooltipText="Remove shift">
                        <Icon
                          name="RemoveIcon"
                          width="0.8vw"
                          height="0.8vw"
                          className="cursor-pointer"
                          isIcon={true}
                        />
                      </OverlayTooltip>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td
                colSpan={5}
                style={{ height: "0.7vw" }}
                className="emptyRow p-0"
              ></td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default PlanningShiftsTimings;
