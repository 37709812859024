import React, { useState, useEffect } from 'react';
import LabelField from './LabelField';
import InputField from './InputTexfield';
import MultiSelectField from './MultiSelectField';
import ValidateMessage from './validationError';
import { ApiCall } from "../../../../services/ApiServices";
import { FETCH_COEFFICIENT_DEVIATION, UPDATE_COEFFICIENT_DEVIATION } from '../../../../routes/ApiEndpoints';

const EditCoefficientDeviation = ({ action, entityId = 0, entityType = 0, editId, coefficientDetails = {} }) => {
    const assignInitialValues = (coefficientDetails) => {
        return {
            name: coefficientDetails.perfcode_name || '',
            code: coefficientDetails.perfcode_code || '',
            employeetypeId: coefficientDetails.employeetype_id || 0,
            coefficient: coefficientDetails.coefficient || '',
            perfcodeId: coefficientDetails.perfcode_id || 0,
            id: coefficientDetails.id || 0,
            coefficientTypes: [
                { label: 'Refer coefficient value', value: 0 },
                { label: 'Default value', value: 1 }
            ],
            coefficientTypesDefault: coefficientDetails.employeetype_id === 0 ? 1 : 0,
            allCoefficientTypes: coefficientDetails.allCoefficientTypes || []
        }
    }

    const [state, setState] = useState({
        ...assignInitialValues(coefficientDetails),
        nameWarning: false,
        codeWarning: false,
        coefficientTypesWarning: false,
        allCoefficientTypesWarning: false,
        coefficientWarning: false,
        loading: false,
        action: action
    });

    useEffect(() => {
        fetchData();
    }, [action, Object.keys(coefficientDetails).length]);

    const fetchData = async () => {
        if (state.action == 1) {
            // alert();
            await ApiCall.service(
                FETCH_COEFFICIENT_DEVIATION,
                "POST",
                { "id": editId, "edit": 0 },
                false,
                "central-data-management",
            ).then((response) => {
                if (response.success) {
                    const coefficientDetails = response.data[0];
                    console.log(coefficientDetails);

                    const initialValues = {
                        name: coefficientDetails.perfcode_name || '',
                        code: coefficientDetails.perfcode_code || '',
                        employeetypeId: coefficientDetails.employeetype_id || 0,
                        coefficient: coefficientDetails.coefficient || '',
                        perfcodeId: coefficientDetails.perfcode_id || 0,
                        id: coefficientDetails.id || 0,
                        coefficientTypes: [
                            { label: 'Refer coefficient value', value: 0 },
                            { label: 'Default value', value: 1 }
                        ],
                        coefficientTypesDefault: coefficientDetails.employeetype_id === 0 ? 1 : 0,
                        allCoefficientTypes: coefficientDetails.allCoefficientTypes || []
                    };

                    setState((prevState) => ({
                        ...prevState,
                        ...initialValues,
                        loaded: true
                    }));
                }
            })
                .catch((error) => {
                    console.error('Error occurred', error);

                });

        }
    };

    const handleSave = async (draft = 0) => {
        let proceed = true;
        let url = editId ? `${UPDATE_COEFFICIENT_DEVIATION}` : `${UPDATE_COEFFICIENT_DEVIATION}`;
        if (!draft) {
            proceed = checkRquiredField();
        }
        if (proceed) {
            try {
                const response = await ApiCall.service(url, 'POST', getPostData(draft));
                console.log(getPostData(draft))
                if (response.success) {
                    // ('/manage/coefficient-deviation?entitytype=1')
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error occurred', error);
            }
        }
    };

    const getPostData = (draft) => {
        const { name, code, employeetypeId, coefficient, perfcodeId, id, coefficientTypes, coefficientTypesDefault } = state;
        return {
            name, code, employeetypeId, coefficient, perfcodeId, id, coefficientTypes, coefficientTypesDefault
        };
    };

    const handleChange = (e, type) => {
        let setObj = { ...state };
        const { value, checked, name } = e.target;
        if (type === 1) {
            setObj[name] = value;
            setObj[`${name}Warning`] = false;
        } else {
            setObj['linkToCooperationAgreement'] = checked ? 1 : 0;
        }
        setState(setObj);
    };

    const checkRquiredField = () => {
        const { name } = state;
        let proceed = true;
        let setObj = {};
        if (!name.length) {
            setObj['codeWarning'] = true;
            proceed = false;
        }
        setState({ ...state, ...setObj });
        return proceed;
    };

    const onSelect = (e, type) => {
        if (type === 1) {
            const employeetypeIdNew = (e.value === 0) ? state.employeetypeId : 0;
            setState({ ...state, coefficientTypesDefault: e.value, employeetypeId: employeetypeIdNew });
        } else if (type === 2) {
            setState({ ...state, employeetypeId: e.value });
        }
    };

    return (
        <div>
            {state.loaded ?
                <>
                    <div className='add-edit-types border-purple p-3 mb-5'>
                        <div className="">
                            <div className='row'>
                                <div className='col-lg-6 col-md-10 pe-0 pb-3'>
                                    <div>
                                        <LabelField title={`Code`} className='poppins-medium-18px' mandotory={false} />
                                        <InputField
                                            type={'text'}
                                            className={'col-md-12 my-1 shadow-none rounded-0'}
                                            value={state.code}
                                            isDisabled={true}
                                            placeholder={'Enter code'}
                                            handleChange={(e) => handleChange(e, 1)}
                                            name={'code'}
                                        />
                                        {state.codeWarning && <ValidateMessage style={{ margin: 0 }} text={'This field is required.'} />}
                                    </div>
                                    <div>
                                        <LabelField title={`Name`} className='poppins-medium-18px' mandotory={false} />
                                        <InputField
                                            type={'text'}
                                            className={'col-md-12 my-1 shadow-none rounded-0'}
                                            value={state.name}
                                            isDisabled={true}
                                            placeholder={'Enter name'}
                                            handleChange={(e) => handleChange(e, 1)}
                                            name={'name'}
                                        />
                                        {state.nameWarning && <ValidateMessage style={{ margin: 0 }} text={'This field is required.'} />}
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12">
                                    <div className='row'>
                                        <div className="col-md-12 pb-3">
                                            <LabelField title={`Select coefficient value type`} mandotory={false} className='poppins-medium-18px' />
                                            <MultiSelectField
                                                options={state.coefficientTypes}
                                                standards={state.coefficientTypes.filter(val => val.value === state.coefficientTypesDefault)}
                                                handleChange={(e) => onSelect(e, 1)}
                                                isMulti={false}
                                                className="col-lg-6 col-md-10 rounded-0 shadow-none employer_select"
                                                placeholder={'Select coefficient value type'}
                                            />
                                            {state.coefficientTypesWarning && <ValidateMessage style={{ margin: 0 }} text={'This field is required.'} />}
                                        </div>
                                        {state.coefficientTypesDefault === 0 ? (
                                            <div className="col-md-12 pb-3">
                                                <LabelField title={`Select reference coefficient`} mandotory={false} className='poppins-medium-18px' />
                                                <MultiSelectField
                                                    options={state.allCoefficientTypes}
                                                    standards={state.allCoefficientTypes.filter(val => val.value === state.employeetypeId)}
                                                    handleChange={(e) => onSelect(e, 2)}
                                                    isMulti={false}
                                                    className="col-lg-6 col-md-10 rounded-0 shadow-none employer_select"
                                                    placeholder={'Select reference coefficient'}
                                                />
                                                {state.allCoefficientTypesWarning && <ValidateMessage style={{ margin: 0 }} text={'This field is required.'} />}
                                            </div>
                                        ) : (
                                            <div>
                                                <LabelField title={`Coefficient value`} className='poppins-medium-18px' mandotory={true} />
                                                <InputField
                                                    type={'text'}
                                                    className={'col-lg-6 col-md-10 my-1 shadow-none rounded-0'}
                                                    value={state.coefficient}
                                                    isDisabled={false}
                                                    placeholder={'Enter coefficient value'}
                                                    handleChange={(e) => handleChange(e, 1)}
                                                    name={'coefficient'}
                                                />
                                                {state.coefficientWarning && <ValidateMessage style={{ margin: 0 }} text={'This field is required.'} />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-6 p-0'>
                            <button className="btn btn-success px-4" onClick={() => handleSave(0)}>
                                Save
                            </button>
                        </div>
                    </div>
                </>
                : null}
        </div>
    );
};

export default EditCoefficientDeviation;
