import React from "react";
import LabelField from "../common/atoms/LabelField";
// import { t } from "../common/atoms/translation";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import { t , translate } from "../CentralDataMangement/translation/Translation";


const EmployeeCommunication = () => {
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <LabelField
        title={t("Communication")}
        className="tab-title" />
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("E-mail address") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="email"
            value={""}
            name=""
            isMandatory={true}
            id="emailAddress"
            labelWrapperClassName="col-4 align-self-center"
          />
        </div>
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Repeat e-mail address") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="email"
            value={""}
            name=""
            isMandatory={true}
            id="reemailAddress"
            labelWrapperClassName="col-4 pe-0 align-self-center"
          />
        </div>
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Phone number") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="tel"
            value={""}
            name=""
            isMandatory={true}
            id="phoneNo"
            labelWrapperClassName="col-4 align-self-center"
          />
        </div>
      </div>
      <div className="col-12 mb-3">
        <LabelField
        title={t("Emergency contact")}
        className="tab-title"/>
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Name of contact person") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="text"
            value={""}
            name=""
            id="name"
            labelWrapperClassName="col-4 align-self-center"
          />
        </div>
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Phone number") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="tel"
            value={""}
            name=""
            id="phoneNo"
            labelWrapperClassName="col-4 align-self-center"
          />
        </div>
      </div>
    </div>
  );
};

export default translate(EmployeeCommunication);
