import React, { useState, useCallback } from "react";
import Calendar from "../common/atoms/Calendar";
import { useDispatch } from "react-redux";
import { formatDate } from "../common/utlis/HelperFunctions";
import { selectContractType, selectEmployeeScheduleKeysById, selectLocationId, updateEmployeeSchedule } from "../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Shifts {
    start_time: string;
    end_time: string;
    sequence: number;
}
interface Schedule {
    location_id: number;
    project_id?: number;
    cost_center?: number;
    shifts: Shifts[];
}
interface ScheduleMap {
    [date: string]: Schedule;
}
interface Props {
    employee: number;
    planningSchedule: ScheduleMap | undefined;
}
const MonthlySchedule: React.FC<Props> = ({ employee, planningSchedule }) => {
    const [selectedDates, setSelectedDates] = useState<string[]>([]);
    const monthView = useSelector(selectContractType);
    const locationId = useSelector(selectLocationId);
    const [schedule, setSchedule] = useState<ScheduleMap>(planningSchedule || {});
    // Get the scheduled dates for the specified employee from the Redux store
    const scheduledDates = useSelector((state: RootState) =>
        selectEmployeeScheduleKeysById(state, employee)
    );


    const dispatch = useDispatch();

    const defaultShift = { location_id: locationId, shifts:[{ start_time: "", end_time: "", sequence: 1 }]};


    const handleDateChange = (dates: string[]) => {
        updateScheduleWithNewDates(dates);
    };
    const updateScheduleWithNewDates = (dates: string[]) => {
        setSchedule(prevSchedule => {
            const updatedSchedule = { ...prevSchedule };
            dates.forEach(date => {
                const dateString = date; // Format as YYYY-MM-DD
                if (!updatedSchedule[dateString]) {
                    updatedSchedule[dateString] = defaultShift;
                }
            });
            dispatch(updateEmployeeSchedule({ employee_id: employee, scheduleMap: updatedSchedule }))
            return updatedSchedule;
        });
        // dispatch(updateEmployeeSchedule({ employee_id: employee, scheduleMap: schedule }))
        setSelectedDates(dates);


    };


    return (
        <>
            <Calendar selectedDates={scheduledDates} onDateChange={handleDateChange} minDate={new Date} calendarType={monthView} />
        </>
    );
};

export default MonthlySchedule;
