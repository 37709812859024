import React, { useState, useEffect } from "react";
import Search from "../../common/atoms/Search";
import Pagination from "../../common/atoms/Pagination";
import { Link } from "react-router-dom";
import { ApiCall } from "../../../services/ApiServices";
import { EMAIL_TEMPLATES, GET_TEMPLATES } from "../../../routes/ApiEndpoints";
import { t, translate } from "../translation/Translation";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import Icon from "../../../Icon";
import BackLink from "../../common/atoms/BackLink";
import OverFlowWrapper from "../../common/OverFlowWrapper";

const ITEMS_PER_PAGE = 10;

interface EmailTemplate {
  id: number;
  name: string;
  module_name: string;
}

const ManageEmailTemplatesPage: React.FC = () => {
  const [searchData, setSearchData] = useState({
    name: "",
  });
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]); // State to hold the fetched email templates

  const [currentPage, setCurrentPage] = useState(1);
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchAllEmailTemplates();
  }, []);

  const fetchAllEmailTemplates = async () => {
    //api call to get all the templates created
    try {
      const response = await ApiCall.getService(
        EMAIL_TEMPLATES,
        // GET_TEMPLATES,
        "GET",
        "central-data-management"
      );
      if (response.status === 200) {
        setEmailTemplates(response.data); // Update the state with fetched email templates
      }
    } catch (error) {
      // Handle API error
      console.error("Error fetching email templates:", error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    setCurrentPage(1);
  };

  const filteredEmailTemplates = emailTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchData.name.toLowerCase())
  );

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = filteredEmailTemplates.slice(startIndex, endIndex);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Email templates",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Header
        headerName={t("Email template")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-4">
        <div className="row mb-2">
          <BackLink backLink="/" />
        </div>
        <div className="row">
          <div className="col-8">
            <Search
              className="form-control inputFieldColor"
              handleChange={handleSearchChange}
              placeholder={t("Search by name")}
              name="name"
              value={searchData.name}
            />
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive manage-email-template">
          <table className="table table-hover customTableProperties">
            <thead>
              <tr className="TableHeader">
                <th>{t("Name")}</th>
                {/* <th>{t("Module name")}</th> */}
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Email templates",
                      update: true,
                      delete: true,
                      read: true,
                    },
                  ]}
                  actions={true}
                  strict={false}
                >
                  <th>{t("Actions")}</th>
                </AccessControl>
              </tr>
              <tr>
                <td
                  style={{ height: "0.7vw" }}
                  className="emptyRow p-0"
                  colSpan={2}
                ></td>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((template, index) => (
                  <tr key={template.id} className="border">
                    <td className="text-break" data-label="Name">
                      {t(`${template.name}`)}
                    </td>
                    {/* <td className="text-break" data-label="Module name">
                         {t(`${template.module_name}`)}
                        </td> */}
                    <td className="px-2">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Email templates",
                            update: true,
                          },
                        ]}
                      >
                        <Link
                          to={`/email-template/edit/${template.id}`}
                          className="btn p-0 border-0 me-2 secondaryColorHoverEffect"
                          title={t("Edit")}
                        >
                          <Icon
                            isIcon={true}
                            width="1vw"
                            height="1vw"
                            name="EditIcon"
                          />
                        </Link>
                      </AccessControl>
                      <Link
                        to={`/email-template/view/${template.id}`}
                        className="btn p-0 border-0 me-2 secondaryColorHoverEffect"
                        title={t("View")}
                      >
                        <Icon
                          isIcon={true}
                          width="1.2vw"
                          height="1.2vw"
                          name="viewIcon"
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="border text-danger text-center">
                    {t("No records")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </OverFlowWrapper>
        {Math.ceil(filteredEmailTemplates.length / ITEMS_PER_PAGE) > 1 && (
          <div className="pagination justify-content-center align-items-center mt-4 px-4">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(
                filteredEmailTemplates.length / ITEMS_PER_PAGE
              )}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </AccessControl>
  );
};

export default translate(ManageEmailTemplatesPage);
