import { AES, enc } from "crypto-ts";

const key = 'H98zM6i/55yNJfkFsbu0HrzlFo17FtR9';
const iv = key.slice(0, 16);
const parsedKey = enc.Utf8.parse(key);
const parsedIv = enc.Utf8.parse(iv);

const EncryptDecryptService = {
    encryptData: (data: string): string => {
        const ciphertext = AES.encrypt(data, parsedKey, { iv: parsedIv }).toString();
        return ciphertext;
    },
    
    decryptData: (encryptedData: string): string => {
        const decrypted = AES.decrypt(encryptedData, parsedKey, { iv: parsedIv });
        const decryptedData = decrypted.toString(enc.Utf8);
        return decryptedData;
    }
}

export default EncryptDecryptService;