import React, { useState, useEffect, useContext } from "react";
import ValidationService from "./ValidationService";
import { ApiCall } from "../../../services/ApiServices";
import { PcContext } from "./PcContext";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import {
    t,
    translate,
} from "../../CentralDataMangement/translation/Translation";
import {
    GET_FUNCTION,
    STORE_FUNCTION,
    UPDATE_FUNCTION,
} from "../../../routes/ApiEndpoints";
import Title from "../../common/atoms/Title";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import LabelField from "../../common/atoms/LabelField";
import Button from "../../common/atoms/Button";

function AddFunction(props) {
    const [id, setId] = useState("");
    const [data, setData] = useState({
        id: "",
        function_name: "",
        min_salary: "",
        pc_unique_key: "",
        category_id: "",
    });
    const {
        setCurrent_sec,
        setSec_completed,
        sec_completed,
        pc_unique_key,
        setPc_unique_key,
        setCat_rightsec,
        setCat_leftsec,
        setCat_fun_updated,
        cat_subsec_type,
        setCat_subsec_type,
        cat_subsec_id,
        setCat_subsec_id,
    } = useContext(PcContext);

    const [error_function_name, setError_function_name] = useState("");
    const [error_min_salary, setError_min_salary] = useState("");
    const [disableSave, setDisableSave] = useState(false);

    const { uid } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const fid = searchParams.get("fid") ?? "";
    const cid = searchParams.get("cid") ?? "";

    let postdata = () => {
        setDisableSave(true);
        if (id === "") {
            ApiCall.service(
                STORE_FUNCTION,
                "POST",
                data,
                false,
                "central-data-management"
            )
                .then((result) => {
                    if (result.success) {
                        if (fid !== null && fid !== undefined && fid !== "") {
                            navigate("/manage-functions");
                        } else {
                            setCat_fun_updated("fun" + result.fcid);
                            setCat_rightsec("d-none");
                            setCat_leftsec("col-md-12");
                            setCat_subsec_type(0);
                            setCat_subsec_id("");
                            // setId(result.fcid);
                        }
                        setDisableSave(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            ApiCall.service(
                UPDATE_FUNCTION + "/" + id,
                "PUT",
                data,
                false,
                "central-data-management"
            )
                .then((result) => {
                    if (result.success) {
                        if (fid !== null && fid !== undefined && fid !== "") {
                            navigate("/manage-functions");
                        } else {
                            setCat_fun_updated("funupdate" + result.fcid);
                            setCat_rightsec("d-none");
                            setCat_leftsec("col-md-12");
                            setCat_subsec_type(0);
                            setCat_subsec_id("");
                        }
                        setDisableSave(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
    let resetErrors = () => {
        setError_function_name("");
        setError_min_salary("");
    };
    useEffect(() => {
        if (cat_subsec_type == 2) {
            if (cat_subsec_id != '')
                setId(cat_subsec_id);
            resetErrors();
        }
        if (cat_subsec_id == '') {
            //create category data for add function flow
            var data1 = data;
            data1.id = "";
            data1.function_name = "";
            data1.min_salary = "";
            data1.category_id = "";
            setData(data1);
        }
    }, [cat_subsec_id]);
    useEffect(() => {
        if (id !== "") {
            ApiCall.getService(
                GET_FUNCTION + "/" + id,
                "GET",
                "central-data-management"
            )
                .then((result) => {
                    if (result.success && result.data) {
                        var res = [];
                        res.function_name = result.data.function_name;
                        res.id = result.data.id;
                        res.min_salary = result.data.min_salary;
                        res.min_salary_temp = result.data.min_salary;
                        res.category_id =
                            result.data.category_id == null ? "" : result.data.category_id;
                        setData(res);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [id, cat_subsec_type]);

    let submit = (event) => {
        event.preventDefault();
        if (validate()) {
            postdata();
        }
    };
    let validate = () => {
        var error = [];
        error["error_function_name"] = ValidationService.emptyValidationMethod(
            data.function_name.trim()
        );
        error["error_min_salary"] =
            ValidationService.emptyValidationMethod(data.min_salary.trim()) === ""
                ? ValidationService.minSalaryValidationMethod(data.min_salary.trim()) ===
                    ""
                    ? ""
                    : t("This field is invalid.")
                : t("This field is required.");

        if (error["error_function_name"] === "") {
            if (typeof props.categorylist == "object") {
                Object.keys(props.categorylist).map((element) => {
                    if (
                        props.categorylist[element].type === "3" &&
                        props.categorylist[element].id !== id &&
                        props.categorylist[element].function_name
                            .replaceAll(" ", "")
                            .toLowerCase() ===
                        data.function_name.replaceAll(" ", "").toLowerCase()
                    ) {
                        error["error_function_name"] = t("Function name already exist.");
                    }
                    if (props.categorylist[element].type === "2") {
                        if (props.categorylist[element].childObj !== undefined) {
                            Object.keys(props.categorylist[element].childObj).map((val) => {
                                if (
                                    props.categorylist[element].childObj[val].type === "3" &&
                                    props.categorylist[element].childObj[val].id !== id &&
                                    props.categorylist[element].childObj[val].function_name
                                        .replaceAll(" ", "")
                                        .toLowerCase() ===
                                    data.function_name.replaceAll(" ", "").toLowerCase()
                                ) {
                                    error["error_function_name"] = t(
                                        "Function name already exist."
                                    );
                                }
                            });
                        }
                    }
                });
            } else {
                props.categorylist.forEach((val1, key) => {
                    if (
                        val1.type === "3" &&
                        val1.id !== id &&
                        val1.function_name.replaceAll(" ", "").toLowerCase() ===
                        data.function_name.replaceAll(" ", "").toLowerCase()
                    ) {
                        error["error_function_name"] = t("Function name already exist.");
                    }
                    if (val1.type === "2") {
                        if (val1.childObj !== undefined) {
                            val1.childObj.forEach((v, k) => {
                                if (
                                    v.type === "3" &&
                                    v.id !== id &&
                                    v.function_name.replaceAll(" ", "").toLowerCase() ===
                                    data.function_name.replaceAll(" ", "").toLowerCase()
                                ) {
                                    error["error_function_name"] = t(
                                        "Function name already exist."
                                    );
                                }
                            });
                        }
                    }
                });
            }
        }
        if (
            error["error_min_salary"] === "" &&
            data.min_salary !== "" &&
            data.min_salary != null &&
            data.category_id !== ""
        ) {
            if (typeof props.categorylist == "object") {
                Object.keys(props.categorylist).map((element) => {
                    if (
                        props.categorylist[element].type === "2" &&
                        props.categorylist[element].id === data.category_id
                    ) {
                        error["error_min_salary"] =
                            parseFloat(data.min_salary.replace(",", ".")) <
                                parseFloat(
                                    props.categorylist[element].min_salary.replace(",", ".")
                                )
                                ? t("Minimum salary cannot be lesser that ") +
                                props.categorylist[element].min_salary +
                                " €"
                                : "";
                    }
                });
            } else
                props.categorylist.forEach((val, key) => {
                    if (val.type === "2" && val.id === data.category_id) {
                        error["error_min_salary"] =
                            parseFloat(data.min_salary.replace(",", ".")) <
                                parseFloat(val.min_salary.replace(",", "."))
                                ? t("Minimum salary cannot be lesser that ") +
                                val.min_salary +
                                " €"
                                : "";
                    }
                });
        }
        setError_function_name(error["error_function_name"]);
        setError_min_salary(error["error_min_salary"]);
        if (error["error_function_name"] === "" && error["error_min_salary"] === "") {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div>
            <form className="border rounded-3 p-3 mt-1" onSubmit={submit}>
                <Title
                    title={id !== "" ? t("Edit function") : t("Add function")}
                    className="colorPrimary poppins-semibold mb-3"
                />
                <div className="row">
                    <div className="col-12 mb-3">
                        <LabelWithInputField
                            label={t("Function name")}
                            name="name"
                            id="name"
                            value={data.function_name}
                            handleChange={(e) => {
                                setData((prev) => ({ ...prev, function_name: e.target.value }));
                            }}
                            error={t(error_function_name)}
                            className="ps-2"
                        />
                    </div>
                    {console.log(props.categorylist, "code check")}

                    {Object.keys(props.categorylist).length > 0 && (

                        <div className="col-12 mb-3">
                            <LabelField title={t("Category")} />
                            <select
                                className="form-select form-control shadow-none inputFieldColor functionCategoryField ps-3"
                                value={data.category_id}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        let sal = "";
                                        if (typeof props.categorylist == "object") {
                                            Object.keys(props.categorylist).map((element) => {
                                                if (e.target.value === props.categorylist[element].id) {
                                                    sal =
                                                        data.min_salary_temp !== undefined &&
                                                            parseFloat(data.min_salary_temp.replace(",", ".")) >
                                                            parseFloat(
                                                                props.categorylist[element].min_salary.replace(
                                                                    ",",
                                                                    "."
                                                                )
                                                            )
                                                            ? data.min_salary_temp
                                                            : props.categorylist[element].min_salary;
                                                }
                                            });
                                        } else {
                                            let obj = props.categorylist.find(
                                                (o) => o.id === parseInt(e.target.value)
                                            );
                                            sal = obj["min_salary"];
                                        }
                                        setData((prev) => ({
                                            ...prev,
                                            category_id: e.target.value,
                                            min_salary: sal,
                                        }));
                                    } else {
                                        setData((prev) => ({
                                            ...prev,
                                            category_id: "",
                                            min_salary: "",
                                        }));
                                    }
                                }}
                            >
                                <option value="">{t("Select")}</option>
                                {Object.keys(props.categorylist).map(
                                    (val, key) =>
                                        props.categorylist[val]["type"] == "2" && (
                                            <option value={props.categorylist[val]["id"]} key={val}>
                                                {props.categorylist[val]["category_name"]}
                                            </option>
                                        )
                                )}
                            </select>
                        </div>
                    )}
                    <div className="col-12 mb-3">
                        <div className="input-group position-relative">
                            <LabelWithInputField
                                label={t("Minimum salary")}
                                name="salary"
                                id="salary"
                                value={data.min_salary}
                                handleChange={(e) => {
                                    setData((prev) => ({ ...prev, min_salary: e.target.value }));
                                }}
                                error={t(error_min_salary)}
                                className="ps-5"
                                isTop={true}
                            />
                            <span className="fieldPrefix position-absolute">€</span>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="text-end col-md-6 ms-auto">
                        <Button
                            handleClick={() => {
                                setData((prev) => ({
                                    ...prev,
                                    pc_unique_key: pc_unique_key,
                                    id: id,
                                }));
                            }}
                            title={t("Save")}
                            className="searchBtn px-3"
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default translate(AddFunction);
