import React from "react";
import LabelField from "../atoms/LabelField";
import InputTextfield from "../atoms/InputTextField";

interface InputTextFieldWithLabelProps {
  type?: string;
  className?: string;
  colClassName?:string;
  placeholder?: string;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  value: string;
  name: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiLabel?: string;
  isTop?: boolean;
}

const InputTextFieldWithLabel: React.FC<InputTextFieldWithLabelProps> = ({
  type,
  className = "",
  placeholder = "",
  handleBlur,
  label,
  value,
  name,
  id = "",
  isDisabled = false,
  customStyle,
  isMandatory = false,
  labelClassName = "",
  error,
  min,
  step,
  max,
  readOnly = false,
  autoComplete = "off",
  handleChange,
  multiLabel = "",
  isTop = false,
  colClassName
}) => {
  const shouldUseFullWidth = !label || (label && isTop);

  return (
    <>
      {label && (
        <div
          className={`text-start ${
            isTop ? "col-12" : "col-3 pe-0 align-self-center"
          }`}
        >
          <LabelField
            title={label}
            className={labelClassName}
            isMandatory={isMandatory}
            htmlfor={id}
            subtitle={multiLabel}
          />
        </div>
      )}

      <div className={`text-start ${shouldUseFullWidth ? "col-12" : `${colClassName}`}`}>
        <InputTextfield
          id={id}
          type={type}
          className={`form-control field-shadow  ${className}`}
          value={value}
          isDisabled={isDisabled}
          placeholder={placeholder}
          customStyle={customStyle}
          handleChange={handleChange || (() => {})}
          handleBlur={handleBlur}
          name={name}
          error={error}
          min={min}
          step={step}
          max={max}
          readOnly={readOnly}
          autoComplete={autoComplete}
        />
      </div>

    </>
  );
};

export default InputTextFieldWithLabel;
