import React, { useState, useContext, useEffect } from "react";
import ValidationService from "./ValidationService";
import { ApiCall } from "../../../services/ApiServices";
import { PcContext } from "./PcContext";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import {
  ADD_PC,
  GET_PC_DETAILS_BY_UNIQUE_ID,
  UPDATE_PC,
} from "../../../routes/ApiEndpoints";
import Title from "../../common/atoms/Title";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import Button from "../../common/atoms/Button";
import OverFlowWrapper from "../../common/OverFlowWrapper";

/**
 *
 * @param {*} props
 * @returns the added category data to the common.js component.
 */
function AddPc(props) {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // const fid = searchParams.get('fid');
  // const cid = searchParams.get('cid');

  const [sec_width, setSec_width] = useState("col-md-6");

  const {
    pc_unique_key,
    setCurrent_sec,
    setSec_completed,
    sec_completed,
    setPc_unique_key,
    cat_subsec_type,
    setCat_subsec_type,
    setCat_rightsec,
    setCat_leftsec,
    setCat_fun_updated,
    setCat_subsec_id,
    pc_view_type,
    cat_subsec_id,
    setUpdate_sec_completed,
  } = useContext(PcContext);

  const [error_pc_number, setError_pc_number] = useState("");
  const [error_pc_name, setError_pc_name] = useState("");
  const [error_pc_alias_name, setError_pc_alias_name] = useState("");
  const [error_min_time, setError_min_time] = useState("");
  const [error_max_time, setError_max_time] = useState("");
  const [error_buffer_time, setError_buffer_time] = useState("");
  const [error_max_time_week_warning, setError_max_time_week_warning] =
    useState("");
  const [error_max_time_week_error, setError_max_time_week_error] =
    useState("");
  const [error_max_employee_time, setError_max_employee_time] = useState("");
  const [disableSave, setDisableSave] = useState(false);

  const [data, setData] = useState({
    id: "",
    pc_unique_key: "",
    pc_number: "",
    pc_name: "",
    pc_alias_name: "",
    buffer_timings: "",
    min_work_timings: "",
    max_work_timings: "",
    max_work_hr_week_warning: "",
    max_end_time_per_day: "",
    max_work_hr_week_error: "",
  });
  const [id, setId] = useState("");

  /**
   * Prefill data if pc already exist
   */
  useEffect(() => {
    if (!uid) return;

    ApiCall.getService(
      GET_PC_DETAILS_BY_UNIQUE_ID + "/" + uid,
      "GET",
      "central-data-management"
    )
      .then((result) => {
        if (result.success) {
          const completed = result.data.completed;
          const completedStates = {
            1: { pc: true },
            2: { pc: true, cat: true },
            3: { pc: true, cat: true, age: true },
            4: { pc: true, cat: true, age: true, emp_type: true },
            5: {
              pc: true,
              cat: true,
              age: true,
              emp_type: true,
              sal_benefit: true,
            },
          };
          setSec_completed((oldState) => ({
            ...oldState,
            ...completedStates[completed],
          }));

          const data1 = {
            id: result.data.id,
            pc_unique_key: result.data.pc_unique_key,
            pc_number: result.data.pc_number,
            pc_name: result.data.pc_name,
            min_work_timings: result.data.min_work_timings,
            max_work_timings: result.data.max_work_timings,
            buffer_timings: result.data.buffer_timings,
            pc_alias_name: result.data.pc_alias_name || "",
            max_work_hr_week_warning: result.data.max_work_hr_week_warning,
            max_work_hr_week_error: result.data.max_work_hr_week_error,
            max_end_time_per_day: result.data.max_end_time_per_day,
          };

          setId(result.data.id);
          setData(data1);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    setPc_unique_key(uid); // Updating pc_unique_key with the value from URL
  }, [uid]);

  useEffect(() => {
    if (pc_view_type === "viewpc") {
      // setDisableForm(true);
      setSec_width("col-md-12");
    }
    if (pc_view_type === "editpc") {
      setSec_width("col-md-12");
    }
  }, [pc_view_type]);

  useEffect(() => {
    if (cat_subsec_type === 3) {
      setId(cat_subsec_id);
    }
  }, [cat_subsec_id]);

  /**
   * it will post the committee data to the backend by using api's
   * @param {*} e
   */

  let postdata = async (e) => {
    setDisableSave(true);
    if (id === "") {
      ApiCall.service(ADD_PC, "POST", data, false, "central-data-management")
        .then((result) => {
          if (result.status === 200) {
            if (cat_subsec_type === 3) {
              setCat_fun_updated("pc" + result.pcid);
              setCat_rightsec("d-none");
              setCat_leftsec("col-md-12");
              setCat_subsec_type(0);
              setCat_subsec_id("");
            } else {
              setId(result.pcid);
              setCurrent_sec(2);
              var res1 = sec_completed;
              res1["pc"] = true;
              setSec_completed(res1);
            }
          } else if (result.status === 205) {
            setDisableSave(false);
            checkduplicates(result.data);
          }
          setDisableSave(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      ApiCall.service(
        UPDATE_PC + "/" + id,
        "PUT",
        data,
        false,
        "central-data-management"
      )
        .then((result) => {
          if (result.status === 200) {
            if (cat_subsec_type === 3) {
              setCat_fun_updated("pc" + result.pcid);
              setCat_rightsec("d-none");
              setCat_leftsec("col-md-12");
              setCat_subsec_type(0);
              setCat_subsec_id("");
            } else {
              setCurrent_sec(2);
              var res1 = sec_completed;
              res1["pc"] = true;
              setSec_completed(res1);
            }
          } else if (result.status === 205) {
            checkduplicates(result.data);
          } else {
            console.log(result);
          }
          setDisableSave(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  let checkduplicates = (res) => {
    res.forEach((element, key) => {
      if (
        element.pc_number.replaceAll(" ", "").toLowerCase() ===
        data.pc_number.replaceAll(" ", "").toLowerCase()
      ) {
        setError_pc_number("Paritair comite number already exists.");
      }
      if (
        element.pc_alias_name != null &&
        data.pc_alias_name != null &&
        element.pc_alias_name.replaceAll(" ", "").toLowerCase() ===
          data.pc_alias_name.replaceAll(" ", "").toLowerCase()
      ) {
        setError_pc_alias_name("Paritair comite alias name already exists.");
      }
      if (
        element.pc_alias_name != null &&
        data.pc_name != null &&
        element.pc_alias_name.replaceAll(" ", "").toLowerCase() ===
          data.pc_name.replaceAll(" ", "").toLowerCase()
      ) {
        setError_pc_name("Paritair comite name already exists.");
      }
      if (
        element.pc_name != null &&
        data.pc_alias_name != null &&
        element.pc_name.replaceAll(" ", "").toLowerCase() ===
          data.pc_alias_name.replaceAll(" ", "").toLowerCase()
      ) {
        setError_pc_alias_name("Paritair comite alias name already exists.");
      }
      if (
        element.pc_name != null &&
        data.pc_name != null &&
        element.pc_name.replaceAll(" ", "").toLowerCase() ===
          data.pc_name.replaceAll(" ", "").toLowerCase()
      ) {
        setError_pc_name("Paritair comite name already exists.");
      }
    });
  };

  let submit = async (event) => {
    event.preventDefault();
    let valid_res = validate(data);
    if (valid_res) {
      postdata();
    }
  };

  const validate = (res) => {
    let error1 = [];
    console.log(res);
    let min_time =
      res.min_work_timings != null && res.min_work_timings.length !== 0
        ? res.min_work_timings.replaceAll(" ", "")
        : "";
    let max_time =
      res.max_work_timings != null && res.max_work_timings.length !== 0
        ? res.max_work_timings.replaceAll(" ", "")
        : "";
    let buffer_timings =
      res.buffer_timings != null && res.buffer_timings !== 0
        ? res.buffer_timings.replaceAll(" ", "")
        : "";
    let max_pr_employee = "";
    if (
      res.max_end_time_per_day !== undefined &&
      res.max_end_time_per_day.length !== 0
    ) {
      max_pr_employee = res.max_end_time_per_day.replaceAll(" ", "");
    }

    let max_work_hr_week_warning =
      res.max_work_hr_week_warning.length !== 0
        ? res.max_work_hr_week_warning.replaceAll(" ", "")
        : "";
    let max_work_hr_week_error = res.max_work_hr_week_error.replaceAll(" ", "");

    //check if required fields are empty
    error1["pc_name"] = ValidationService.emptyValidationMethod(
      res.pc_name.trim()
    );
    error1["pc_number"] = ValidationService.emptyValidationMethod(
      res.pc_number.trim()
    );
    error1["min_work_timings"] = ValidationService.emptyValidationMethod(
      min_time.trim()
    );
    error1["max_work_timings"] = ValidationService.emptyValidationMethod(
      max_time.trim()
    );
    error1["buffer_timings"] = ValidationService.emptyValidationMethod(
      buffer_timings.trim()
    );
    error1["max_work_hr_week_warning"] =
      ValidationService.emptyValidationMethod(max_work_hr_week_warning.trim());
    error1["max_work_hr_week_error"] = ValidationService.emptyValidationMethod(
      max_work_hr_week_error.trim()
    );

    //check if fields are valid
    error1["pc_number"] =
      error1["pc_number"] === ""
        ? ValidationService.pcNumberValidationMethod(res.pc_number.trim())
        : error1["pc_number"];

    error1["min_work_timings"] =
      error1["min_work_timings"] === ""
        ? ValidationService.hoursperdayValidationMethod(min_time.trim())
        : error1["min_work_timings"];
    error1["max_work_timings"] =
      error1["max_work_timings"] === ""
        ? ValidationService.hoursperdayValidationMethod(max_time.trim())
        : error1["max_work_timings"];
    error1["buffer_timings"] =
      error1["buffer_timings"] === ""
        ? ValidationService.hoursperdayValidationMethod(buffer_timings.trim())
        : error1["buffer_timings"];
    // error1['pc_name'] =
    // 	error1['pc_name'] == '' ? ValidationService.nameValidationMethod(res.pc_name) : error1['pc_name'];
    // error1['pc_alias_name'] =
    // 	res.pc_alias_name != '' && res.pc_alias_name != undefined
    // 		? ValidationService.nameValidationMethod(res.pc_alias_name)
    // 		: '';
    error1["max_work_hr_week_warning"] =
      error1["max_work_hr_week_warning"] === ""
        ? ValidationService.maximumhourperweekValidationMethod(
            res.max_work_hr_week_warning.trim()
          )
        : error1["max_work_hr_week_warning"];

    error1["max_work_hr_week_error"] =
      error1["max_work_hr_week_error"] === ""
        ? ValidationService.maximumhourperweekValidationMethod(
            res.max_work_hr_week_error.trim()
          )
        : error1["max_work_hr_week_error"];

    if (
      error1["min_work_timings"] === "" &&
      error1["max_work_timings"] === ""
    ) {
      error1["max_work_timings"] =
        parseFloat(min_time.trim()) > parseFloat(max_time.trim())
          ? "Maximum work timing cannot be lesser than minimum work timing."
          : "";
    }
    error1["pc_alias_name"] =
      res.pc_alias_name !== "" &&
      res.pc_alias_name != null &&
      res.pc_name.replaceAll(" ", "").toLowerCase().trim() ===
        res.pc_alias_name.replaceAll(" ", "").toLowerCase().trim()
        ? "Alias name cannot be same as paritair committe name."
        : "";
    //seterror messages
    setError_pc_number(error1["pc_number"]);
    setError_pc_name(error1["pc_name"]);
    setError_pc_alias_name(error1["pc_alias_name"]);
    setError_min_time(error1["min_work_timings"]);
    setError_max_time(error1["max_work_timings"]);
    setError_buffer_time(error1["buffer_timings"]);
    setError_max_time_week_warning(error1["max_work_hr_week_warning"]);
    setError_max_time_week_error(error1["max_work_hr_week_error"]);
    //return false if there is an error else return true
    max_pr_employee !== ""
      ? setError_max_employee_time(
          ValidationService.hoursperdayValidationMethod(max_pr_employee)
        )
      : setError_max_employee_time("");
    if (
      error1["pc_number"] === "" &&
      error1["pc_name"] === "" &&
      error1["pc_alias_name"] === "" &&
      error1["min_work_timings"] === "" &&
      error1["max_work_timings"] === "" &&
      error1["buffer_timings"] === "" &&
      error1["max_work_hr_week_warning"] === "" &&
      error1["max_work_hr_week_error"] === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  let backToDashboard = () => {
    var src = JSON.parse(localStorage.getItem("src"));
    var type = JSON.parse(localStorage.getItem("type"));
    if (src) {
      window.localStorage.removeItem("src");
      if (type === "1") {
        window.location.assign(src);
      } else {
        navigate(`/${src}`);
      }
    }
  };

  return (
    <div>
      <form onSubmit={(e) => submit(e)} className="border p-3 rounded-3 mt-1">
        {/* {cat_subsec_type == 3 ? <h4 className="h5 mt-3" >Edit paritair comite</h4> : ''} */}
        <OverFlowWrapper className="ageWrapper ps-1">
          <Title
            title={cat_subsec_type === 3 ? t("Edit paritair committee") : ""}
            className="colorPrimary poppins-medium mb-3 pcTitle"
          />
          <div
            className={
              pc_view_type === "addpc"
                ? "row parithair-border m-0 px-md-4 "
                : "row"
            }
          >
            <div className="col-12 mb-3">
              <LabelWithInputField
                label={t("Paritair committee number")}
                value={data.pc_number}
                name="pcNumber"
                id="pcNumber"
                handleChange={(e) => {
                  setData((prev) => ({ ...prev, pc_number: e.target.value }));
                }}
                error={t(error_pc_number)}
                className="ps-2"
              />
            </div>
            <div className="col-12 mb-3">
              <LabelWithInputField
                label={t("Paritair committee name")}
                value={data.pc_name}
                name="pcname"
                id="pcname"
                handleChange={(e) => {
                  setData((prev) => ({ ...prev, pc_name: e.target.value }));
                }}
                error={t(error_pc_name)}
                className="ps-2"
              />
            </div>
            <div className="col-12 mb-3">
              <LabelWithInputField
                label={t("Paritair committee alias name")}
                value={data.pc_alias_name}
                name="pcAlasName"
                id="pcAlasName"
                handleChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    pc_alias_name: e.target.value,
                  }));
                }}
                error={t(error_pc_alias_name)}
                className="ps-2"
              />
            </div>
            <div className="col-12 mb-3">
              <div className="input-group position-relative">
                <LabelWithInputField
                  label={t("Buffer time between two plannings")}
                  value={
                    data.buffer_timings
                      ? data.buffer_timings.replace(/\./g, ",")
                      : ""
                  }
                  name="bufferTime"
                  id="bufferTime"
                  handleChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      buffer_timings: e.target.value,
                    }));
                  }}
                  error={t(error_buffer_time)}
                  className="ps-2"
                  isTop={true}
                />
                <span className="fieldSuffix position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="input-group position-relative">
                <LabelWithInputField
                  label={t("Minimum work hours per day")}
                  value={
                    data.min_work_timings
                      ? data.min_work_timings.replace(/\./g, ",")
                      : ""
                  }
                  name="minTime"
                  id="minTime"
                  handleChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      min_work_timings: e.target.value,
                    }));
                  }}
                  error={t(error_min_time)}
                  className="ps-2"
                  isTop={true}
                />
                <span className="fieldSuffix position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="input-group position-relative">
                <LabelWithInputField
                  label={t("Maximum work hours per day")}
                  value={
                    data.max_work_timings
                      ? data.max_work_timings.replace(/\./g, ",")
                      : ""
                  }
                  name="maxTime"
                  id="maxTime"
                  handleChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      max_work_timings: e.target.value,
                    }));
                  }}
                  error={t(error_max_time)}
                  className="ps-2"
                  isTop={true}
                />
                <span className="fieldSuffix position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="input-group position-relative">
                <LabelWithInputField
                  label={t("Employee can work upto")}
                  value={
                    data.max_end_time_per_day
                      ? data.max_end_time_per_day.replace(/\./g, ",")
                      : ""
                  }
                  name="maxEndTime"
                  id="maxEndTime"
                  handleChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      max_end_time_per_day: e.target.value,
                    }));
                  }}
                  error={t(error_max_employee_time)}
                  className="ps-2"
                  isTop={true}
                />
                <span className="fieldSuffix position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="input-group position-relative">
                <LabelWithInputField
                  label={t("Maximum work hours per week (Warning)")}
                  value={
                    data.max_work_hr_week_warning
                      ? data.max_work_hr_week_warning.replace(/\./g, ",")
                      : ""
                  }
                  name="maxworkHorsperWeek"
                  id="maxworkHorsperWeek"
                  handleChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      max_work_hr_week_warning: e.target.value,
                    }));
                  }}
                  error={t(error_max_time_week_warning)}
                  className="ps-2"
                  isTop={true}
                />
                <span className="fieldSuffix position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="input-group position-relative">
                <LabelWithInputField
                  label={t("Maximum work hours per week (Error)")}
                  value={
                    data.max_work_hr_week_error
                      ? data.max_work_hr_week_error.replace(/\./g, ",")
                      : ""
                  }
                  name="maxworkHorsperWeekError"
                  id="maxworkHorsperWeekError"
                  handleChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      max_work_hr_week_error: e.target.value,
                    }));
                  }}
                  error={t(error_max_time_week_error)}
                  className="ps-2"
                  isTop={true}
                />
                <span className="fieldSuffix position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
          </div>
          {cat_subsec_type === 3 ? (
            <div className="row">
              <div className="col-6 ms-auto text-end">
                <Button
                  handleClick={() => {
                    setData((prev) => ({
                      ...prev,
                      pc_unique_key: pc_unique_key,
                      id: id,
                    }));
                  }}
                  disabled={disableSave}
                  title={t("Save")}
                  className="searchBtn px-3"
                  type="submit"
                />
              </div>
            </div>
          ) : (
            <div className="row m-0 mt-4 mb-2">
              <div className="text-start col-md-6 p-0 d-flex align-items-center back-btn-mobile">
                <button
                  type="button"
                  className="bg-white border-0 poppins-regular-18px px-0 shadow-none text-decoration-underline"
                  onClick={() => backToDashboard()}
                >
                  {t("BACK")}
                </button>
              </div>
              <div className="text-end col-md-6 px-0 back-btn-mobile">
                <button
                  type="sumit"
                  className="btn rounded-0 px-3  btn-block float-end px-0 poppins-medium-18px-next-button shadow-none"
                  disabled={disableSave}
                  onClick={() => {
                    setData((prev) => ({
                      ...prev,
                      pc_unique_key: pc_unique_key,
                      id: id,
                    }));
                  }}
                >
                  {t("NEXT")}
                </button>
              </div>
            </div>
          )}
        </OverFlowWrapper>
      </form>
    </div>
  );
}

export default translate(AddPc);
