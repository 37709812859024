import React from "react";
import Icon from "../../../Icon";
import { t } from "../../CentralDataMangement/translation/Translation";
import Link from "./Link";

interface Props {
  backLink?: string | (() => void); // Accept both string and function
}

const BackLink: React.FC<Props> = ({ backLink }) => {
  const handleClick = (event: React.MouseEvent) => {
    if (typeof backLink === "function") {
      event.preventDefault(); // Prevent default link behavior
      backLink();
    }
  };

  return (
    <div className="col-6 mb-2">
      <Link
        className="pagebackIcon"
        href={typeof backLink === "string" ? backLink : "#"}
        onClick={handleClick}
      >
        <span title={t("Back")}>
          <Icon isIcon={true} width="1vw" height="1vw" name="longLeftArrow" />
        </span>
      </Link>
    </div>
  );
};

export default BackLink;
