// utils/dateHelpers.ts

// utils/dateHelpers.ts

/**
 * Formats a Date object to a YYYY-MM-DD string.
 * @param date - The date string to format.
 * @returns A string in YYYY-MM-DD format.
 */
export const formatDate = (date: string): string => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
export const formatDateTime = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

/**
 * Generates an array of dates between startDate and endDate in YYYY-MM-DD format.
 * @param startDate - The start date in YYYY-MM-DD format.
 * @param endDate - The end date in YYYY-MM-DD format.
 * @returns An array of date strings in YYYY-MM-DD format.
 */
export const generateDateRange = (startDate: string, endDate: string): string[] => {
    const dates: string[] = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
        dates.push(formatDate(currentDate.toISOString())); // Pass ISO string to formatDate
        currentDate.setDate(currentDate.getDate() + 1); // Increment date by one day
    }

    return dates;
};
