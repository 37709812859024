import React, { useContext } from 'react';
// import LinkCoeffEmpContext from '../../Contexts/LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext';
import LinkCoeffEmpContext from './LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';

const DEFAULT = 2;
const CoeffcientValuesFirstPart = (props) => {
    const { state } = useContext(LinkCoeffEmpContext);
    const { selectedDevaitionObj, valueTypeArray } = state;

    const getTableContent = () => {
        let htmlContent = [];
        selectedDevaitionObj.map(coefficient => {
            htmlContent.push(
                <tr className="height-co-eff-deviation" key={coefficient.value} id={coefficient.value}>
                    <td className="three-row-span poppins-medium-16px" rowSpan="1" title={coefficient.label}> {coefficient.label} </td>
                    <td className="value-single-span pc-linking-td poppins-regular-16px">{t('Default')} </td>
                </tr>
            )
        })
        return htmlContent;
    }

    return (
        <table className="table pclinking-table table-first-part">
            <thead className="pclinking-table-thead table-first-part-thead">
                <tr>
                    <th height="100" colSpan="2" className="p-0">
                        <div className="firstpart-cell" style={{ paddingTop: '0.49rem', paddingBottom: '0.49rem' }}>
                            <span title="Employee types" className="cell--topRight poppins-medium-16px" key={`tablecolindex`} scope="col">{t('Employee types')} </span>
                            <span title="Coefficients" className="cell--bottomLeft poppins-medium-10px" key={`tablecolindex2`} scope="col">{t('Coefficient deviations')}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className="pclinking-table-tbody table-first-part-tbody">
                {getTableContent()}
            </tbody>
        </table>
    )
}

export default translate(CoeffcientValuesFirstPart);
