import React, { useRef, useState } from "react";
import Image from "../atoms/Image";
import LabelField from "../atoms/LabelField";
import uploadIcon from "../../../static/images/Upload.svg";
import Icon from "../../../Icon";
import { t } from "../atoms/translation";

interface DocumentUploadProps {
  documentTitle?: string;
  infoIconWrapper?: Boolean;
  helpText?: string;
}

const DocumentsUpload: React.FC<DocumentUploadProps> = ({
  documentTitle,
  infoIconWrapper = false,
  helpText
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFileURL, setUploadedFileURL] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFileName(file.name);
      const fileURL = URL.createObjectURL(file);
      setUploadedFileURL(fileURL);
    }
  };

  const handleRemoveFile = () => {
    // Revoke the object URL to free up memory
    if (uploadedFileURL) {
      URL.revokeObjectURL(uploadedFileURL);
    }
    setUploadedFileName(null);
    setUploadedFileURL(null);
    // Optionally, you can also clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <LabelField title={documentTitle} />
      {uploadedFileURL && uploadedFileName ? (
        <span className="pe-2">
          <a
            href={uploadedFileURL}
            target="_blank"
            rel="noopener noreferrer"
            className="uploadedFile"
          >
            <span className="ms-2">{uploadedFileName}</span>
          </a>
          <span
            onClick={handleRemoveFile}
            className="ms-2 removeUploadedFile cursor-pointer"
            title={t("Remove")}
          >
            <Icon name="RemoveIcon" isIcon={true} width="1vw" height="1vw" />
          </span>
          {infoIconWrapper && (
            <span title={helpText} className="ms-2 cursor-pointer">
              <Icon name="infoIcon" isIcon={true} width="1vw" height="1vw" />
            </span>
          )}
        </span>
      ) : (
        <span
          className="ms-2 cursor-pointer"
          onClick={handleImageClick}
          title={t("Upload file")}
        >
          <Image src={uploadIcon} imageHeight="1vw" imageWidth="1vw" />
        </span>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default DocumentsUpload;
