import React, { ReactNode } from "react";
interface AccordionCustomProps {
  accordionTitle?:string;
  children?:ReactNode;
  accordionTitleClassName?:string;
  accordionButton?:string
}
const AccordionCustom:React.FC<AccordionCustomProps> = ({
  accordionTitle,
  children,
  accordionTitleClassName,
  accordionButton
}) => {
  return (
    <>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className={`${accordionButton} accordion-button collapsed`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
            <div className={accordionTitleClassName}>{accordionTitle}</div>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
             {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionCustom;
