import React, { MouseEventHandler } from "react";
// import { t } from "../common/atoms/translation";
import LabelField from "../common/atoms/LabelField";
import Icon from "../../Icon";
import {t , translate } from "../CentralDataMangement/translation/Translation";
interface invoiceEnquiryProps {
  handleClick?: (actionType: "mail" | "contact") => void;
  documentsTitle?: string;
}
const InvoicesEnquiry: React.FC<invoiceEnquiryProps> = ({
  handleClick,
  documentsTitle,
}) => {
  const IconProps = {
    isIcon: true,
    width: "2vw",
    height: "2vw",
    className: "secondaryColorHoverEffect cursor-pointer",
  };
  return (
    <div className="tableBg rounded-4 p-4">
      <LabelField title={documentsTitle} className="invoicesEnquiry" />
      <div className="text-center mt-3">
        <span
          title={t("Send Mail")}
          onClick={() => handleClick?.("mail")}
          className="me-4"
        >
          <Icon {...IconProps} name="MailIcon" />
        </span>
        <span title={t("Conatct us")} onClick={() => handleClick?.("contact")}>
          <Icon {...IconProps} name="callIcon" />
        </span>
      </div>
    </div>
  );
};

export default translate(InvoicesEnquiry);
