import React, { useReducer, useRef } from 'react';
import LinkCoeffDeviationEmpContext from './LinkCoeffDeviationEmpContext';
import LinkCoeffDeviationEmpReducer from './LinkCoeffDeviationEmpReducer';
import { UPDATE_STATE } from './Actions';

const LinkCoeffDeviationEmpStates = (props) => {
    const initialState = {
        employeeTypeArray: []
        , coefficientTypeArray: []
        , valueTypeArray: []
        , pclinkingValueobj: {}
        , selectedPc: null
        , pcArray: []
        , pcWarning: false
        , lowHighValidation: []
        , inputRef: useRef({})
        , lowKey: 1
        , defaultKey: 2
        , highKey: 3
        , minValue: 0
        , maxValue: 10
        , regexp: /^[0-9,]*$/
        , valueErrorArray: []
        , defaultValueError: []
        , emptyDataWarning: false
        , isOverflow: false
        , tableWidth: '100%'
        , coefficientDeviationObj: []
        , selectedDeviation: null
        , selectedDevaitionObj: []
        , CoefficientDeviationlinkingValueobj: []
    };
    const [state, dispatch] = useReducer(LinkCoeffDeviationEmpReducer, initialState);

    const updateStateChanges = (obj) => {
        dispatch({ type: UPDATE_STATE, payload: obj })
    }

    return (
        <LinkCoeffDeviationEmpContext.Provider value={{
            state,
            updateStateChanges,
        }}> {props.children}
        </LinkCoeffDeviationEmpContext.Provider>
    )
}

export default LinkCoeffDeviationEmpStates;
