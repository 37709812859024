import React, { ReactNode } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
// import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";
import PlanningFilterSection from "./PlanningFilterSection";
import { t, translate } from "../CentralDataMangement/translation/Translation";

interface Location {
  id: number;
  title: string;
}
interface HeaderWithFilterProps {
  children?: ReactNode;
  headerName?: string;
  type?: string;
  page?: string;
  location: Location;
}
const DashboardPlannings: React.FC<HeaderWithFilterProps> = ({
  children,
  headerName,
  type,
  page,
  location
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <PlanningFilterSection type={type} page={page} location={location} >
          {children}
        </PlanningFilterSection>
      </div>
    </div>
  );
};

export default translate(DashboardPlannings);
