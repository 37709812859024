import React from "react";

interface SubIconProps {
  subIconname?: string;
  subIconWidth?: string | number;
  subIconHeight?: string | number;
  borderRadius?: string | number;
  color?: string;
  className?:string;
}
const SubIcon: React.FC<SubIconProps> = ({
  subIconname,
  subIconWidth,
  subIconHeight,
  color,
  borderRadius,
  className,
  ...props
}) => {
  // Convert borderRadius to a style object
  const style: React.CSSProperties = {
    borderRadius:
      typeof borderRadius === "number" ? `${borderRadius}px` : borderRadius,
  };
  return (
    <svg width={subIconWidth} height={subIconHeight} {...props} style={style} className={className}>
      <use xlinkHref={`#${subIconname}`} fill={color} />
    </svg>
  );
};

export default SubIcon;
