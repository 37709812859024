import React, { ReactNode, MouseEventHandler, CSSProperties } from "react";
interface Props {
  title?: ReactNode;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  children?: React.ReactNode;
  toolTipName?: string;
}

const Button: React.FC<Props> = ({
  title,
  handleClick,
  className = "",
  toolTipName = "",
  disabled = false,
  type = "button",
  style = {},
  children,
}) => {
  return (
    <>
      <button
        type={type}
        className={`${className}`}
        onClick={handleClick}
        disabled={disabled}
        title={toolTipName}
        style={style}
      >
        {title}
        {children}
      </button>
    </>
  );
};

export default Button;
