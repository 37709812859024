import React, { useState } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
// import { t } from "../common/atoms/translation";
import HeaderWIthFilter from "./HeaderWIthFilter";
import WeeklyCalendar from "./WeeklyCalendar";
import { useSelector } from "react-redux";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import PlanningTabs from "./PlanningTabs";
import {t , translate } from "../CentralDataMangement/translation/Translation";

const WeeklyPlanningOverview = () => {
  const show = useSelector(selectPlanningShow); // Access the `show` state

  const [date, setDate] = useState(new Date());

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  return (
    <>
      <div className="container-fluid">
        <HeaderWIthFilter headerName={t("Planning - Week")} type="week">
          <DateAndTime
            isWeek={true}
            isIcon={true}
            monthFormat="long"
            dataTimeWrapperClassName="d-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
            dataTimeClassName="mx-3 planningPlannedDate"
            dayClassName="me-1"
            iconFillColor="#8571b9"
            onDateChange={handleDateChange} // Pass date change handler
          />
        </HeaderWIthFilter>
        <div className="row">
          <div className="col-12">
            <WeeklyCalendar startDate={date} /> {/* Pass the start date */}
          </div>
        </div>
        {show && <PlanningTabs />} {/* Conditionally render the modal based on the `show` state */}
      </div>
    </>
  );
};

export default translate(WeeklyPlanningOverview);
