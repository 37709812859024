import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { USER_ROLE_ENTITY_TYPE } from "./Constants";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { t, translate } from "../../../CentralDataMangement/translation/Translation";

const itemsPerPage = 5;

const CoefficientOverview = ({
    headers,
    rows,
    entityId,
    entityType,
    ...props
}) => {
    // entityType === 2
    //     ? headers.indexOf("Employer") > -1
    //         ? headers.splice(headers.indexOf("Employer"), 1)
    //         : headers
    //     : [];

    if (entityType === 2 && headers.indexOf("Employer") > -1) {
        headers.splice(headers.indexOf("Employer"), 1);
    }

    const navigate = useNavigate();
    const [state, setState] = useState({
        searchName: "",
        searchEmployer: "",
        searchCompany: "",
        filterRows: rows,
        currentItems: [],
        pageCount: 0,
        itemOffset: 0,
        currentPage: 0,
    });

    useEffect(() => {
        setState({ ...state, filterRows: rows, ...updatePaginationData(rows, 0) });
    }, [rows.length]);

    const handleActionClick = (action, eachRow) => {
        if (action === "edit") {
            navigate(
                `/manage/coefficient-deviation?entitytype=${entityType}&entityid=${entityId}&action=1&id=${eachRow.id}`
            );
        }
    };

    const handleSearch = (search) => {
        let filterRows = [];
        let { searchCode = "", searchName = "" } = state;
        if (search && (searchCode || searchName)) {
            filterRows = rows.filter((item) => {
                let status = true;
                if (searchName)
                    status =
                        `${item["perfcode_name"]}`
                            .toLowerCase()
                            .toString()
                            .indexOf(searchName.toLowerCase().toString()) !== -1;
                if (status && searchCode)
                    status =
                        `${item["perfcode_code"]}`
                            .toLowerCase()
                            .toString()
                            .indexOf(searchCode.toLowerCase().toString()) !== -1;
                return status;
            });
        } else {
            filterRows = rows;
            searchCode = "";
            searchName = "";
        }
        setState({
            ...state,
            searchName,
            searchCode,
            filterRows: filterRows,
            currentPage: 0,
            itemOffset: 0,
            ...updatePaginationData(filterRows, 0),
        });
    };

    // ------------------- Pagination code -------------------------//
    useEffect(() => {
        setState({
            ...state,
            ...updatePaginationData(state.filterRows, state.itemOffset || 0),
        });
    }, [state.itemOffset]);

    const updatePaginationData = (filterRows, offset) => {
        let items = [...filterRows];
        const endOffset = offset + itemsPerPage;
        return {
            currentItems: items.slice(offset, endOffset),
            pageCount: Math.ceil(items.length / itemsPerPage),
        };
    };

    const handlePageClick = (event) => {
        let items = [...state.filterRows];
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setState({ ...state, itemOffset: newOffset, currentPage: event.selected });
    };

    const getNeededActions = (eachRow) => {
        return (
            <>
                {/* DESKTOP */}
                <div className="d-none d-md-none d-lg-block">
                    {[
                        USER_ROLE_ENTITY_TYPE.ABSOLUTE_YOU_ADMIN,
                        USER_ROLE_ENTITY_TYPE.SALES_AGENT,
                    ].includes(entityType) && (
                            <span
                                title={"Edit"}
                                className="actions-span text-dark"
                                onClick={() => handleActionClick("edit", eachRow)}
                            >
                                {" "}
                                <MdEdit />{" "}
                            </span>
                        )}
                </div>
                {/* IPAD */}
                <div className="d-block d-md-block d-lg-none row text-center">
                    {[
                        USER_ROLE_ENTITY_TYPE.ABSOLUTE_YOU_ADMIN,
                        USER_ROLE_ENTITY_TYPE.SALES_AGENT,
                    ].includes(entityType) && (
                            <span
                                title={"Edit"}
                                className="actions-span text-dark"
                                onClick={() => handleActionClick("edit", eachRow)}
                            >
                                <button
                                    type="button"
                                    name="Edit"
                                    className="btn-design-sm text-uppercase mb-3"
                                >
                                    {" "}
                                    {t("Edit")}
                                </button>
                            </span>
                        )}
                </div>
            </>
        );
    };

    return (
        <div className="row">
            <div className="position-sticky-coefficient-deviation position-static-mobile pb-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8 col-xxl-9 col-md-12">
                                <div className="row">
                                    {[
                                        { value: "searchCode", label: t("Coefficient code") },
                                        { value: "searchName", label: t("Coefficient name") },
                                    ].map((key, index) => {
                                        if (
                                            (entityType == 2 && key.label === "employer") ||
                                            (entityType === 3 && key.label !== "Document")
                                        )
                                            return;
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    entityType === 3
                                                        ? "col-md-12"
                                                        : entityType === 2
                                                            ? "col-md-4"
                                                            : "col-lg-6 col-md-12"
                                                }
                                            >
                                                <input
                                                    type="text"
                                                    value={state[key.value]}
                                                    className="form-control mt-2 mb-2 input-border-lightgray poppins-regular-18px mh-50 rounded-0 shadow-none"
                                                    onChange={(e) =>
                                                        setState({ ...state, [key.value]: e.target.value })
                                                    }
                                                    placeholder={t(`${key.label}`)}
                                                    onKeyUp={(e) =>
                                                        e.key === "Enter" ? handleSearch(1) : null
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div
                                className={
                                    entityType === 3
                                        ? "col-lg-3 col-md-7"
                                        : entityType === 2
                                            ? "col-md-3"
                                            : "col-lg-5 col-xl-4 col-xxl-3 col-md-7 ms-auto"
                                }
                            >
                                <div className="row">
                                    <div className="col-md-6 back-btn-mobile pe-md-0">
                                        <button
                                            type="button"
                                            className="btn btn-block border-0 rounded-0 float-right mt-2 mb-2 skyblue-bg-color w-100 shadow-none px-md-0 text-uppercase"
                                            onClick={() => handleSearch(1)}
                                        >
                                            {t("SEARCH")}
                                        </button>
                                    </div>
                                    <div className="col-md-6 back-btn-mobile">
                                        <button
                                            type="button"
                                            className="btn border-0 btn-block rounded-0 float-right mt-2 mb-2 reset_skyblue_button w-100 shadow-none text-uppercase px-0"
                                            onClick={() => handleSearch(0)}
                                        >
                                            {t("RESET")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-render-parent-div min-height-add-doc min-height-auto manage-coefficitent-deviation-data">
                <table
                    className={
                        entityType === 3
                            ? "table pwa table-hover manage-types-table manage-documents-table-header additional-document-table-design my_documents_position_sticky_table"
                            : entityType === 2
                                ? "table pwa table-hover manage-types-table manage-documents-table-header additional-document-table-design my_documents_position_sticky_table employer-docs-table"
                                : "table pwa table-hover manage-types-table manage-documents-table-header additional-document-table-design my_documents_position_sticky_table"
                    }
                >
                    <thead className="table-render-thead">
                        <tr key={"header-row-tr"}>
                            {headers.map((eachHeader, index) => (
                                <th
                                    key={`tablecol${index}`}
                                    className="align-middle "
                                    scope="col"
                                >
                                    {" "}
                                    {t(eachHeader)}{" "}
                                </th>
                            ))}{" "}
                        </tr>
                    </thead>
                    {state.currentItems && state.currentItems.length > 0 ? (
                        <tbody>
                            {state.currentItems.map((eachRow) => (
                                <tr
                                    key={eachRow.id}
                                    id={eachRow.id}
                                    className="box-shadow-sm mb-4"
                                >
                                    <td className="ps-lg-4" data-label="Code">
                                        {" "}
                                        {eachRow.perfcode_code}{" "}
                                    </td>
                                    <td data-label="Name"> {eachRow.perfcode_name} </td>
                                    <td className="color-skyblue-icon mt-2 text-center p-2">
                                        {getNeededActions(eachRow)}{" "}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody className="no-record-data">
                            <tr>
                                <td
                                    colSpan={8}
                                    className="text-center poppins-regular-18px no-records no-record-planning"
                                >
                                    {t("No records")}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
            <div>
                {state.filterRows.length > itemsPerPage && (
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<AiOutlineArrowRight />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        pageCount={state.pageCount}
                        forcePage={state.currentPage}
                        previousLabel={<AiOutlineArrowLeft />}
                        renderOnZeroPageCount={null}
                        // pageRangeDisplayed={1}
                        containerClassName={"pagination"}
                        itemClass="page-item"
                        linkClass="page-link"
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                )}
                <div className="row mt-3 mb-2">
                    <div className="col-md-12">
                        {/* <button
                            onClick={() => router.push("/manage")}
                            type="button"
                            className="btn poppins-light-18px text-decoration-underline shadow-none p-0 text-uppercase"
                        >
                            {t(`Back`)}
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default translate(CoefficientOverview);
