import React, { ReactNode } from "react";
import Image from "../common/atoms/Image";
// import { t } from "../common/atoms/translation";
import LoadImage from "../../static/images/DummyImage.jpg";
import TimePicker from "../common/atoms/TimePicker";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Icon from "../../Icon";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import { read } from "fs";
import { create } from "domain";

interface PlanningTableProps {
  children?: ReactNode;
  page?: string;
}
const PlanningTable: React.FC<PlanningTableProps> = ({
  children, page = 'default',
}) => {
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  return (
    <>
      <table className="table planningTableWrapper table-borderless">
        <thead className="positionSticky">
          <tr>
            <th></th>
            <th>{t("Employee")}</th>
            <th>{t("Status")}</th>
            <th>{t("Function")}</th>
            <th>{t("Paritair committee")}</th>
            <th>{t("Hourly wage")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Break Total")}</th>
            <th>{t("Total work done")}</th>
            <th>{t("Action")}</th>
          </tr>
          <tr className="spacing-row bg-transparent">
            <td
              colSpan={20}
              className="bg-transparent rounded-0 p-0"
              style={{ height: "1vw" }}
            ></td>
          </tr>
        </thead>
        <tbody>
          {/* Data */}
          <tr>
            <td>
              <Image
                src={LoadImage}
                imageWidth="3vw"
                imageHeight="3vw"
                imageBorderRadius="50%"
              />
            </td>
            <td>Camille V.</td>
            <td>Bediende </td>
            <td>Winkelbediende CATII</td>
            <td> PC201 </td>
            <td>€12,50</td>
            <td>
              <TimePicker
                setHourMin={() => { }}
                type={""}
                index={0}
                value={""}
                hour={""}
                minute={""}
              />
            </td>
            <td>
              <TimePicker
                setHourMin={() => { }}
                type={""}
                index={0}
                value={""}
                hour={""}
                minute={""}
              />
            </td>
            <td>
              <TimePicker
                setHourMin={() => { }}
                type={""}
                index={0}
                value={""}
                hour={""}
                minute={""}
              />
            </td>
            <td>
              <TimePicker
                setHourMin={() => { }}
                type={""}
                index={0}
                value={""}
                hour={""}
                minute={""}
              />
            </td>
            <td>
              <div className="totalHours text-white poppins-medium">00:00</div>
            </td>
            <td>
              <div className="totalHours text-white poppins-medium">00:00</div>
            </td>
            <td className="actionWrapper">
              <div>
                {/* clone icon */}
                {page == 'dashboard' &&
                  <AccessControl
                    renderNoAccess={false}
                    requiredPermissions={[
                      {
                        permission: "Planning time registration",
                        read: true,
                        create:true,
                        update:true,
                        delete:true,
                      },
                    ]}
                  >
                    <>
                      <AccessControl
                        renderNoAccess={false}
                        requiredPermissions={[
                          {
                            permission: "Check in",
                            read: true
                          },
                        ]}
                      >
                        <span
                          data-bs-toggle="tooltip"
                          title={t("Start")}
                          onClick={() => { }}
                          className="me-2"
                        >
                          <Icon name="startIcon" {...IconProps} />
                        </span>
                      </AccessControl>
                      <AccessControl
                        renderNoAccess={false}
                        requiredPermissions={[
                          {
                            permission: "Break in",
                            read: true
                          },
                        ]}
                      >
                        <span
                          data-bs-toggle="tooltip"
                          title={t("Pause")}
                          onClick={() => { }}
                          className="me-2"
                        >
                          <Icon name="pauseIcon" {...IconProps} />
                        </span>
                      </AccessControl>
                      <AccessControl
                        renderNoAccess={false}
                        requiredPermissions={[
                          {
                            permission: "Check out",
                            read: true
                          },
                        ]}
                      >
                        <span
                          data-bs-toggle="tooltip"
                          title={t("Stop")}
                          onClick={() => { }}
                          className="me-2"
                        >
                          {/* } */}
                          <Icon name="stopIcon" {...IconProps} />
                        </span>
                      </AccessControl>

                    </>
                  </AccessControl>

                }
                <span
                  data-bs-toggle="tooltip"
                  title={t("Clone")}
                  onClick={() => { }}
                  className="me-2"
                >
                  <Icon name="CloneIcon" {...IconProps} />
                </span>
                {/* edit icon */}
                <span
                  data-bs-toggle="tooltip"
                  title={t("Edit")}
                  onClick={() => { }}
                  className="me-2"
                >
                  <Icon name="EditIcon" {...IconProps} />
                </span>
                {/* remove icon */}
                <span
                  data-bs-toggle="tooltip"
                  title={t("Remove")}
                  onClick={() => { }}
                >
                  <Icon name="RemoveIcon" {...IconProps} />
                </span>
              </div>
            </td>
          </tr>
          {/* for space between rows */}
          <tr>
            <td
              colSpan={15}
              style={{ height: "0.7vw" }}
              className="emptyRow p-0 bg-transparent"
            ></td>
          </tr>
        </tbody>
      </table >
    </>
  );
};

export default translate(PlanningTable);
