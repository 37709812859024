import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import CheckBoxField from "../common/atoms/CheckBoxField";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import LeftPart from "./LeftPart";
import ParitiarCommitteeSection from "./ParitairCommiteeSection";
import RightPart from "./RightPart";
import Navigation from "./Navigation";
const AbsoluteyouAgent = () => {
  const [isLastTab, setIsLastTab] = useState(false);
  const handleNext = () => {
    console.log("Next clicked");
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };

  return (
    <>
      <div className="row">
        <div className="col-4 position-relative agreementStartDate">
          <Calender onChange={() => {}} label={t("Start date of agreement")} />
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={`${t("AbsoluteYou consultant")}:`}
            title={t("AbsoluteYou consultant")}
            name="absoluteYouConsultant"
            placeHolder={t("Select")}
            search
            options={[]}
            value={() => {}}
            onChange={() => {}}
            isMulti={false}
            className="select-field"
            error=""
            isTranslate
          />
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={`${t("AbsoluteYou office number")}:`}
            title={t("AbsoluteYou office number")}
            name="absoluteYouOfficeNumber"
            placeHolder={t("Select")}
            search
            options={[]}
            value={() => {}}
            onChange={() => {}}
            isMulti={false}
            className="select-field"
            error=""
            isTranslate
          />
        </div>
        <div className="col-12 my-3">
          <CheckBoxField
            name="activateAddProject"
            label={
              t("Activate 'Add project' for the employer in the planning") + "."
            }
            className="my-checkbox"
            isChecked={false}
            onChangeHandler={() => {}}
            id="activateAddProject"
            lineHeight="1vw"
          />
        </div>
        <div className="col-6">
          <div>
            <LabelField
              title={
                t("In which language Werkpostfiche should be present") + "?"
              }
            />
          </div>
          <CheckBoxField
            name="langDutch"
            label={t("Dutch")}
            className="my-checkbox"
            isChecked={false}
            onChangeHandler={() => {}}
            id="langDutch"
            lineHeight="1vw"
            checkBoxWrapper="me-4 mt-2 mb-3"
          />
          <CheckBoxField
            name="langFrench"
            label={t("French")}
            className="my-checkbox"
            isChecked={false}
            onChangeHandler={() => {}}
            id="langFrench"
            lineHeight="1vw"
            checkBoxWrapper="me-4 mt-2 mb-3"
          />
        </div>
        <div className="col-6">
          <div>
            <LabelField title={t("Who will sign the Werkpostfiche") + "?"} />
          </div>
          <CheckBoxField
            name="employer"
            label={t("Employer")}
            className="my-checkbox"
            isChecked={false}
            onChangeHandler={() => {}}
            id="employer"
            lineHeight="1vw"
            checkBoxWrapper="me-4 mt-2 mb-3"
          />
          <CheckBoxField
            name="coEmployer"
            label={t("Co-employer")}
            className="my-checkbox"
            isChecked={false}
            onChangeHandler={() => {}}
            id="coEmployer"
            lineHeight="1vw"
            checkBoxWrapper="me-4 mt-2 mb-3"
          />
        </div>
        <div className="col-6 multiSelectWrapper">
          <ParitiarCommitteeSection
            optionsList={[]}
            selectedValue={null}
            selectedEmpType={null}
            handlePCFields={() => {}}
            state={undefined}
            addParitiarComitee={() => {}}
            pcChangeHandler={() => {}}
            pcDeleteHandler={() => {}}
            employeeTypes={[]}
            isBlueCollar={true}
            isWhiteCollar={false}
          />
        </div>
        <div className="col-6 multiSelectWrapper">
          <ParitiarCommitteeSection
            optionsList={[]}
            selectedValue={null}
            selectedEmpType={null}
            handlePCFields={() => {}}
            state={undefined}
            addParitiarComitee={() => {}}
            pcChangeHandler={() => {}}
            pcDeleteHandler={() => {}}
            employeeTypes={[]}
            isBlueCollar={false}
            isWhiteCollar={true}
          />
        </div>
        <div className="col-12 absoluteAgentTable mt-4">
          <div className="row">
            <div className="col-4 pe-0">
              <LeftPart />
            </div>
            <div className="col-8 ps-0">
              <RightPart />
            </div>
          </div>
        </div>
      </div>
      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </>
  );
};

export default AbsoluteyouAgent;
