import React from 'react';
import { t, translate } from "../../components/CentralDataMangement/translation/Translation";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../features/auth/AuthSlice';
import { CANDIDATE_ROLE } from '../../Constants';

const AccessDenied: React.FC = () => {
  const userAuth = useSelector(selectAuth);

  return (
    <div className='row accedDeniedWrapper'>
      <div className="col-12">
        <div className='contentBg'>
          <div className="access-denied-code">403</div>
          <div className="access-denied-title pb-4">
            {t("Access denied")}
          </div>
          <div className="access-denied-content mb-5">
            {t("Oops! You can't access the page you are looking for")}
          </div>
          {userAuth.role === CANDIDATE_ROLE ? (
              <Link to='/' className="form-button rounded-3 p-3 page-not-found-back-link">{t("Go to home page")}</Link>
          ):(
            <Link to='/dashboard' className="form-button rounded-3 p-3 page-not-found-back-link">{t("Go to dashboard")}</Link> )
          }
        </div>
      </div>
    </div>
  )
}

export default translate(AccessDenied);