import React, { useState, ChangeEvent } from "react";

interface InputTextAreaFieldProps {
  className?: string;
  value?: string;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  rows?: number | undefined;
  error?:string
  id?: string;
}

const style: React.CSSProperties = {};

export default function InputTextAreaField({
  className = "form-control field-shadow rounded-3 select-field text-blue",
  value = "",
  isDisabled = false,
  placeholder = "",
  customStyle = {},
  handleChange = () => {},
  name = "",
  rows = 4, // Set a default value for rows
  error,
  id,
}: InputTextAreaFieldProps) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
    <textarea
      style={{ ...style, ...customStyle }}
      className={className}
      disabled={isDisabled}
      placeholder={isFocused ? "" : placeholder}
      name={name}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      rows={rows}
      id={id}
    />
    <div className="mb-2 mt-2">
      {error && <span className="text-danger mt-2">{error}</span>}
    </div>
    </>
  );
}
