import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EMPLOYEE_TYPES } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import CheckBoxField from "../../common/atoms/CheckBoxField";
import CustomNotify from "../../common/atoms/CustomNotify";
import LabelField from "../../common/atoms/LabelField";
import { t } from "../../common/atoms/translation";
import Header from "../../common/layout/Header";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";

export default function EmployeeTypeEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [employee, setEmployee] = useState({
    id: "",
    name: "",
    status: 1,
    type: "",
    day_contract: "",
  });

  const [error, setError] = useState("");

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await ApiCall.getService(
          EMPLOYEE_TYPES + "/" + id,
          "GET",
          "central-data-management"
        );

        if (response.success === true) {
          const data = response.data[0];
          setEmployee({
            id: data.id,
            name: data.name,
            status: 1,
            type: data.type,
            day_contract: data.day_contract,
          });
        } else {
          console.error("Failed to fetch the employee type.");
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching the employee type:",
          error
        );
      }
    };

    fetchEmployeeData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setEmployee((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!employee.name.trim()) {
      setError("Employee type cannot be empty.");
      return;
    }
    setError("");

    try {
      const response = await ApiCall.service(
        EMPLOYEE_TYPES + "/" + id,
        "PUT",
        {
          id: employee.id,
          name: employee.name,
          status: employee.status,
          type: employee.type,
          day_contract: employee.day_contract,
        },
        false,
        "central-data-management"
      );
      if (response.success === true) {
        navigate("/manage/employee-types");
      } else {
        CustomNotify({ type: "warning", message: t(response.message[0]) });
        console.error("Failed to save the employee type.");
      }
    } catch (error) {
      console.error("An error occurred while saving the employee type:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Edit employee type")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/manage/employee-types" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-4">
              <LabelWithInputField
                isMandatory={true}
                name="name"
                handleChange={handleChange}
                value={employee.name}
                id={`name-${employee.name}`}
                label={t("Name")}
                placeholder={t("Name")}
                type="text"
                error={error}
              />
            </div>
            <div className="col-4">
              <div>
                <LabelField title={t("Job type")} />
              </div>
              <select
                id="type"
                name="type"
                value={employee.type}
                onChange={handleChange}
                className="form-control form-select field-shadow inputFieldColor shadow-none functionCategoryField ps-2"
              >
                <option value="1">{t("White Collar")}</option>
                <option value="2">{t("Blue Collar")}</option>
              </select>
            </div>
            <div className="col-4 seachBtnWrapper">
              <input
                type="checkbox"
                name="day_contract"
                checked={employee.day_contract === 1}
                onChange={handleChange}
                className="form-check-input"
              />
              <LabelField title={t("Day contract")} />
            </div>
            <div className="col-12 text-end mt-4">
              <Button
                type="submit"
                className="searchBtn shadow-none px-3"
                title={t("Save Changes")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
