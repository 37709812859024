import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import CheckBoxField from "../common/atoms/CheckBoxField";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";

const CompanyInformation = () => {
  const [isLastTab, setIsLastTab] = useState(false);
  const handleNext = () => {
    console.log("Next clicked");
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };
  // Define common properties for LabelWithInputField
  const inputFields = [
    { label: "VAT number", name: "vatNumber", id: "vatNumber" },
    {
      label: "Official legal company name",
      name: "legalName",
      id: "legalName",
    },
    { label: "Company call name", name: "companyName", id: "companyName" },
    {
      label: "Company client number",
      name: "clientNumber",
      id: "clientNumber",
    },
    { label: "RSZ-number", name: "rszno", id: "rszno" },
    { label: "Legal address: Street", name: "streetName", id: "streetName" },
    { label: "Legal address: House number", name: "houseNo", id: "houseNo" },
    { label: "Legal address: Box number", name: "boxNo", id: "boxNo" },
    {
      label: "Legal address: Postal code",
      name: "postalCode",
      id: "postalCode",
    },
    { label: "Legal address: City", name: "city", id: "city" },
    { label: "Legal address: Phone number", name: "phoneNo", id: "phoneNo" },
    { label: "Legal address: Fax number", name: "faxNo", id: "faxNo" },
    { label: "Legal address: Email", name: "email", id: "email" },
    { label: "Website", name: "website", id: "website" },
  ];

  return (
    <>
      <div className="row">
        {inputFields.map((field) => (
          <div key={field.id} className="col-6 mb-3">
            <LabelWithInputField
              type={field.id === "email" ? "email" : "text"}
              label={t(field.label)}
              value=""
              name={field.name}
              id={field.id}
              handleChange={() => {}}
              error=""
              className="ps-2"
            />
          </div>
        ))}

        <div className="col-6 multiSelectWrapper mb-3">
          <SelectWithSearch
            fieldLabel={`${t("Type of company")}:`}
            title={t("Type of company")}
            name="companyType"
            placeHolder={t("Select")}
            search
            options={[]}
            value={() => {}}
            onChange={() => {}}
            isMulti={false}
            className="select-field"
            error=""
            isTranslate
          />
        </div>

        <div className="col-6 mb-3">
          <LabelWithInputField
            type="text"
            label={t("Legal address: Country")}
            value=""
            name="country"
            id="country"
            handleChange={() => {}}
            error=""
            className="ps-2"
          />
        </div>

        <div className="col-6 mb-3">
          <LabelWithInputField
            type="text"
            label={t("Browsers")}
            value=""
            name="browsers"
            id="browsers"
            handleChange={() => {}}
            error=""
            className="ps-2"
          />
        </div>

        <div className="col-6">
          <div className="mb-2">
            <LabelField title={t("Language")} />
          </div>
          <RadioField
            name="lang"
            label={t("Dutch")}
            className="radioBtn"
            ischecked={false}
            handleChange={() => {}}
            id="langDutch"
            containerClassName="companyLanguage me-4"
          />
          <RadioField
            name="lang"
            label={t("French")}
            className="radioBtn"
            ischecked={false}
            handleChange={() => {}}
            id="langFrench"
            containerClassName="companyLanguage"
          />
        </div>

        <div className="col-6">
          <div className="mb-2">
            <LabelField title={t("Labour regulations (arbeidsreglement)")} />
          </div>
          <RadioField
            name="labourRegulations"
            label={t("Yes")}
            className="radioBtn"
            ischecked={false}
            handleChange={() => {}}
            id="yesLabourRegulations"
            containerClassName="companyLanguage me-4"
          />
          <RadioField
            name="labourRegulations"
            label={t("No")}
            className="radioBtn"
            ischecked={false}
            handleChange={() => {}}
            id="noLabourRegulations"
            containerClassName="companyLanguage"
          />
        </div>

        <div className="col-6">
          <div className="mb-2">
            <LabelField
              title={t("Labour regulations (arbeidsreglement) - sharing")}
            />
          </div>
          <RadioField
            name="sharingLabourRegulations"
            label={t("Yes")}
            className="radioBtn"
            ischecked={false}
            handleChange={() => {}}
            id="yesSharingLabourRegulations"
            containerClassName="companyLanguage me-4"
          />
          <RadioField
            name="sharingLabourRegulations"
            label={t("No")}
            className="radioBtn"
            ischecked={false}
            handleChange={() => {}}
            id="noSharingLabourRegulations"
            containerClassName="companyLanguage"
          />
        </div>
      </div>
      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </>
  );
};

export default CompanyInformation;
