import React from 'react';
import ReactLoading from 'react-loading';

interface Props {
    iconType?: any,
    color?: string,
    height?: number,
    width?: number,
    className?: string
}

const LoadingIcon: React.FC<Props> = ({
    iconType = "bars",
    color = "#8571b9",
    width = 64,
    className='m-auto',
    height=40
}) => {

    return <>
        <ReactLoading type={iconType} color={color} className={className} width={width} height={height}/>
    </>
}

export default LoadingIcon;