import React, { useState } from "react";
// import { t } from "../common/atoms/translation";
import LabelField from "../common/atoms/LabelField";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import ToggleButton from "../common/atoms/ToggleButton";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import CheckBoxField from "../common/atoms/CheckBoxField";
import { icons } from "../Dashboard/WelcomeDashboard";
import Image from "../common/atoms/Image";
import { t  , translate} from "../CentralDataMangement/translation/Translation";


type OptionType = { label: string; value: string };

const EmployeeOthers = () => {
  const [pcValue, setPcValue] = useState<OptionType[] | null>(null);
  const [functionValue, setFunctionValue] = useState<OptionType[] | null>(null);
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());

  const handlePcChange = (selectedOption: OptionType[] | null) => {
    setPcValue(selectedOption);
  };

  const handleFunctionChange = (selectedOption: OptionType[] | null) => {
    setFunctionValue(selectedOption);
  };

  const handleCheckBoxClick = (index: number) => {
    setCheckedItems(prev => {
      const updated = new Set(prev);
      if (updated.has(index)) {
        updated.delete(index);
      } else {
        updated.add(index);
      }
      return updated;
    });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <LabelField
            title={t("To which company would you like to add this employee")}
            className="tab-title"
          />
        </div>
        <div className="col-12 mb-3">
          {icons.map((icon, index) => (
            <div
              key={index}
              className={`checkBoxContainer ${checkedItems.has(index) ? 'border-class' : ''} d-inline-block`} // Apply border conditionally
              onClick={() => handleCheckBoxClick(index)} // Handle click
            >
              <CheckBoxField
                name="addemployee"
                value="0"
                id={`company-${index}`}
                lineHeight="25px"
                className="checkBoxLabel"
                checkBoxWrapper="addEmployeeToCompany me-4 pe-3"
                wrapperClass="d-inline-flex"
                isChildren
              >
                <div data-bs-toggle="tooltip" title={icon.title}>
                  <Image
                    src={icon.src}
                    imageWidth="3vw"
                    imageHeight="3vw"
                    imageBorderRadius="50%"
                  />
                </div>
              </CheckBoxField>
            </div>
          ))}
        </div>
        <div className="col-12">
          <LabelField
            title={t("Function & hourly wage")}
            className="tab-title"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={`${t("Status")}:`}
              title={t("Status")}
              name="Status"
              placeHolder={t("Select")}
              isMandatory
              search
              options={[]}
              value={() => {}}
              onChange={() => {}}
              isMulti={false}
              className="select-field"
              error=""
              isTranslate
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={`${t("Paritair committee")}:`}
              title={t("Paritair committee")}
              name="pc"
              placeHolder={t("Select")}
              isMandatory
              search
              options={[
                { label: t("Committee A"), value: "committee_a" },
                { label: t("Committee B"), value: "committee_b" },
                { label: t("Committee C"), value: "committee_c" },
              ]}
              value={pcValue}
              onChange={handlePcChange}
              isMulti={true}
              className="select-field"
              error=""
              isTranslate
              labelWrapperClassName="col-4"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={`${t("Function")}:`}
              title={t("Function")}
              name="function"
              placeHolder={t("Select")}
              isMandatory
              search
              options={[
                { label: t("function A"), value: "function_a" },
                { label: t("function B"), value: "function_b" },
                { label: t("function C"), value: "function_c" },
              ]}
              value={functionValue}
              onChange={handleFunctionChange}
              isMulti={true}
              className="select-field"
              error=""
              isTranslate
              labelWrapperClassName="col-4"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={`${t("Minimum wage")}:`}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value=""
              name=""
              id="wage"
              labelWrapperClassName="col-4 align-self-center"
            >
              <span className="colorPrimary helpText">
                *{t("If flexi excl holiday pay")}
              </span>
            </LabelWithInputField>
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={`${t("Actual wage")}:`}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value=""
              name=""
              id="wage"
              labelWrapperClassName="col-4 align-self-center"
            >
              <span className="colorPrimary helpText">
                *{t("If flexi excl holiday pay")}
              </span>
            </LabelWithInputField>
          </div>
        </div>
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t("I agree to the GDPR data")}
            className="mb-0 me-3 colorPrimary poppins-medium"
          />
          <ToggleButton />
        </div>
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t("The temporary employment agency and the candidate temporary worker confirm the intention to enter into one or more employment contract(s) for temporary work.Letter of intent")}
            className="mb-0 me-3 colorPrimary poppins-medium"
          />
          <ToggleButton />
        </div>
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t("I agree to digitally sign contracts")}
            className="mb-0 me-3 colorPrimary poppins-medium"
          />
          <ToggleButton />
        </div>
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={t("I agree to the Terms and Conditions")}
            className="mb-0 me-3 colorPrimary poppins-medium"
          />
          <ToggleButton />
        </div>
      </div>
    </>
  );
};

export default EmployeeOthers;
