import React, { useState } from "react";
import WeekNumbers from "react-calendar/dist/cjs/MonthView/WeekNumbers";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import DateAndTime from "../common/atoms/DateWithTime";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";

const ProcessedTimeRecords = () => {
  const [date, setDate] = useState(new Date());

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };
  return (
    <>
      <Header
        headerName={t("Time registration")}
        headerClassName="myAccountCardTitle"
      />
      <TableHeaderWithIcon
        title={t("Location Name")}
        TableHeaderWithIconClassName="plannedLocationHeader px-3"
        TableHeaderTitle="poppins-semibold text-white"
      />
      <div
        className="contentBg rounded-top-0 p-3"
        style={{ height: "calc(100vh - 11vw" }}
      >
        <div className="row">
          <div className="col-12 align-self-center text-center">
            <DateAndTime
              isWeek={true}
              isIcon={true}
              monthFormat="long"
              dataTimeWrapperClassName="d-inline-flex align-items-center justify-content-center timeRegistrationDateWrapper"
              dataTimeClassName="mx-3 planningPlannedDate"
              dayClassName="me-1"
              isWeekDate={true}
              iconFillColor="#8571b9"
              onDateChange={handleDateChange}
            />
          </div>
          <div
            style={{ height: "calc(100vh - 15vw", fontSize: "1.6vw" }}
            className="d-flex align-items-center justify-content-center colorPrimary poppins-medium processedWeekNumber"
          >
            {t("All open time records for ")}
            <q
              className="mx-2 poppins-medium d-flex align-items-center"
              style={{ fontSize: "1.6vw" }}
            >
              <DateAndTime
                isWeek={true}
                dayClassName="poppins-medium weekNumber"
              />
            </q>
            {t("have been processed")}
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(ProcessedTimeRecords);
