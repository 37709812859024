import React from "react";
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
import { useSelector } from "react-redux";
import { selectEmployees } from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";

interface SelectedEmployeesNameCardProps {
  currentEmployeeIndex?: number; // Current index of the selected employee
  onEmployeeSelect?: (index: number) => void; // Pass index of the selected employee
}

const SelectedEmployeesNameCard: React.FC<SelectedEmployeesNameCardProps> = ({ currentEmployeeIndex, onEmployeeSelect }) => {
  const selectedEmployees = useSelector(selectEmployees);

  const handleButtonClick = (index: number) => {
    if (onEmployeeSelect) {
      onEmployeeSelect(index); // Notify parent component with the selected employee index
    }
  };

  return (
    <div className="selectedEmployees">
      <div className="row">
        <div className="col-11">
          <div className="d-grid">
            {selectedEmployees.map((employee: any, index: number) => (
              <Button
                key={employee.employee_id}
                handleClick={() => handleButtonClick(index)} // Pass the index
                className={`planningEmployeeButton ${index === currentEmployeeIndex ? "activeEmployee" : "inActiveEmployee"}`}
              >
                {employee.employee_name}
              </Button>
            ))}
          </div>
        </div>
        <div className="col-1 searchIconWrapper">
          <Icon
            name="searchIcon"
            fillColor="#8571b9"
            width="0.8vw"
            height="0.8vw"
            className="ms-3"
            isIcon={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <div className="addSelectedEmployee d-grid">
            <div></div>
            <div className="text-center">
              <Icon
                name="add"
                fillColor="#8571b9"
                width="0.6vw"
                height="0.6vw"
                className="ms-3 cursor-pointer"
                isIcon={true}
                onClick={() => { }}
              />
            </div>
            <div className="align-self-center">
              <div className="selectedNumberOfEmployees text-end">
                {t("Number selected") + ": " + selectedEmployees.length}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(SelectedEmployeesNameCard);
