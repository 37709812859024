import React, { useEffect, useState } from "react";
// import { t } from "../common/atoms/translation";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import SelectedEmployeesNameCard from "./SelectedEmployeesNameCard";
import ShiftTiming from "./ShiftTiming";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import "./css/planning.css";
import { useDispatch } from "react-redux";
import { resetAllData, resetShow, selectEmployees, setShow, setTab } from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { useSelector } from "react-redux";
import EmpployeeShiftTable from "./EmpployeeShiftTable";

const PlanningOverview = () => {

  // State to control the visibility of the modal
  const [showModal, setShowModal] = useState(true);
  // State to track the index of the currently selected employee
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number | null>(0); // Default to 0 if there's at least one employee
  // Get selected employees from Redux store
  const selectedEmployees = useSelector(selectEmployees);
  // State to store the currently active employee
  const [activeEmployee, setActiveEmployee] = useState(selectedEmployees[0]);
  // Get selected dates from Redux store
  const dispatch = useDispatch();

  // Handler to close the modal and reset all data
  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetAllData());
  };

  // Effect to update the active employee based on the current index
  useEffect(() => {
    currentEmployeeIndex !== null ? setActiveEmployee(selectedEmployees[currentEmployeeIndex]) : setActiveEmployee(selectedEmployees[0]);
  }, [currentEmployeeIndex]);

  const handleBackClick = () => {
    console.log("Setting tab to tab_2"); // Debugging log
    dispatch(setTab('tab_2'));
  };
  return (
    <>
      <ModalPopup
        modalTitle={t("Planning overview")}
        show={true}
        handleClose={handleCloseModal}
        modalBackgroundColor="#8571b9"
        className="planningModal planningOverviewModal"
        buttonText={t("Send all")}
        showBackIcon={true}
        handleBack={handleBackClick}
      >
        <Title
          title={t("Overview of the entered planning") + ":"}
          className="bodyTitle colorPrimary mb-2"
        />
        <SelectedEmployeesNameCard
          currentEmployeeIndex={currentEmployeeIndex}
          onEmployeeSelect={(index: number) => setCurrentEmployeeIndex(index)}
        />
        <div className="row mb-3">
          <div className="col-3 align-self-center ">
            <EmployeeDetailsCard
              cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center"
              employeeInformationClass="planningEmployeeDetails w-75"
              employeeName={activeEmployee.employee_name}
              employeePosition={activeEmployee.employee_type}
              employeeLocation={activeEmployee.location}
              employeeRole={activeEmployee.role}
              employeePC={activeEmployee.pc}
              employeeSalary={activeEmployee.salary}
              employeeImage={activeEmployee.image}
            />
          </div>
          <div className="col-9 align-self-center">
            <EmpployeeShiftTable
              onEmployeeSelected={activeEmployee}
              page={'overview'}
            />
          </div>
        </div>
      </ModalPopup>
    </>
  );
};

export default translate(PlanningOverview);
