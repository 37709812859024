// src/components/AddCompanyModal.tsx
import React from "react";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import Image from "../common/atoms/Image";
import InputTextFieldWithLabel from "../common/molecules/InputTextFieldWithLabel";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import userIcon from "../../static/images/User.svg";
import { t } from "../common/atoms/translation";
import Button from "../common/atoms/Button";
import InputTextfield from "../common/atoms/InputTextField";

type OptionType = { label: string; value: string };

interface AddCompanyModalProps {
    show: boolean;
    handleClose: () => void;
    pcValue: OptionType[] | null;
    handlePcChange: (selectedOption: OptionType[] | null) => void;
}

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({ show, handleClose, pcValue, handlePcChange }) => {
    const inputFieldSharedProps = {
        isTop: false,
        labelClassName: "label colorPrimary",
        colClassName: "col-6",
        className: "inputFieldColor",
    };

    return (
        <ModalPopup
            show={show}
            handleClose={handleClose}
            modalTitle={t("Add company")}
            modalBackgroundColor="#8571b9"
            className="addCompanyModal"
            buttonText={t("Add")}
        >
            <div className="companyData">
                {/* Add company */}
                <div className="row">
                    <div className="col-12">
                        <Title
                            title={t("Company data") + ":"}
                            className="bodyTitle colorPrimary"
                        />
                        <div className="mt-3">
                            <div className="addCompanyImage d-flex align-items-center justify-content-center">
                                <Image
                                    src={userIcon}
                                    imageWidth="3vw"
                                    imageHeight="3vw"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row mb-2">
                                <div className="col-3 align-self-center">
                                    <LabelField
                                        title={t("VAT number") + ":"}
                                        className="label colorPrimary"
                                    />
                                </div>
                                <div className="col-6">
                                    <InputTextfield
                                        type="text"
                                        value={""}
                                        handleChange={() => { }}
                                        name={"vatNumber"}
                                        id="vatNumber"
                                        {...inputFieldSharedProps}
                                    />
                                </div>
                                <div className="col-3">
                                    <Button
                                        handleClick={() => { }}
                                        title={t("Verify")}
                                        className="modalSaveBtn"
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Company name") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"companyName"}
                                    id="companyName"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Official name") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"companyName"}
                                    id="companyName"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("First name") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"companyName"}
                                    id="companyName"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Last name") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"companyName"}
                                    id="companyName"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Email address") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"emailId"}
                                    id="emailId"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Phone number") + ":"}
                                    type="tel"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"phoneNo"}
                                    id="phoneNo"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row">
                                <LabelField
                                    title={t("Address registered office") + ":"}
                                    className="bodyTitle colorPrimary my-3"
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Street name") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"streetName"}
                                    id="streetName"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("House no") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"houseNo"}
                                    id="houseNo"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Postal code") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"postalCode"}
                                    id="postalCode"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2 multiSelectWrapper">
                                <SelectWithSearch
                                    fieldLabel={`${t("City")}:`}
                                    title={t("City")}
                                    name="Status"
                                    placeHolder={t("Select")}
                                    search
                                    options={[]}
                                    value={() => { }}
                                    onChange={() => { }}
                                    isMulti={false}
                                    className="select-field"
                                    error=""
                                    isTranslate
                                    labelWrapperClassName="col-3 align-self-center"
                                    colClassName="col-6"
                                />
                            </div>
                            <div className="row mb-2 multiSelectWrapper">
                                <SelectWithSearch
                                    fieldLabel={`${t("Country")}:`}
                                    title={t("Country")}
                                    name="Status"
                                    placeHolder={t("Select")}
                                    search
                                    options={[]}
                                    value={() => { }}
                                    onChange={() => { }}
                                    isMulti={false}
                                    className="select-field"
                                    error=""
                                    isTranslate
                                    labelWrapperClassName="col-3 align-self-center"
                                    colClassName="col-6"
                                />
                            </div>

                            <div className="row">
                                <LabelField
                                    title={
                                        t(
                                            "Is the registered office the same as the address of employment"
                                        ) + "?"
                                    }
                                    className="bodyTitle colorPrimary mt-3"
                                />
                            </div>
                            <div className="row">
                                <LabelField
                                    title={t("Employement address") + ":"}
                                    className="bodyTitle colorPrimary my-3"
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Street name") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"streetName"}
                                    id="streetName"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("House no") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"houseNo"}
                                    id="houseNo"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2">
                                <InputTextFieldWithLabel
                                    label={t("Postal code") + ":"}
                                    type="text"
                                    value={""}
                                    handleChange={() => { }}
                                    name={"postalCode"}
                                    id="postalCode"
                                    {...inputFieldSharedProps}
                                />
                            </div>
                            <div className="row mb-2 multiSelectWrapper">
                                <SelectWithSearch
                                    fieldLabel={`${t("City")}:`}
                                    title={t("City")}
                                    name="Status"
                                    placeHolder={t("Select")}
                                    search
                                    options={[]}
                                    value={() => { }}
                                    onChange={() => { }}
                                    isMulti={false}
                                    className="select-field"
                                    error=""
                                    isTranslate
                                    labelWrapperClassName="col-3 align-self-center"
                                    colClassName="col-6"
                                />
                            </div>
                            <div className="row mb-2 multiSelectWrapper">
                                <SelectWithSearch
                                    fieldLabel={`${t("Country")}:`}
                                    title={t("Country")}
                                    name="Status"
                                    placeHolder={t("Select")}
                                    search
                                    options={[]}
                                    value={() => { }}
                                    onChange={() => { }}
                                    isMulti={false}
                                    className="select-field"
                                    error=""
                                    isTranslate
                                    labelWrapperClassName="col-3 align-self-center"
                                    colClassName="col-6"
                                />
                            </div>
                            <div className="row">
                                <LabelField
                                    title={t("Sector") + ":"}
                                    className="bodyTitle colorPrimary my-3"
                                />
                            </div>
                            <div className="row multiSelectWrapper">
                                <SelectWithSearch
                                    fieldLabel={`${t("Paritair committee")}:`}
                                    title={t("Paritair committee")}
                                    name="pc"
                                    placeHolder={t("Select")}
                                    search
                                    options={[
                                        { label: t("Committee A"), value: "committee_a" },
                                        { label: t("Committee B"), value: "committee_b" },
                                        { label: t("Committee C"), value: "committee_c" },
                                    ]}
                                    value={pcValue}
                                    onChange={handlePcChange}
                                    isMulti={true}
                                    className="select-field"
                                    error=""
                                    isTranslate
                                    labelWrapperClassName="col-3"
                                    colClassName="col-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalPopup>
    );
};

export default AddCompanyModal;
