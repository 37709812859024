import React from "react";
import Icon from "../../Icon";
import { t } from "../common/atoms/Constants";

interface dataProps {
  data: object[];
  pcChangeHandler: (value: any, type: string) => void;
  pcDeleteHandler: (value: any, type: string) => void;
  type: string;
}

const PCTable: React.FC<dataProps> = ({
  data,
  pcChangeHandler,
  type,
  pcDeleteHandler,
}) => {
  return (
    <>
      {data && data.length > 0 && (
        <div className="col-lg-12 pwa">
          <table className="table table-hover general-agreement-table">
            <thead>
              <tr className="TableHeader bg-white">
                <th className="ps-lg-4">
                  {t("Paritair committee")} {type}
                </th>
                <th>{t("Employee types")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => (
                <tr
                  key={index}
                  className="border-bottom mb-3 box-shadow align-middle"
                >
                  <td
                    className="text-break ps-lg-4"
                    data-label={t("Paritair committee")}
                  >
                    {t(item.PC.label)}
                  </td>
                  <td className="text-break" data-label={t("Employee types")}>
                    {item.employeeType.map((empType: any, index: number) =>
                      index === 0 ? t(empType.label) : `, ${t(empType.label)}`
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => pcChangeHandler(index, type)}
                      className="secondaryColorHoverEffect me-2"
                      title={t("Edit")}
                    >
                      <Icon
                        isIcon={true}
                        name="EditIcon"
                        width="1vw"
                        height="1vw"
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => pcDeleteHandler(index, type)}
                      className="secondaryColorHoverEffect"
                      title={t("Delete")}
                    >
                      <Icon
                        isIcon={true}
                        name="delete"
                        width="1vw"
                        height="1vw"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PCTable;
