import React from "react";
import Image from "../common/atoms/Image";
import LoadImage from "../../static/images/DummyImage.jpg";
import Icon from "../../Icon";
import "./css/weekCalender.css";
// import { t } from "../common/atoms/translation";
import {t , translate } from "../CentralDataMangement/translation/Translation";
interface EventData {
  image: string;
  name: string;
  pc: string;
  function: string;
  actions: string;
  timing: string;
}

interface WeeklyCalendarProps {
  startDate?: Date;
  data?: { [key: string]: EventData }; // Updated data format
}

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({ startDate, data }) => {
  const getWeekDays = (startDate: Date) => {
    const days = [];
    const weekStart = new Date(startDate);
    weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Adjust to start on Sunday
    for (let i = 0; i < 7; i++) {
      days.push(new Date(weekStart));
      weekStart.setDate(weekStart.getDate() + 1);
    }
    return days;
  };

  const weekDays = getWeekDays(startDate || new Date());

  // Get today's date
  const today = new Date();
  const currentDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const IconPrps = {
    isIcon: true,
    width: "0.8vw",
    height: "0.8vw",
    cursor: "pointer",
  };
  return (
    <div className="d-grid">
      <div className="weekHeader">
        <div className="weekHeaderRow d-grid">
          {weekDays.map((day, index) => {
            const isToday = day.toDateString() === currentDay.toDateString();
            return (
              <div
                key={index}
                className={`weekDay text-center ${isToday ? "currentDay" : ""}`}
              >
                <div className="dayName">
                  {day.toLocaleDateString("en-US", { weekday: "long" })}
                </div>
                <div className="dayDate">
                  {day.getDate()}{" "}
                  {day.toLocaleDateString("en-US", { month: "long" })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="weekData d-grid">
        {weekDays.map((day, index) => {
          const dateKey = day.toISOString().split("T")[0]; // Format date as 'YYYY-MM-DD'
          const dayData = data?.[dateKey]; // Get data for the specific day

          return (
          <>
            <div key={index} className="dayDataItem">
              {/* {dayData ? ( */}
              <div className="dayDataContent">
                <div className="row">
                  <div className="col-10 pe-0">
                    <div className="row">
                      <div className="col-3 pe-0">
                        <Image
                          src={LoadImage}
                          imageWidth="2vw"
                          imageHeight="2vw"
                          imageBorderRadius="50%"
                        />
                      </div>
                      <div className="col-9">
                        <div className="planningDetails colorPrimary">
                          <div className="eventName">Camille V.</div>
                          <div className="eventStatus">Bediende</div>
                          <div className="eventFunction">
                            Winkelbediende CATII{" "}
                          </div>
                          <div className="eventPC"> PC201 | €12,50</div>
                          <div className="eventLocation"> Vestiging 1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 align-self-center">
                    <div
                      data-bs-toggle="tooltip"
                      title={t("Clone")}
                      onClick={() => {}}
                    >
                      <Icon name="CloneIcon" {...IconPrps} />
                    </div>
                    <div
                      data-bs-toggle="tooltip"
                      title={t("Edit")}
                      onClick={() => {}}
                    >
                      <Icon name="EditIcon" {...IconPrps} />
                    </div>
                    <div
                      data-bs-toggle="tooltip"
                      title={t("Delete")}
                      onClick={() => {}}
                    >
                      <Icon name="RemoveIcon" {...IconPrps} />
                    </div>
                  </div>
                </div>
                <div className="row weeklyPlannedTimingWrapper">
                  <div className="col-8">
                    <div className="row">
                      <div className="col-6 pe-0">
                        <div className="plannedTiming">06:00</div>
                      </div>
                      <div className="col-6 pe-0">
                        <div className="plannedTiming">08:00</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="plannedOverallTiming">08:00</div>
                  </div>
                </div>
              </div>
              {/* ) : ( */}
              <div className="dayDataContent"></div>
              {/* )} */}
            </div>
          </>
          );
        })}
      </div>
    </div>
  );
};

export default translate(WeeklyCalendar);
