import React, { MouseEventHandler } from "react";
import { t } from "../../CentralDataMangement/translation/Translation";
import Button from "./Button";

interface props {
  handleSearchClick?: MouseEventHandler<HTMLButtonElement>;
}
const SearchBtn:React.FC<props> = ({
  handleSearchClick
}) => {
  return (
    <Button
      title={t("Search")}
      type="button"
      handleClick={handleSearchClick}
      className="searchBtn"
    />
  );
};

export default SearchBtn;
