import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import AuthReducer from "../features/auth/AuthSlice";
import planningReducer from '../features/planning/planningSlice';
import encryptTransform from "./encryptTransform";
// Configuration object for Redux Persist
const persistConfig = {   
  key: "root", // The key for the persisted state in storage
  storage, // The storage engine to use (e.g., localStorage or AsyncStorage)
  transforms: [encryptTransform], // Optional: transforms to apply to the persisted state (e.g., encryption)
  blacklist: ['planning'] // Add this lin
};

// Creating a persisted reducer using Redux Persist
const persistedReducer = persistReducer(
  persistConfig, // The persist configuration
  combineReducers({ // Combine reducers for different parts of your state
    auth: AuthReducer, // Add the AuthReducer for authentication state
    planning:planningReducer
    // Add reducers for other parts of your state if needed 
  })
);

// Configuring the Redux store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer, // The root reducer with persistence capabilities
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore specific action types to avoid serialization warnings
        ignoredActions: ["persist/PERSIST"], // Ignore persist actions
      },
    }),
});

// Creating the persistor object, which will be used to persist the store
export const persistor = persistStore(store);

// Type for the root state of the Redux store
export type RootState = ReturnType<typeof store.getState>;

// Type for the dispatch function of the Redux store
export type AppDispatch = typeof store.dispatch;

// Exporting the configured Redux store
export default store;
