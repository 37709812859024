import React, { Suspense, useContext } from "react";
import LinkCoeffEmpStates from "./LinkCoeffEmp/LinkCoeffEmpStates";
import LinkCoeffEmpComponent from "./LinkCoeffEmpComponent";
import { t, translate } from '../../../CentralDataMangement/translation/Translation';
import { useNavigate, useLocation, useParams } from 'react-router-dom';


const LinkCoefficientEmployeetype = (props) => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const pcid = query.get('pcid') ?? 0;

    if (pcid !== undefined)
        return (
            <LinkCoeffEmpStates>
                <Suspense fallback={`Loading...`}>
                    <LinkCoeffEmpComponent pcid={pcid} />
                </Suspense>
            </LinkCoeffEmpStates>
        );
    else return <> </>;
};

export default translate(LinkCoefficientEmployeetype);