import React, { useState } from "react";
import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import DateAndTime from "../common/atoms/DateWithTime";
import PlanningTable from "../Planning/PlanningTable";
import PlanningTabs from "../Planning/PlanningTabs";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import DashboardPlannings from "../Planning/DashboardPlannings";
import DashboardAccordion from "../common/atoms/DashboardAccordion";

const LocationDashboard = () => {
  const show = useSelector(selectPlanningShow); // Access the `show` state

  const locations = [
    {
      title: "Location 1",
      id: 1,
    },
    {
      title: "Location 2",
      id: 2,
    },
    {
      title: "Location 3",
      id: 3,
    },
  ];

  // State to track which accordion is expanded
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(null);

  const handleToggle = (id: string) => {
    setExpandedAccordion((prev) => (prev === id ? null : id)); // Toggle between expand/collapse
  };

  return (
    <>
      <div className="container-fluid">
        <div className="col-12 ">
          <Header headerName={t("Dashboard")} />
        </div>
        {locations.map((location) => (
          <div className="headerWrapper mb-2" key={location.id}>
            <DashboardAccordion
              accordionTitle={t(location.title)}
              accordionTitleClassName="w-100 text-center poppins-medium dashboardAccordionTitle"
              accordionButton="dashboardAccordionHeader shadow-none"
              id={location.id.toString()}
              expanded={expandedAccordion === location.id.toString()}
              onToggle={handleToggle}
            >
              {/* This is children */}
              <>
                <DashboardPlannings headerName={t("Dashboard")} type={"day"} page="dashboard" location={location}>
                  <DateAndTime
                    isDate={true}
                    showDate={true}
                    showMonth={true}
                    showYear={true}
                    isDay={true}
                    isIcon={true}
                    monthFormat="long"
                    dataTimeWrapperClassName="d-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
                    dataTimeClassName="mx-3 planningPlannedDate"
                    dayClassName="me-1"
                    iconFillColor="#8571b9"
                  />
                </DashboardPlannings>
                <div className="row">
                  <div className="col-12">
                    <div className="dayPlanningOverviewTable">
                      <PlanningTable page="dashboard" />
                    </div>
                  </div>
                </div>
              </>
            </DashboardAccordion>
          </div>
        ))}
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default LocationDashboard;
