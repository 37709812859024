import React from "react";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";

function Popup(props) {
  return (
    <div
      className="modal"
      id="myModal"
      style={{ display: "block", background: "rgb(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered customModal">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title colorPrimary poppins-medium">
              {t("Delete category")}
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => props.popupActionNo()}
            />
          </div>

          <div className="modal-body">
            <p>{t("Are you sure you want to delete this category?")}</p>{" "}
            <p>
              {t("Functions related to this category will also be deleted.")}
            </p>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="resetBtn"
              onClick={() => props.popupActionNo()}
            >
              {t("No")}
            </button>
            <button
              className="searchBtn px-3"
              onClick={() => props.popupActionYes()}
            >
              {t("Yes")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default translate(Popup);
