import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { MdEdit, MdDelete } from 'react-icons/md';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { t, translate } from '../../translation/Translation';
import { ApiCall } from '../../../../services/ApiServices';
import { DELETE_COEFFICIENT_TYPE } from '../../../../routes/ApiEndpoints';
import { useNavigate } from "react-router-dom";

const itemsPerPage = 5;

const TableRendererCoefficientTypes = ({ headers, rows, manageType }) => {
  const [state, setState] = useState({
    searchTerm: '',
    deleteTitle: 'coefficient',
    filterRows: rows,
    searchKey: 'name',
    currentItems: [],
    pageCount: 0,
    itemOffset: 0,
    currentPage: 0,
    deleteUrl: DELETE_COEFFICIENT_TYPE,
  });

  const navigate = useNavigate();
  const basePath = '/manage/coefficient-types';

  useEffect(() => {
    setState((prevState) => ({ ...prevState, filterRows: rows }));
  }, [rows]);

  const getNeededActions = (eachRow) => (
    <>
      <div className='d-none d-lg-block'>
        <span title='Edit' className="actions-span text-dark" onClick={() => handleActionClick('edit', eachRow)}>
          <MdEdit className="color-skyblue" />
        </span>
        <span title='Delete' className="actions-span text-dark" onClick={() => handleActionClick('delete', eachRow)}>
          <MdDelete className="color-skyblue" />
        </span>
      </div>
      <div className='d-block d-lg-none text-center row'>
        <span title='Edit' className="actions-span text-dark" onClick={() => handleActionClick('edit', eachRow)}>
          <button type='button' className='btn-design-sm text-uppercase'>{t('Edit')}</button>
        </span>
        <span title='Delete' className="actions-span text-dark" onClick={() => handleActionClick('delete', eachRow)}>
          <button type='button' className='btn-design-sm-delete text-uppercase my-3'>{t('Delete')}</button>
        </span>
      </div>
    </>
  );

  const handleActionClick = (action, eachRow) => {
    if (action === 'delete') {
      confirmAlert({
        message: `${t('Do you want to delete the ')} ${state.deleteTitle}?`,
        buttons: [
          { label: t('No') },
          { label: t('Yes'), onClick: () => handleDelete(eachRow.id) },
        ],
      });
    } else {
      navigate(`${basePath}/${action}?id=${eachRow.id}`);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await ApiCall.service(`${DELETE_COEFFICIENT_TYPE}/${id}`, "DELETE");
      if (result.success) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (value) => {
    const filteredRows = rows.filter((item) =>
      item[state.searchKey].toLowerCase().includes(value.toLowerCase())
    );
    setState({
      ...state,
      searchTerm: value,
      filterRows: filteredRows,
      currentPage: 0,
      itemOffset: 0,
      ...updatePaginationData(filteredRows, 0),
    });
  };

  const handleSearchClick = (search = 0) => {
    handleSearch(search ? state.searchTerm : '');
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...updatePaginationData(state.filterRows, state.itemOffset || 0),
    }));
  }, [state.itemOffset]);

  const updatePaginationData = (filterRows, offset) => {
    const endOffset = offset + itemsPerPage;
    return {
      currentItems: filterRows.slice(offset, endOffset),
      pageCount: Math.ceil(filterRows.length / itemsPerPage),
    };
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % state.filterRows.length;
    setState((prevState) => ({
      ...prevState,
      itemOffset: newOffset,
      currentPage: event.selected,
    }));
  };

  const buttonTitle = 'Add coefficient';

  return (
    <>
      <div className='py-4'>
        <h4 className='font-weight-bold'>{t('Manage coefficients')}</h4>
      </div>
      <div className='col-md-12 text-end'>
        <button
          onClick={() => navigate(`${basePath}/add?id=0`)}
          type="button"
          className="btn btn-block mt-2 mb-2 border-0 rounded-0 ms-2 skyblue-bg-color py-2 px-3 shadow-none text-uppercase"
        >
          + {t(buttonTitle)}
        </button>
      </div>
      <div className='row m-0 pt-2 pb-3'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-lg-7'>
              <input
                type="text"
                value={state.searchTerm}
                className="form-control mt-2 mb-2 input-border-lightgray"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder={t('Coefficient')}
                onKeyUp={(e) => e.key === 'Enter' ? handleSearchClick(1) : null}
              />
            </div>
            <div className='col-lg-5'>
              <div className='row justify-content-end'>
                <div className='col-6'>
                  <button
                    type="button"
                    className="btn btn-block mt-2 mb-2 skyblue-bg-color w-100 shadow-none text-uppercase"
                    onClick={() => handleSearchClick(1)}
                  >
                    {t('SEARCH')}
                  </button>
                </div>
                <div className='col-6'>
                  <button
                    type="button"
                    className="btn btn-block mt-2 mb-2 reset_skyblue_button w-100 shadow-none px-0"
                    onClick={() => handleSearchClick(0)}
                  >
                    {t('RESET')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={`tablecol${index}`} scope="col">{t(header)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.currentItems.length > 0 ? (
              state.currentItems.map((eachRow) => (
                <tr key={eachRow.id}>
                  <td>{eachRow.name}</td>
                  <td className='text-end'>{getNeededActions(eachRow)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length} className='text-center'>{t('No records')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {state.filterRows.length > itemsPerPage && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={<AiOutlineArrowRight />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={state.pageCount}
          forcePage={state.currentPage}
          previousLabel={<AiOutlineArrowLeft />}
          containerClassName="pagination justify-content-center"
          activeClassName="active"
        />
      )}
    </>
  );
};

export default translate(TableRendererCoefficientTypes);
