import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Icon";
import { EMPLOYEE_TYPES } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import CheckBoxField from "../../common/atoms/CheckBoxField";
import CustomNotify from "../../common/atoms/CustomNotify";
import LabelField from "../../common/atoms/LabelField";
import { t } from "../../common/atoms/translation";
import Header from "../../common/layout/Header";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import OverFlowWrapper from "../../common/OverFlowWrapper";

export default function EmployeeTypeAdd() {
  const [employees, setEmployees] = useState([
    { name: "", day_contract: 0, type: "", status: 1 },
  ]);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    const updatedEmployees = [...employees];
    updatedEmployees[index][name] =
      type === "checkbox" ? (checked ? 1 : 0) : value || "";
    setEmployees(updatedEmployees);
  };

  const addEmployeeType = () => {
    setEmployees([
      ...employees,
      { name: "", day_contract: 0, type: "", status: 1 },
    ]);
  };

  const removeEmployeeType = (index) => {
    const updatedEmployees = employees.filter((_, i) => i !== index);
    setEmployees(updatedEmployees);
  };

  const validateFields = () => {
    const errors = {};
    employees.forEach((employee, index) => {
      if (!employee.name) {
        errors[`name-${index}`] = t("Employee type name is required");
      }
      if (!employee.type) {
        errors[`type-${index}`] = t("Job type is required");
      }
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const sendData = async () => {
    try {
      const response = await ApiCall.service(
        EMPLOYEE_TYPES,
        "POST",
        { data: employees },
        false,
        "central-data-management"
      );

      if (response.success) {
        navigate("/manage/employee-types");
      } else {
        CustomNotify({ type: "warning", message: t(response.message[0]) });
        console.error("Failed to store the employee types.");
      }
    } catch (error) {
      console.error(
        "An error occurred while storing the employee type:",
        error
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateFields()) {
      sendData();
    }
  };

  return (
    <>
      <Header
        headerName={t("Add employee types")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/manage/employee-types" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <OverFlowWrapper className="addEmployeeType">
            {employees.map((employee, index) => (
              <div className="row mb-3">
                <div className="col-4">
                  <LabelWithInputField
                    isMandatory={true}
                    name="name"
                    handleChange={(event) => handleChange(index, event)}
                    value={employee.name}
                    id={`name-${index}`}
                    label={t("Name")}
                    placeholder={t("Name")}
                    type="text"
                    error={error[`name-${index}`]}
                  />
                </div>
                <div className="col-4">
                  <div>
                    <LabelField title={t("Job type")} />
                  </div>
                  <select
                    id={`type-${index}`}
                    name="type"
                    value={employee.type}
                    onChange={(event) => handleChange(index, event)}
                    className="form-control form-select field-shadow inputFieldColor shadow-none functionCategoryField"
                  >
                    <option value="">{t("Select Job Type")}</option>
                    <option value="1">{t("White Collar")}</option>
                    <option value="2">{t("Blue Collar")}</option>
                  </select>
                  {error[`type-${index}`] && (
                    <span className="text-danger">
                      {error[`type-${index}`]}
                    </span>
                  )}
                </div>
                <div className="col-3 seachBtnWrapper ps-4">
                  <CheckBoxField
                    name={employee.day_contract}
                    value={employee.day_contract}
                    isChecked={employee.day_contract === 1}
                    onChangeHandler={(event) => handleChange(index, event)}
                    label={t("Day contract")}
                    checkBoxWrapper="ps-0"
                    error={error[`day_contract-${index}`]}
                  />
                </div>
                <div className="col-1" style={{ marginTop: "1.6vw" }}>
                  {employees.length - 1 === index && (
                    <Button
                      type="button"
                      handleClick={addEmployeeType}
                      className="saveBtn px-2 py-1"
                    >
                      <span className="" title={t("Add employee type")}>
                        <Icon
                          isIcon={true}
                          width="1vw"
                          height="1vw"
                          name="add"
                          fillColor="#fff"
                        />
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </OverFlowWrapper>
          <div className="row  pt-3">
            <div className="col-12 text-end">
              <Button
                type="submit"
                className="searchBtn px-3"
                handleClick={() => { }}
                title={t("Save")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
