import React, { ReactNode } from "react";

interface AccordionCustomProps {
  accordionTitle?: string;
  children?: ReactNode;
  accordionTitleClassName?: string;
  accordionButton?: string;
  id: string; // Added `id` to uniquely identify each accordion
  expanded: boolean; // Whether the accordion is expanded or not
  onToggle: (id: string) => void; // Function to handle the toggle action
}

const DashboardAccordion: React.FC<AccordionCustomProps> = ({
  accordionTitle,
  children,
  accordionTitleClassName,
  accordionButton,
  id,
  expanded,
  onToggle,
}) => {
  return (
    <>
      <div className="accordion accordion-flush" id={`accordionFlush${id}`}>
        <div className="accordion-item">
          <h2 className="accordion-header" id={`flush-heading-${id}`}>
            <button
              className={`${accordionButton} accordion-button ${expanded ? '' : 'collapsed'}`}
              type="button"
              onClick={() => onToggle(id)}
              aria-expanded={expanded ? "true" : "false"}
              aria-controls={`flush-collapse-${id}`}
            >
              <div className={accordionTitleClassName}>{accordionTitle}</div>
            </button>
          </h2>
          <div
            id={`flush-collapse-${id}`}
            className={`accordion-collapse collapse ${expanded ? 'show' : ''}`}
            aria-labelledby={`flush-heading-${id}`}
            data-bs-parent={`#accordionFlush${id}`}
          >
            <div className="accordion-body">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAccordion;
