import React, { useState, useEffect } from "react";
import {
  validateForm,
  validateRequired,
  validateSelectField,
  validateTokens,
} from "../../../services/ValidationService";
import Button from "../../common/atoms/Button";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import LabelWithSelectField from "../../common/molecules/LabelwithSelectField";
import LabelWithCKEditorField from "../../common/molecules/LabelWithCKEditorField";
import LabelWithTextAreaField from "../../common/molecules/LabelWithTextAreaField";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import CustomNotify from "../../common/atoms/CustomNotify";
import {
  UPDATE_TEMPLATE,
  GET_TEMPLATE,
  GET_APP_LANG,
  EMAIL_TEMPLATES,
} from "../../../routes/ApiEndpoints";
import { getLanguage, t, translate } from "../translation/Translation";
import Title from "../../common/atoms/Title";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import BackLink from "../../common/atoms/BackLink";
import LabelField from "../../common/atoms/LabelField";
import { ApiCall } from "../../../services/ApiServices";
import * as ROUTE from "../../../routes/RouteConstants";

interface TemplateProps {
  //Properties/ fields and there respective data type in email template form
  module?: string;
  name?: string;
  subject?: string;
  body?: string;
  signature?: string;
  bodyToken?: string;
  signatureToken?: string;
  lang?: string;
}

interface OptionProps {
  ///fields related to module dropdown and their datatype
  value?: number;
  name?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

interface Lang {
  key: string;
  name: string;
}

const EditEmailTemplate: React.FC = () => {
  //component
  const location = useLocation();
  const isViewMode = location.pathname.includes("view"); //to kno whether it is view mode based location
  const queryParams = new URLSearchParams(location.search);
  const langParam = queryParams.get("lang"); //To get value of lang parameter in url ?lang=..
  const [formData, setFormData] = useState<TemplateProps>({
    //form data with intial values
    module: "",
    name: "",
    subject: "",
    body: "",
    signature: "",
    bodyToken: "",
    signatureToken: "",
    lang: langParam ?? "",
  });
  const currentLang = getLanguage();
  const [langs, setLangs] = useState<Lang[]>([]); // Annotate the type of langs as an array of Lang objects
  const [errors, setErrors] = useState<TemplateProps>({}); //maintaining errors object
  const navigate = useNavigate();
  const [optionList, setOptionList] = useState<OptionProps[]>([]); //for module dropdown
  const [combinedContent, setCombinedContent] = useState<string>(""); //for view mode content
  const [contentChanged, setContentChanged] = useState(false);
  const [selectedLang, setSelectedLang] = useState(formData.lang || "en"); // Initialize with default language or formData's language

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    //functionality for edit flow and view
    if (id) {
      const fetchFormData = async () => {
        const editData = { id: id, language_key: langParam };

        // const langResponse = await ApiCall.getService(
        //   `${GET_APP_LANG}/absolute_jobs`,
        //   "GET",
        //   "central-data-management"
        // );
        // const keysArray = langResponse.app_langs.map((lang: any) => ({
        //   key: lang.key,
        //   name: lang.name,
        // }));
        // setLangs(keysArray);

        const response = await ApiCall.getService(
          `${EMAIL_TEMPLATES}/${id}`,
          "GET",
          "central-data-management"
        );
        if (response.status === 200) {
          const templateData = response.data["templateData"][0];

          const {
            name,
            body_token,
            signature_token,
            subject,
            body,
            signature,
          } = templateData;

          // Update the form state with the extracted data and tokens
          setFormData({
            name,
            subject,
            body,
            signature,
            bodyToken: body_token,
            signatureToken: signature_token,
            lang: langParam ?? "",
          });
        }
      };

      fetchFormData();
    }
  }, [id, langParam]);

  useEffect(() => {
    const { subject, body, signature } = formData;
    const combined = `<b>Subject</b> : ${subject} \n${body}\n${signature}`;
    setCombinedContent(combined);
  }, [formData]);

  const bodyTokens = (formData.bodyToken?.match(/\[\w+\]/g) ?? []) as string[]; //validation of body content for body tokens
  const signatureTokens = (formData.signatureToken?.match(/\[\w+\]/g) ??
    []) as string[]; //validation of signature content for signature tokens

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      // module: [validateSelectField],
      name: [validateRequired],
      subject: [validateRequired],
      body: [validateRequired, validateTokens(bodyTokens)],
      signature: [validateRequired, validateTokens(signatureTokens)],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    setFormData((prevData) => ({ ...prevData, [name]: content }));
    setContentChanged(true);
    if (contentChanged) {
      validation(name, content, true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    //api call for updating data
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = { id: id, ...formData };
    if (validation(name, value)) {
      const response = await ApiCall.service(
        `${EMAIL_TEMPLATES}/${id}`,
        "PUT",
        data,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        navigate("/manage-email-templates");
        CustomNotify({ type: "success", message: t(response.message) });
      }
    }
  };

  const languageLinks = langs.map((lang) => (
    <Link
      key={lang.key}
      to={{
        pathname: `/email-template/${isViewMode ? "view" : "edit"}/${id}`,
        search: `?lang=${lang.key}`,
      }}
      target="_blank"
      className={`dropdown-item ${selectedLang === lang.key ? "active" : ""}`}
      onClick={() => setSelectedLang(lang.key)}
    >
      {lang.name.toUpperCase()}
    </Link>
  ));

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Email templates",
          update: true,
        },
      ]}
      renderNoAccess={true}
    >
      {isViewMode ? (
        <Header
          headerName={t("View email template")}
          headerClassName="myAccountCardTitle"
        />
      ) : (
        <Header
          headerName={t("Edit email template")}
          headerClassName="myAccountCardTitle"
        />
      )}
      <div className="search-bar pb-3">
        <BackLink backLink={ROUTE.MANAGE_EMAIL_TEMPLATES} />
      </div>
      <div className="managepagesBg mb-4">
        <div className="row language-dropdown">
          <div className="col-md-12">
            {/* <div className="float-end">
            <div className="dropdown">
              <span className="me-3">{t("Select language for email template:")}</span>
              <button
                className="btn dropdown-toggle rounded-0 shadow-none"
                type="button"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedLang.toUpperCase()}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="languageDropdown"
              >
                {languageLinks}
              </div>
            </div>
          </div> */}
          </div>
        </div>
        {/*displaying common data for both view and edit mode */}
        <>
          {isViewMode ? ( //displaying data when its view mode
            <div className="email-preview">
              <LabelWithCKEditorField
                label={t("Preview email")}
                value={combinedContent ?? ""}
                isDisabled={true}
                name={""}
              />
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  {/* Name field */}
                  <LabelWithInputField
                    isMandatory={true}
                    name="name"
                    handleChange={changeHandler}
                    isDisabled={true}
                    value={formData.name}
                    id="name"
                    label={t("Name")}
                    placeholder={t("Name")}
                    type="text"
                    error={errors.name}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    {/* Body field */}
                    <LabelWithCKEditorField
                      label={t("Body")}
                      name="body"
                      value={formData.body ?? ""}
                      placeholder={t("Type here") + "..."}
                      handleChange={(event, editor) =>
                        handleCKEditorChange(event, editor, "body")
                      }
                      isMandatory={true}
                      error={errors.body}
                    />
                  </div>
                  <div className="col-lg-3 body-tokens">
                    <LabelWithTextAreaField
                      label={t("Body tokens")}
                      isMandatory={true}
                      name="bodyToken"
                      isDisabled={true}
                      value={
                        formData.bodyToken &&
                        Object.values(
                          JSON.parse(formData.bodyToken) as Record<
                            string,
                            string
                          >
                        ).join("\n")
                      }
                      handleChange={changeHandler}
                      rows={4}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    {/* Signature field */}
                    <LabelWithCKEditorField
                      label={t("Signature")}
                      name="signature"
                      value={formData.signature ?? ""}
                      placeholder={t("Type here") + "..."}
                      handleChange={(event, editor) =>
                        handleCKEditorChange(event, editor, "signature")
                      }
                      isMandatory={true}
                      error={errors.signature}
                    />
                  </div>
                  {/* Signature Token field */}

                  <div className="col-lg-3 signature-tokens">
                    <LabelWithTextAreaField
                      label={t("Signature tokens")}
                      isMandatory={true}
                      name="signatureToken"
                      isDisabled={true}
                      value={
                        formData.signatureToken &&
                        Object.values(
                          JSON.parse(formData.signatureToken) as Record<
                            string,
                            string
                          >
                        ).join("\n")
                      }
                      handleChange={changeHandler}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 ms-auto text-end">
                  <Button
                    title={t("Save email template")}
                    type="submit"
                    className="searchBtn px-3"
                  />
                </div>
              </div>
            </form>
          )}
        </>
      </div>
    </AccessControl>
  );
};

export default translate(EditEmailTemplate);
