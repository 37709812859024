import { t, translate } from "../../CentralDataMangement/translation/Translation";
import React, { ReactElement, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface CommonModalProps {
  size?: 'sm' | 'lg' | 'xl';
  show: boolean;
  onHide?: () => void;
  title: string;
  body: ReactNode | string;
  onCloseButtonClick?: () => void;
  onConfirmButtonClick?: (event?: any) => void;
  closeTitle?: string;
  confirmTitle?: string;
  className?: string;
  inputClass?: string;
  headerClassName?: string;
  showCloseButton?: boolean;
  isInnerHTML?: boolean;
}

const ConfirmPopup: React.FC<CommonModalProps> = ({
  size,
  show,
  onHide,
  title,
  body,
  onCloseButtonClick,
  onConfirmButtonClick,
  closeTitle,
  confirmTitle,
  className,
  inputClass,
  headerClassName,
  showCloseButton = true,
  isInnerHTML = false
}) => {
  return (
    <>
      <Modal size={size} show={show} onHide={onHide} centered className={`${className} customModal`}>
        <Modal.Header closeButton={showCloseButton} title={t("Close")} className={`modal-border ${headerClassName}`}>
          <Modal.Title className={`${inputClass} modalTitleConfirm`}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isInnerHTML &&
            <div>
              {body}
            </div>
          }
          {isInnerHTML && typeof body === 'string' && (
            <div dangerouslySetInnerHTML={{ __html: body }} />
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          {closeTitle && onCloseButtonClick && (
            <button
              onClick={onCloseButtonClick}
              className="resetBtn"
            >
              {closeTitle}
            </button>
          )}
          {confirmTitle && onConfirmButtonClick && (
            <button
              onClick={onConfirmButtonClick}
              className="searchBtn px-3"
            >
              {confirmTitle}
            </button>
          )}
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default translate(ConfirmPopup);
