import React from "react";
import Button from "../common/atoms/Button";
import { t } from "../CentralDataMangement/translation/Translation";

interface NavigationProps {
  isLastTab: boolean;
  onPrevious?: () => void; // Added for handling the Previous button
  onNext?: () => void;
  onSubmit?: () => void;
}

const Navigation: React.FC<NavigationProps> = ({
  isLastTab,
  onPrevious,
  onNext,
  onSubmit,
}) => {
  const handlePreviousClick = () => {
    if (onPrevious) {
      onPrevious();
    }
  };

  const handleNextClick = () => {
    if (onNext) {
      onNext();
    }
  };

  const handleSubmitClick = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <div className="row py-4">
      <div className="col-6">
        <Button
          handleClick={handlePreviousClick}
          title={t("Back")}
          className="searchBtn px-3"
        />
      </div>
      <div className="col-6 text-end">
        <Button
          handleClick={() => {}}
          title={t("Save as draft")}
          className="searchBtn px-3 me-3"
        />
        <Button
          handleClick={isLastTab ? handleSubmitClick : handleNextClick}
          title={isLastTab ? t("Submit") : t("Next")}
          className="searchBtn px-3"
        />
      </div>
    </div>
  );
};

export default Navigation;
