import React, { Suspense, memo } from "react";
import { useLocation } from "react-router-dom";
import ManageCoefficientDeviation from "./ManageCoefficientDeviation";
import { t, translate } from "../../../CentralDataMangement/translation/Translation";


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ManageCoefficientDeviationIndex = () => {
    const query = useQuery();
    const entitytype = query.get("entitytype") || "";
    const entityid = query.get("entityid") || "";
    const action = Number(query.get("action")) || 0;
    const id = query.get("id") || 0;

    return (
        <div>
            <Suspense fallback={`Loading...`}>
                <ManageCoefficientDeviation
                    entityType={entitytype}
                    entityId={entityid}
                    action={action}
                    editId={id}
                />
            </Suspense>
        </div>
    );
};

export default translate(ManageCoefficientDeviationIndex);
