import React from 'react';

interface TitleProps {
  color?: string;
  title?: string;
  className?: string | number;
  subTitle?: any;
  link?: string;
  linkName?:string;
  children?:React.ReactNode;
}

const CardTitle: React.FC<TitleProps> = ({
  color,
  className,
  title,
  subTitle,
  link,
  linkName,
  children,
}) => {
  const DashboardCardStyle: React.CSSProperties = {
    color,
  };

  return (
    <div style={DashboardCardStyle} className={`${className}`}>
      {title}
      {children}
      {subTitle &&
        <div>{subTitle}</div>
      }
      {link &&
        <a href={link}>{linkName}</a>
      } 
    </div>
  );
};

export default CardTitle;
