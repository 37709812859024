import React from "react";
import Icon from "../../../Icon";
import Image from "../atoms/Image";
import SubIcon from "../atoms/SubIcon";
import Title from "../atoms/Title";

interface IconWithTitleProps {
  name?: string;
  color?: string;
  width?: number | string;
  height?: number | string;
  title?: string;
  className?: string;
  labelClassName?: string;
  icon?: string; // This appears unused in the current code
  borderRadius?: string | number;
  subIconClassName?: string;
  subIconname?: string;
  src?: string;
  altName?: string;
  imageBorderRadius?: string;
  imageWidth?: string | number;
  imageHeight?: string | number;
  isImage?: boolean;
  isIcon?: boolean;
  isTitle?: boolean;
  isSubIcon?: boolean;
  imageWrapperClassName?: string;
  iconImageWrapperClassName?: string;
  titleImageWrapperClassName?: string;
  subIconImageWrapperClassName?: string;
  subIconWidth?: string | number;
  subIconHeight?: string | number;
  imageBackgroundColor?: string;
  iconBackgroundColor?: string;
  fillColor?: string;
  handleCompanyClick?: () => void

}

const IconWithTitle: React.FC<IconWithTitleProps> = ({
  name,
  color,
  width,
  height,
  title,
  className,
  labelClassName,
  borderRadius,
  subIconClassName,
  subIconname,
  src,
  altName,
  imageBorderRadius,
  imageWidth,
  imageHeight,
  isImage = false,
  isIcon = false,
  isTitle = false,
  isSubIcon = false,
  imageWrapperClassName,
  iconImageWrapperClassName,
  titleImageWrapperClassName,
  subIconImageWrapperClassName,
  subIconHeight,
  subIconWidth,
  imageBackgroundColor,
  iconBackgroundColor,
  fillColor,
  handleCompanyClick,
}) => {
  return (
    <>
      {isImage && (
        <div className={imageWrapperClassName} onClick={handleCompanyClick}>
          <Image
            src={src}
            altName={altName}
            imageBorderRadius={imageBorderRadius}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            imageBackgroundColor={imageBackgroundColor}
          />
        </div>
      )}
      {isIcon && (
        <div className={iconImageWrapperClassName}>
          <Icon
            name={name}
            color={color}
            width={width}
            height={height}
            className={className}
            borderRadius={borderRadius}
            iconBackgroundColor={iconBackgroundColor}
            fillColor={fillColor}
            isIcon={true}
          />
        </div>
      )}
      {isTitle && (
        <div className={titleImageWrapperClassName} onClick={handleCompanyClick}>
          <Title title={title} className={labelClassName} />
        </div>
      )}
      {isSubIcon && (
        <div className={subIconImageWrapperClassName}>
          <SubIcon
            subIconname={subIconname}
            color={color}
            subIconHeight={subIconHeight}
            subIconWidth={subIconWidth}
            className={subIconClassName}
            borderRadius={borderRadius}
          />
        </div>
      )}
    </>
  );
};

export default IconWithTitle;
