import React from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalendarIcon from "../atoms/CalendarIcon";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from '../utlis/dateHelper'; // Adjust the import path as needed
import {
  selectContractType,
  selectDates,
  setWeekNumber,
  setDates,
  addDate
} from "../../../features/planning/planningSlice";
import {
  startOfWeek,
  endOfWeek,
  getWeek,
  isWithinInterval,
  format // Import format from date-fns
} from "date-fns";

interface PlanningCalendarProps {
  isReactCalendar?: boolean;
  iscalendarSeperator?: boolean;
  customClassName?: string;

}

const PlanningCalendar: React.FC<PlanningCalendarProps> = ({
  isReactCalendar,
  iscalendarSeperator,
  customClassName,
}) => {
  const contractType = useSelector(selectContractType);
  const dates = useSelector(selectDates);
  const dispatch = useDispatch();

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (!Array.isArray(dates) || dates.length < 2) {
      return "";
    }

    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[1]);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return "";
    }

    if (
      contractType === "week" &&
      isWithinInterval(date, {
        start: startOfWeek(startDate, { weekStartsOn: 1 }),
        end: endOfWeek(startDate, { weekStartsOn: 1 })
      })
    ) {
      return "react-calendar_week_navigation";
    }

    return "";
  };


  // Set the value based on contract type
  const calendarValue = Array.isArray(dates) ? dates[0] : dates;

  function onChange(value: any, event: React.MouseEvent<HTMLButtonElement>) {
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      if (value.length === 2 && value[0] instanceof Date && value[1] instanceof Date) {
        // Handle date range selection
        const startDate = formatDate(value[0].toISOString()); // Format start date
        const endDate = formatDate(value[1].toISOString()); // Format end date

        dispatch(setDates([startDate, endDate]));
        dispatch(setWeekNumber(getWeek(value[0], { weekStartsOn: 1 })));
      } else {
        // Handle multiple single date selections (not a range)
        dispatch(setDates(value.map((date) => date instanceof Date ? formatDate(date.toISOString()) : "")));
      }
    } else if (value instanceof Date) {
      // Handle single date selection
      const formattedDate = formatDate(value.toISOString()); // Subtract one day for the start date
      dispatch(addDate(formattedDate));
      if (contractType === "day") {
        dispatch(setWeekNumber(getWeek(value, { weekStartsOn: 1 })));
      }
    }
  }


  function onClickWeekNumber(weekNumber: number, date: Date, event: React.MouseEvent<HTMLButtonElement>) {
    if (!(date instanceof Date)) {
      return;
    }

    // Calculate the start and end dates of the week
    const start = startOfWeek(date, { weekStartsOn: 1 }).toISOString();
    const end = endOfWeek(date, { weekStartsOn: 1 }).toISOString();

    if (contractType === "week") {
      // Dispatch the start and end dates for the week
      dispatch(setWeekNumber(weekNumber));
      dispatch(setDates([formatDate(start), formatDate(end)]));
    }

    if (contractType === "day") {
      // Dispatch only the start date if contract type is "day"
      dispatch(setDates([formatDate(start)]));
      dispatch(setWeekNumber(getWeek(new Date(start), { weekStartsOn: 1 })));
    }
  }



  return (
    <div className={`calendarWrapper ${customClassName}`}>
      <div>
        {
          iscalendarSeperator
          &&
          <CalendarIcon isCalendarIcon={true} />
        }

        {isReactCalendar && (
          <ReactCalendar
            onChange={(value, event) => onChange(value, event)}
            value={calendarValue}
            className={`mx-auto`}
            showWeekNumbers={true}
            onClickWeekNumber={(weekNumber, date, event) =>
              contractType === "week"
                ? onClickWeekNumber(weekNumber, date, event)
                : undefined
            } tileClassName={tileClassName}
          />
        )}
      </div>
    </div>
  );
};

export default PlanningCalendar;
