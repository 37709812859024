import React from "react";
import { t } from "../common/atoms/Constants";
import Header from "../common/layout/Header";
import { Tab, Tabs } from "react-bootstrap";
import "./css/Agreement.css";
import AbsoluteyouAgent from "./AbsoluteyouAgent";
import CompanyInformation from "./CompanyInformation";
import ContactPerson from "./ContactPerson";
import OnlineDetails from "./OnlineDetails";
import SalaryBenifits from "./SalaryBenefits";
import Invoicing from "./Invoicing";

// Data array for tabs
const tabData = [
  {
    eventKey: "absoluteyouAgent",
    step: "Step 1",
    title: "AbsoluteYou Agent",
    content: <AbsoluteyouAgent />,
  },
  {
    eventKey: "companyInformation",
    step: "Step 2",
    title: "Company Information",
    content: <CompanyInformation />,
  },
  {
    eventKey: "contactPersons",
    step: "Step 3",
    title: "Contact Persons",
    content: <ContactPerson />,
  },
  {
    eventKey: "onlineDetails",
    step: "Step 4",
    title: "Online Details",
    content: <OnlineDetails />,
  },
  {
    eventKey: "salaryBenefits",
    step: "Step 5",
    title: "Salary Benefits",
    content: <SalaryBenifits />,
  },
  {
    eventKey: "invoicing",
    step: "Step 6",
    title: "Invoicing",
    content: <Invoicing />,
  },
];

const AgreementForm: React.FC = () => {
  return (
    <div className="cooperationAgreementWrapper">
      <Header
        headerName={t("Edit cooperation agreement")}
        headerClassName="myAccountCardTitle"
      />
        <Tabs
          defaultActiveKey="absoluteyouAgent"
          transition={false}
          id="fill-tab-example"
          className="cooperationAgreementTabs border-0 p-3 bg-white"
          fill
        >
          {tabData.map((tab, index) => (
            <Tab
              className="p-3 bg-white"
              key={index}
              eventKey={tab.eventKey}
              style={{minHeight:"calc(100vh - 16vw)"}}
              title={
                <>
                  <div className="mb-2">{t(tab.step)}</div>
                  {t(tab.title)}
                </>
              }
            >
              {tab.content}
            </Tab>
          ))}
        </Tabs>
    </div>
  );
};

export default AgreementForm;
