import React, { ChangeEvent } from 'react';
import LabelField from '../atoms/LabelField';
import InputTextAreaField from '../atoms/InputTextAreaField';

interface LabelWithTextAreaFieldProps {
  label?: string,
  name: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMandatory?: boolean;
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number | undefined;
  error?: string;
  id?: string;
  className?:string;
}

const LabelWithTextAreaField: React.FC<LabelWithTextAreaFieldProps> = ({
  label,
  name,
  value,
  placeholder = '',
  isDisabled = false,
  isMandatory,
  handleChange,
  rows = 4,
  error,
  id,
  className
}) => {
  return (
    <div>
      <LabelField 
      title={label} 
      isMandatory={isMandatory}
      />
      <InputTextAreaField              
        name={name}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        handleChange={handleChange}
        rows={rows}
        error={error}
        id={id}
        className={className}
      />
    </div>
  );
};

export default LabelWithTextAreaField;
