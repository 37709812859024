import React, { useState, useRef, useContext, useEffect } from "react";
import {
  GET_SALARY_BENIFITS,
  STORE_SALARY_BENIFITS,
} from "../../../routes/ApiEndpoints";
import styles from "./Pc.module.css";
import MultiSelectField from "./MultiSelectField";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import { FaRegPlusSquare, FaRegMinusSquare } from "react-icons/fa";
import styles2 from "./SalaryBenefits.module.css";
import DateField from "./DateField";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Header from "../../common/layout/Header";
import BackLink from "../../common/atoms/BackLink";
import "./multiSelect.css";
import CheckBoxField from "../../common/atoms/CheckBoxField";
import LabelField from "../../common/atoms/LabelField";
import InputTextfield from "../../common/atoms/InputTextField";
import Icon from "../../../Icon";

const SalaryBenefits = (props) => {
  const [salaryBenefits, setSalaryBenefits] = useState([]);
  const [pc_unique_key, setpc_unique_key] = useState([]);

  const options = [
    { value: 0, label: "--Select--" },
    { value: 1, label: "Hour" },
    { value: 2, label: "Day" },
    { value: 3, label: "Week" },
    { value: 4, label: "Contract" },
  ];
  const [compState, setCompState] = useState({
    expand: {},
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const k = searchParams.get("k");
    setpc_unique_key(k);
    ApiCall.getService(
      GET_SALARY_BENIFITS + "/" + k,
      "GET",
      "central-data-management"
    )
      .then((result) => {
        if (result.data.length > 0) {
          console.log(result.data);
          setSalaryBenefits(result.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getOptionObj = (val) => {
    var V = "";
    options.forEach((data) => {
      if (data.value == val) {
        V = data;
      }
    });
    return V;
  };

  const onSelect = (e) => {
    if (e.length > 0) {
      let data = [...salaryBenefits];
      let index = data.findIndex((x) => x.sb_main_id === e[0].sb_main_id);
      data[index].selected = "1";
      setSalaryBenefits(data);
    }
  };

  const updateBenefitList = () => {
    let data = [...salaryBenefits];
    let dataArray = data.filter((object1) => {
      return object1["selected"] !== "1";
    });
    return dataArray;
  };
  const onDelete = (index) => {
    let data = [...salaryBenefits];
    data[index].selected = "3";
    setSalaryBenefits(data);
  };

  const expandMinimizeDiv = (sb_main_id) => {
    let expand = { ...compState.expand };
    expand[sb_main_id] = !expand[sb_main_id];
    setCompState({ ...compState, expand: expand });
  };
  const showEachBenifitDetails = (val, index) => {
    const { expand } = compState;
    return (
      <div className="basic_details">
        <div key={val.pc_id} className={`${styles2["expand-minimize-div"]}`}>
          <div className="mb-3 d-flex align-items-center">
            <span
              onClick={() => expandMinimizeDiv(val.sb_main_id)}
              title={expand[val.sb_main_id] === true ? t("Open") : t("Close")}
              className={`${styles2["expand-minimize-span"]} close_open_basic_details secondaryColorHoverEffect`}
              style={{ width: "3%" }}
            >
              {!expand[val.sb_main_id] ? (
                <FaRegMinusSquare />
              ) : (
                <FaRegPlusSquare />
              )}
            </span>
            <div
              onClick={() => expandMinimizeDiv(val.sb_main_id)}
              className={`${styles2["expand-minimize-box"]} inputFieldColor`}
              style={{ width: "94%" }}
            >
              <span className="colorPrimary ps-2"> {salaryBenefits[index].name} </span>
            </div>
            {props.type != "view" && (
              <span
                onClick={() => onDelete(index)}
                title={"Delete"}
                className={`actionWrapper text-center`}
                style={{ width: "3%" }}
              >
                <Icon isIcon={true} width="0.8vw" height="0.8vw" name="close" />
              </span>
            )}
          </div>
          {!expand[val.sb_main_id] ? (
            <div className={`${styles2["salay-content-div"]}`}>
              {showSalaryContent(index)}
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const updateValue = (index, value, type) => {
    var object = [...salaryBenefits];
    switch (type) {
      case "is_mandatory":
        object[index].is_mandatory = value === true ? true : false;
        break;
      case "is_sales_can_change":
        object[index].is_sales_can_change = value === true ? true : false;
        break;

      case "start_date":
        object[index].start_date = value;
        break;

      case "end_date":
        object[index].end_date = value;
        break;

      case "occurence":
        object[index].occurence = value;
        break;

      case "amount":
        object[index].amount = value;
        break;

      case "is_skip_absence":
        object[index].is_skip_absence = value;
        break;

      // case 'premium_value':
      //     object[index].premium_value = value;
      //     break;

      // case 'coefficient_type':
      //     object[index].coefficient_type = value;
      //     break;

      // case 'granted':
      //     object[index].granted = value;
      //     break;

      default:
        break;
    }
    setSalaryBenefits(object);
  };
  const validation = () => {
    return true;
  };

  const Submit = (e) => {
    e.preventDefault();
    validation()
      ? ApiCall.service(
          STORE_SALARY_BENIFITS + "/" + pc_unique_key,
          "POST",
          { data: salaryBenefits },
          false,
          "central-data-management"
        )
          .then((res) => {
            if (res.status == 200) {
              navigate("/manage-pc");
            }
          })
          .catch((error) => {})
      : console.log("false validation");
  };

  const showSalaryContent = (index) => {
    let salaryDataArray = salaryBenefits[index] || [];
    let tableHtmlContent = [];
    tableHtmlContent.push(
      <div className={`mb-3 rounded-3 border p-3`} style={{width:"94%", margin:"auto"}}>
        <div className="row">
          {/* is_mandatory */}

          <div className={"col-3 mb-3"}>
            <CheckBoxField
              name={"is_mandatory" + salaryDataArray.sb_main_id}
              label={t("Is this mandatory") + "?"}
              className="my-checkbox"
              checked={salaryDataArray.is_mandatory === true ? true : false}
              onChangeHandler={(e) => {
                updateValue(index, e.target.checked, "is_mandatory");
              }}
              id="activateAddProject"
              lineHeight="1vw"
              disabled={props.type == "view"}
            />
          </div>

          {/* is_sales_can_change */}
          <div className={"col-9 mb-3"}>
            <CheckBoxField
              name={"sales_agent" + salaryDataArray.sb_main_id}
              label={
                t(
                  "Allow sales agent to update the value during creation of cooperation agreement"
                ) + "?"
              }
              className="my-checkbox"
              checked={
                salaryDataArray.is_sales_can_change == true ? true : false
              }
              onChangeHandler={(e) => {
                updateValue(index, e.target.checked, "is_sales_can_change");
              }}
              id="activateAddProject"
              lineHeight="1vw"
              disabled={props.type == "view"}
            />
          </div>

          {/* start date */}
          <div className="col-4">
            <LabelField title={t("Start date")} />
            <DateField
              id={"date"}
              placeholder={"date"}
              handleChange={(e) => {
                updateValue(index, e.target.value, "start_date");
              }}
              style={{ marginLeft: "0.8rem" }}
              className="date_field_salary_benefits"
              value={
                salaryDataArray.selected == "0"
                  ? new Date()
                  : salaryDataArray.start_date
              }
              isDisabled={props.type == "view"}
            />
            <p style={{ color: "red" }} className="error">
              {salaryDataArray.date_err}
            </p>
          </div>

          {/* end date */}
          <div className="col-4">
            <LabelField title={t("End date")} />

            <DateField
              id={"date"}
              placeholder={"date"}
              handleChange={(e) => {
                updateValue(index, e.target.value, "end_date");
              }}
              style={{ marginLeft: "0.8rem" }}
              className="date_field_salary_benefits"
              value={
                salaryDataArray.selected == "0"
                  ? new Date()
                  : salaryDataArray.end_date
              }
              isDisabled={props.type == "view"}
            />
            <p style={{ color: "red" }} className="error">
              {salaryDataArray.date_err}
            </p>
          </div>

          {/* {salaryDataArray.sb_main_premium == '1' && (<div className={'col-xl-4 col-lg-6 col-md-12'}>

                            <div className=" me-auto pe-0 mt-3">
                                <label className={'poppins-medium-16px mb-2 custom_astrick form-label'}>
                                    {t('Salary benefit value')}
                                </label>
                                <div className="input-group">
                                    <input
                                        name={'premium_value' + salaryDataArray.sb_main_id}
                                        type="text"
                                        value={salaryDataArray.premium_value}
                                        onChange={(e) => {
                                            updateValue(index, e.target.value, 'premium_value');
                                        }}
                                        className="ps-3 rounded-0 shadow-none border form-control"
                                        disabled={props.type == "view"}
                                    />
                                    <span className="input-group-text rounded-0  category_currency_height poppins-medium-16px">
                                        {salaryDataArray.variable_type == 2 ? '%' : '€'}
                                    </span>
                                </div>
                                <span
                                    className={
                                        'input-group-text hi-40 border-0 bg-white rounded-0 bg-transparent px-0 pe-3 bg-color-salary-benefits'
                                    }
                                    style={{ marginLeft: '-29px' }}
                                />
                            </div>

                            <p style={{ color: 'red' }} className="error">
                                {salaryDataArray.v_err}
                            </p>

                        </div>
                        )} */}
          {/* <div className="col-xl-4 col-lg-6 col-md-12 mt-3">
                            <label
                                className={

                                    'poppins-medium-16px mb-2'

                                }
                            >
                                {t('Is the benefit granted in case of absence of the employee?')}
                            </label>
                            <RadioGroup
                                name={'granted' + salaryDataArray.sb_main_id}
                                onChange={(e) => {
                                    updateValue(index, e, 'granted');
                                }}
                                selectedValue={salaryDataArray.is_skip_absence}

                            >
                                <label htmlFor="granted1" className="mb-2 poppins-regular-16px d-flex">
                                    <Radio id="granted1" value={'1'} className="me-2" disabled={props.type == "view"} />
                                    {t('Yes')}
                                </label>
                                <label htmlFor="granted2" className="mb-3 poppins-regular-16px d-flex">
                                    <Radio id="granted2" value={'0'} className="me-2" disabled={props.type == "view"} />
                                    {t('No')}
                                </label>
                            </RadioGroup>
                        </div> */}
          {/* {salaryDataArray.sb_main_autowagecode == 0 &&
                            <div className={'col-md-4 ps-3 mt-3'}>

                                <label
                                    className={
                                        (
                                            'poppins-medium-16px mb-2 custom_astrick'
                                        )
                                    }
                                >
                                    {t('Applicable coefficient')}
                                </label>
                                <RadioGroup
                                    name={'coefficient' + index}
                                    onChange={(e) => {
                                        updateValue(index, e, 'coefficient_type');
                                    }}
                                    selectedValue={salaryDataArray.coefficient_type}
                                    disabled={props.type == "view"}

                                >
                                    <label
                                        htmlFor="coefficient1"
                                        className="mb-2 poppins-regular-16px d-flex align-items-baseline"
                                    >
                                        <Radio id="coefficient1" value={'1'} className="me-2" disabled={props.type == "view"} />
                                        {t('Based on employee type in the cooperation agreement')}
                                    </label>
                                    <label htmlFor="coefficient2" className="mb-3 poppins-regular-16px d-flex" disabled>
                                        <Radio id="coefficient2" value={'2'} className="me-2" disabled={props.type == "view"} />
                                        {t('Other')}
                                    </label>
                                </RadioGroup>
                                <br />
                                <p style={{ color: 'red' }} className="error">
                                    {salaryDataArray.ct_err}
                                </p>
                                {salaryDataArray.coefficient_type == 2 &&
                                    <div className="">
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                updateValue(index, e.target.value, 'coefficient_value');
                                            }}
                                            className="ps-3 rounded-0 shadow-none border form-control"
                                            defaultValue={salaryDataArray.coefficient_value}
                                            name="coefficientother"
                                            disabled={props.type == "view"}

                                        />

                                        <p style={{ color: 'red' }} className="error">
                                            {salaryDataArray.c_err}
                                        </p>

                                    </div>
                                }</div>} */}

          {/* occurence  */}
          <div className={"col-4 multiSelectWrapper"}>
            <LabelField title={t("Occurence")} />
            <MultiSelectField
              name={"occurence" + salaryDataArray.sb_main_id}
              id={"select_id"}
              options={options}
              standards={getOptionObj(salaryDataArray.occurence)}
              handleChange={(e) => {
                updateValue(index, e.value, "occurence");
              }}
              isMulti={false}
              className="w-100"
              disabled={props.type == "view"}
              classNamePrefix={`multiSelectDropdown`}
            />
            <p style={{ color: "red" }} className="error">
              {salaryDataArray.o_err}
            </p>
            {/* Nothing extra is displayed for occurence === 0 */}
          </div>
          <div className="col-12 mb-3">
            {/* Render CheckBoxField for occurence 1 or 2 */}
            {(salaryDataArray.occurence === 1 ||
              salaryDataArray.occurence === 2) && (
              <div className={salaryDataArray.occurence === 2 ? "row" : ""}>
                <div className={salaryDataArray.occurence === 2 ? "col-6" : ""}>
                  <CheckBoxField
                    name={`is_mandatory${salaryDataArray.is_skip_absence}`}
                    label={
                      t(
                        "Is the benefit granted in case of absence of the employee"
                      ) + "?"
                    }
                    className="my-checkbox"
                    checked={salaryDataArray.is_skip_absence}
                    onChangeHandler={(e) =>
                      updateValue(index, e.target.checked, "is_skip_absence")
                    }
                    id="activateAddProject"
                    lineHeight="1vw"
                    disabled={props.type === "view"}
                  />
                </div>

                {/* Conditionally render InputTextfield for occurence 2 */}
                {salaryDataArray.occurence === 2 && (
                  <div className="col-6">
                    <InputTextfield
                      value={salaryDataArray.amount}
                      type="text"
                      onChange={(e) =>
                        updateValue(index, e.target.value, "amount")
                      }
                      disabled={props.type === "view"}
                      placeholder={t("Enter amount")}
                    />
                  </div>
                )}
              </div>
            )}

            {/* Render InputTextfield for occurence 3 or 4 */}
            {(salaryDataArray.occurence === 3 ||
              salaryDataArray.occurence === 4) && (
              <InputTextfield
                type="text"
                value={salaryDataArray.amount}
                onChange={(e) => updateValue(index, e.target.value, "amount")}
                placeholder={t("Enter amount")}
                disabled={props.type === "view"}
              />
            )}
          </div>
        </div>
      </div>
    );
    return tableHtmlContent;
  };

  return (
    <>
      <Header
        headerName={`${props.type == "view" ? "View" : "Edit"} salary benefit`}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-3">
        <BackLink
          backLink={() => {
            navigate("/editpc/" + pc_unique_key);
          }}
        />
      </div>
      <form onSubmit={Submit} className="managepagesBg">
        <div className="row ">
          <div className="col-md-12">
            <div className={`${styles["salary-benefits-tab-parent"]}`}>
              <div>
                <div className={""}>
                  {salaryBenefits.map((val, index) =>
                    val.selected === "1"
                      ? showEachBenifitDetails(val, index)
                      : ""
                  )}
                </div>
              </div>
              {props.type != "view" && (
                <div className="row">
                  <div className="col-md-12 multiSelectWrapper">
                    <MultiSelectField
                      options={updateBenefitList()}
                      handleChange={onSelect}
                      isMulti={true}
                      standards={[]}
                      classNamePrefix={`multiSelectDropdown`}
                      placeholder={t("Select benefit")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-end col-md-12 mt-3">
            <button
              // onClick={()=>Submit}
              type="submit"
              className="searchBtn shadow-none"
            >
              {t("SAVE")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default translate(SalaryBenefits);
