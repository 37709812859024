import React from "react";
import { t } from "../CentralDataMangement/translation/Translation";

const LeftPart = () => {
  return (
    <table className="table">
      <thead>
        <tr className="TableHeader" style={{height:"4.5vw"}}>
          <th colSpan={2} className="p-0">
            <div className="firstpart-cell ">
              <span title="Employee types" key={`tablecolindex`} className="text-end pt-3 pe-4">
                {t("Employee types")}
              </span>
              <span title="Coefficients" key={`tablecolindex2`} className="pb-3 ps-4">
                {t("Coefficients")}
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="pclinking-table-tbody table-first-part-tbody">
        {/* {getTableContent()} */}
      </tbody>
    </table>
  );
};

export default LeftPart;
