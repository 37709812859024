import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";

const Invoicing = () => {
  const [isLastTab, setIsLastTab] = useState(true);
  const handlePrevious = () => {
    console.log("Previous clicked");
  };
  const handleSubmit = () => {
    // Logic to submit the form
    console.log("Form submitted");
  };

  // Define the type for formData including all possible fields
  type FormData = {
    firstName: string;
    lastName: string;
    streetName: string;
    houseNo: string;
    boxNo: string;
    postalCode: string;
    city: string;
    country: string;
    phoneNo: string;
    faxNo: string;
    email: string;
    vatRate: string;
    [key: string]: string; // Index signature to allow string indexing
  };

  // Initialize formData with the defined type
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    streetName: "",
    houseNo: "",
    boxNo: "",
    postalCode: "",
    city: "",
    country: "",
    phoneNo: "",
    faxNo: "",
    email: "",
    vatRate: "",
    // Add all expected fields here
    absoluteYouOfficeNumber: "", // Include missing property to match formData type
    lineTotalForCostCenter: "",
  });

  // Correctly type the 'e' parameter in handleChange function
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Reusable function to render input fields
  const renderInputField = (
    type: string,
    label: string,
    name: string,
    isMandatory = false
  ) => (
    <LabelWithInputField
      type={type}
      label={t(label)}
      value={formData[name]}
      name={name}
      id={name}
      handleChange={handleChange}
      error=""
      className="ps-2"
      isMandatory={isMandatory}
    />
  );

  // Reusable function to render radio fields
  const renderRadioFieldGroup = (title: string, name: string) => (
    <div className="col-4 mb-3">
      <div className="mb-2">
        <LabelField title={t(title)} isMandatory={true} />
      </div>
      <RadioField
        name={name}
        label={t("Yes")}
        className="radioBtn"
        ischecked={formData[name] === "yes"}
        handleChange={handleChange}
        id={`${name}Yes`}
        containerClassName="companyLanguage me-3"
      />
      <RadioField
        name={name}
        label={t("No")}
        className="radioBtn"
        ischecked={formData[name] === "no"}
        handleChange={handleChange}
        id={`${name}No`}
        containerClassName="companyLanguage"
      />
    </div>
  );

  return (
    <>
      <div className="row">
        {/* Input Fields */}
        <div className="col-6 mb-3">
          {renderInputField(
            "text",
            "Invoicing contact person first name",
            "firstName",
            true
          )}
        </div>
        <div className="col-6 mb-3">
          {renderInputField(
            "text",
            "Invoicing contact person last name",
            "lastName",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: Street",
            "streetName",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: House number",
            "houseNo",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField("text", "Invoicing address: Box number", "boxNo")}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: Postal code",
            "postalCode",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField("text", "Invoicing address: City", "city", true)}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: Country",
            "country",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "tel",
            "Invoicing address: Phone number",
            "phoneNo"
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField("text", "Invoicing address: Fax number", "faxNo")}
        </div>
        <div className="col-4 mb-3">
          {renderInputField("email", "Invoicing address: Email", "email", true)}
        </div>
        <div className="col-4 mb-3">
          <div className="position-relative vatRate">
            {renderInputField("text", "VAT rate", "vatRate", true)}
            <span className="fieldSuffix position-absolute">{t("%")}</span>
          </div>
        </div>

        {/* Radio Fields */}
        {renderRadioFieldGroup("VAT applicable", "vatApplicable")}
        {renderRadioFieldGroup(
          "Payment via domiciliering",
          "paymentViaDomiciliering"
        )}
        {renderRadioFieldGroup("Private customer", "privateCustomer")}
        {renderRadioFieldGroup("Digital Invoicing", "digitalInvoicing")}
        {renderRadioFieldGroup("Invoice frequency", "invoiceFrequency")}
        {renderRadioFieldGroup(
          "Separate invoice per location",
          "separateInvoicePerLocation"
        )}
        {renderRadioFieldGroup(
          "Subtotal per cost center",
          "subtotalPerCostCenter"
        )}
        {renderRadioFieldGroup(
          "Separate invoicing for workers (arbeiders) and servants (bedienden)",
          "separateInvoicingForWorkers"
        )}
        {renderRadioFieldGroup(
          "Line with total of actuals worked hours per cost center needs to be added",
          "lineTotalForCostCenter"
        )}

        {/* Select Fields */}
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={t("Payment condition (standard payment condition)")}
            title={t("Payment condition (standard payment condition)")}
            name="absoluteYouOfficeNumber"
            placeHolder={t("Select")}
            search
            options={[]}
            value={formData.absoluteYouOfficeNumber}
            onChange={handleChange}
            isMulti={false}
            className="select-field"
            error=""
            isTranslate
          />
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={t("Type of correction invoice")}
            title={t("Type of correction invoice")}
            name="absoluteYouOfficeNumber"
            placeHolder={t("Select")}
            search
            options={[]}
            value={formData.absoluteYouOfficeNumber}
            onChange={handleChange}
            isMulti={false}
            className="select-field"
            error=""
            isTranslate
          />
        </div>
      </div>
      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handleSubmit}
      />
    </>
  );
};

export default Invoicing;
