import React from "react";
import Icon from "../../../Icon";
interface TableHeaderWithIconProps {
  title?: string;
  TableHeaderWithIconClassName?:string;
  TableHeaderTitle?:string;
}
const TableHeaderWithIcon: React.FC<TableHeaderWithIconProps> = ({ title, TableHeaderWithIconClassName,TableHeaderTitle }) => {
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
    fillColor: "#fff",
  };
  return (
    <div className={`d-flex align-items-center justify-content-between ${TableHeaderWithIconClassName}`}>
     <div className="prevWhiteIconWrapper">
     <Icon name="backIcon" {...IconProps} />
     </div>
      <div className={`${TableHeaderTitle}`}>{title}</div>
      <div className="nextWhiteIconWrapper">
      <Icon name="nextIcon" {...IconProps} />
      </div>
    </div>
  );
};

export default TableHeaderWithIcon;
