import React from "react";

/**
 * Service for validation and date-time utilities
 */
const ValidationService = {
    dateComparison: (timestamp1, timestamp2, type = 0) => {
        const a = new Date(timestamp1);
        const b = new Date(timestamp2);
        if (type === 0) {
            return a > b;
        }
        return a >= b;
    },

    differenceBetweenTwoTimestamps: (timestamp1, timestamp2, type = 0) => {
        const date1 = new Date(timestamp1.replace(/-/g, "/"));
        const date2 = new Date(timestamp2.replace(/-/g, "/"));
        const timeDifference = Math.abs(date1 - date2);

        if (isNaN(timeDifference)) {
            return 0;
        }

        let hoursDifference = timeDifference / (1000 * 60 * 60);
        if (type === 1) {
            hoursDifference = Math.abs(hoursDifference).toFixed(2);
        }

        return hoursDifference;
    },

    today: (type = 0, format = 0) => {
        const date = new Date();
        let day = date.getDate().toString().padStart(2, "0");
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        if (type === 1) {
            let hours = date.getHours().toString().padStart(2, "0");
            let minutes = date.getMinutes().toString().padStart(2, "0");
            const formattedDate =
                format === 0
                    ? `${year}-${month}-${day} ${hours}:${minutes}:00`
                    : `${day}-${month}-${year} ${hours}:${minutes}:00`;
            return formattedDate;
        } else if (type === 2) {
            return `${day}-${month}-${year}`;
        }

        return `${year}-${month}-${day}`;
    },

    addDays: (value, days) => {
        const date = new Date(value);
        const newDate = new Date(date.setDate(date.getDate() + days));
        const day = newDate.getDate().toString().padStart(2, "0");
        const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
        const year = newDate.getFullYear();
        const hours = newDate.getHours().toString().padStart(2, "0");
        const minutes = newDate.getMinutes().toString().padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:00`;
    },

    removeDays: (value, days) => {
        const date = new Date(value);
        const newDate = new Date(date.setDate(date.getDate() - days));
        const day = newDate.getDate().toString().padStart(2, "0");
        const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
        const year = newDate.getFullYear();
        const hours = newDate.getHours().toString().padStart(2, "0");
        const minutes = newDate.getMinutes().toString().padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:00`;
    },

    getDate: (value, type = 0) => {
        const parts = value.split(/[- :]/);
        const date = new Date(
            parseInt(parts[0]),
            parseInt(parts[1]) - 1,
            parseInt(parts[2])
        );

        if (type !== 0) {
            return date.toLocaleDateString("en-GB").split("/").reverse().join("-");
        }

        return date.toLocaleDateString("en-GB");
    },

    getDate2: (value, type = 0) => {
        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString();

        if (type !== 0) {
            return `${year}-${month}-${day}`;
        }

        return `${day}-${month}-${year}`;
    },

    getTime: (value, type = 0) => {
        const [datePart, timePart] = value.split(" ");
        const [hours, minutes] = timePart.split(":");

        if (type === 1) {
            return `${datePart}T${hours}:${minutes}:00`;
        }

        return `${hours}:${minutes}`;
    },

    timeFormatting: (value) => {
        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return `${day}-${month}-${year} ${hours}:${minutes}:00`;
    },

    emptyValidationMethod: (value) => {
        if (!value || value.length === 0) {
            return "This field is required.";
        }
        if (
            typeof value === "string" &&
            (value.startsWith("<script>") || value.endsWith("</script>"))
        ) {
            return "HTML tags are not accepted.";
        }

        return "";
    },

    numberValidationMethod: (value) => {
        if (!/^[0-9]+$/.test(value)) {
            return "This field is invalid.";
        }

        return "";
    },

    nameValidationMethod: (value) => {
        if (!/^[a-zA-Z\u0080-\uFFFF0-9 ]+$/.test(value)) {
            return "This field is invalid.";
        }

        return "";
    },

    salaryValidationMethod: (value) => {
        if (!/^[0-9]+$/.test(value)) {
            return "This field is invalid.";
        }

        return true;
    },

    pcNumberValidationMethod: (value) => {
        if (!/^([0-9]{3})$/.test(value) && !/^([0-9]{3}[.][0-9]{2})$/.test(value)) {
            return "This field is invalid.";
        }

        return "";
    },

    percentageValidationMethod: (value) => {
        if (
            /(^(100([.][0]{1,2})?)$)|(^[1-9][0-9]{0,1}([.]\d{1,2})?)$/.test(value) ||
            /(^(100([,][0]{1,2})?)$)|(^[1-9][0-9]{0,1}([,]\d{1,2})?)$/.test(value)
        ) {
            return "";
        }

        return "This field is invalid.";
    },

    minSalaryValidationMethod: (value) => {
        if (/^[1-9][0-9]{0,2}(([.]|[,])\d{1,4})?$/.test(value)) {
            return "";
        }

        return "This field is invalid.";
    },

    maxSalaryValidationMethod: (value) => {
        if (/^[1-9][0-9]{0,4}(([.]|[,])\d{1,4})?$/.test(value)) {
            return "";
        }

        return "This field is invalid.";
    },

    formatNumber: (value) => {
        return parseFloat(value.replace(/[,]/g, ".")).toFixed(2);
    },

    dayFormatNumber: (value) => {
        return parseFloat(value.replace(/[.]/g, ",")).toFixed(2);
    },

    hoursperdayValidationMethod: (value) => {
        if (
            value.match(/^(0?[0-9]|1[0-9]|2[0-3])([.,]\d{1,2})?$/) ||
            value.match(/^24([.,]0{1,2})?$/) ||
            value.match(/(^[0]?\d{1})$/) ||
            value.match(/(^[1]{1}\d{1})$/) ||
            value.match(/(^[2]{1}[0-3]{1})$/) ||
            value.match(/(^([2][4]){1})$/)
        ) {

            return "";
        } else {
            return "This field is invalid.";
        }
    },

    maximumhourperweekValidationMethod: (value) => {
        const truncatedValue = value.split(/[.,]/)[0];
        if (truncatedValue.length > 3) {
            return "This field is invalid.";
        } else if (!value.match(/^[0-9]{1,3}([.,][0-9]{1,2})?$/)) {
            return "This field is invalid.";
        } else {
            return "";
        }
    },
};

export default ValidationService;
