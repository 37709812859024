import React from "react";
import Icon from "../../Icon";
import IconWithTitle from "../common/molecules/IconWithTitle";
import LoadImage from "../../static/images/DummyImage.jpg";
import { t, translate } from "../CentralDataMangement/translation/Translation";

interface Employee {
  company_id: number;
  cost_center_id: number;
  employee_id: number;
  employee_name: string;
  employee_type: string;
  employee_type_id: number;
  function: string;
  function_id: number;
  location: string;
  location_id: number;
  paritair_commitee: number;
  pc: string;
  project_id: number;
  salary: string;
}

interface SelectedEmployeesDetailsTableProps {
  onEmployeeSelected: Employee;
  onNextEmployee: () => void;
  onPreviousEmployee: () => void;
}

const SelectedEmployeesDetailsTable: React.FC<SelectedEmployeesDetailsTableProps> = ({
  onEmployeeSelected,
  onNextEmployee,
  onPreviousEmployee,
}) => {
  const IconProp = {
    width: "1vw",
    isIcon: true,
    height: "1vw",
    fillColor: "#8571b9",
    outline: "0.2vw solid #8571b9",
    outlineOffset: "0vw",
    borderRadius: "50%",
  };

  return (
    <div className="row">
      <div className="employeeSchedulingTable col-12 px-0">
        <table className="table mb-0">
          <tbody>
            <tr>
              <td className="align-middle text-start backIconWrapper">
                <Icon name="backIcon" {...IconProp} onClick={onPreviousEmployee} />
              </td>
              <td>
                <div className="d-inline-flex">
                  <IconWithTitle
                    src={LoadImage}
                    isImage={true}
                    imageWidth="2vw"
                    imageHeight="2vw"
                    imageBorderRadius="50%"
                    title={onEmployeeSelected.employee_name}
                    isTitle={true}
                    labelClassName="colorPrimary poppins-medium ms-2 employeeName"
                  />
                </div>
              </td>
              <td>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.function}
                </div>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.pc}
                </div>
              </td>
              <td>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.employee_type}
                </div>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.salary}
                </div>
              </td>
              <td className="align-middle text-end nextIconWrapper">
                <Icon name="nextIcon" {...IconProp} onClick={onNextEmployee} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default translate(SelectedEmployeesDetailsTable);
