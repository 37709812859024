import React, { useCallback, useState, useRef } from "react";
import { AutoComplete } from "primereact/autocomplete";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ApiCall } from "../../../services/ApiServices";
import { Debounce } from "../utlis/Debounce";
import LabelField from "./LabelField";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";

interface MultiTableProps {
  label: string;
  group: string;
  modelKey: string;
  column?: string;
  valueColumn?: string;
  concat?: string | null;
  joinTable?: string | null;
  notNullColumn?: string | null;
  customFetch?: boolean;
  separator?: string;
}

interface FilterProps {
  column: string;
  value: any;
  condition: "where" | "orWhere";
}

interface BaseAutocompleteDropdownProps {
  label?: string;
  labelClassName?: string;
  isMandatory?: boolean;
  id?: string;
  placeholder?: string;
  shouldCreateNew?: boolean;
  isMultiSelect?: boolean;
  microService: string;
  onChange: (value: any) => void;
  value: any;
  showDropdownList?: boolean;
  columnName?: string;
  modelKey: string;
  error?: string;
  concat?: string;
  name?: string;
  valueColumn?: string;
  isGrouped?: boolean;
  multiTables?: MultiTableProps[];
  isDisabled?: boolean;
  customFetch?: boolean;
  separator?: string;
  group?: string;
  notNullColumn?: string;
  className?:string;
}

interface AutocompleteDropdownPropsWithoutFilter extends BaseAutocompleteDropdownProps {
  filter?: false;
  filterInfo?: never;
}

interface AutocompleteDropdownPropsWithFilter extends BaseAutocompleteDropdownProps {
  filter: true;
  filterInfo: FilterProps[];
}

type AutocompleteDropdownProps =
  | AutocompleteDropdownPropsWithoutFilter
  | AutocompleteDropdownPropsWithFilter;

  interface AutoCompleteCompleteMethodParams {
    query: string;
  }

const AutoCompleteDropdown: React.FC<AutocompleteDropdownProps> = ({
  label,
  labelClassName,
  isMandatory,
  id,
  placeholder,
  shouldCreateNew = false,
  isMultiSelect = false,
  microService,
  onChange,
  value,
  showDropdownList = false,
  columnName = "name",
  modelKey,
  error,
  concat = null,
  name,
  valueColumn = 'id',
  isGrouped = false,
  multiTables = [],
  isDisabled = false,
  customFetch = false,
  separator = null,
  filter = false,
  filterInfo = [],
  group = null,
  notNullColumn = null,
  className

}) => {
  const [filteredItems, setFilteredItems] = useState<string[]>([]);
  const autoCompleteRef = useRef<HTMLDivElement>(null);

  const defaultColumn = 'name';
  const defaultValueColumn = 'id';
  const defaultConcatTable = null;
  const defaultJoinTable = null;
  const defaultNotNull = null;

  // Ensure default values for column and valueColumn using destructuring
  const processedMultiTables = multiTables.map(({
    label,
    group,
    modelKey,
    column = defaultColumn,
    valueColumn = defaultValueColumn,
    concat = defaultConcatTable,
    joinTable = defaultJoinTable,//Join is not implemented
    notNullColumn = defaultNotNull,
    customFetch = false,
    separator = null
  }) => ({
    label,
    group,
    modelKey,
    column,
    valueColumn,
    concat,
    joinTable,//Join is not implemented
    notNullColumn,
    customFetch,
    separator
  }));

  // Function to fetch items from the API
  const searchItems = async (query: string) => {
    try {
      const response = await ApiCall.service(
        ENDPOINTS.GET_SEARCH_VALUES,
        "POST",
        {
          modelKey: modelKey, value: query, column: columnName, customFetch: customFetch, concat: concat,
          separator: separator, valueColumn: valueColumn, multiTables: processedMultiTables, filter: filter,
          filterInfo: filterInfo, group: group, notNullColumn: notNullColumn
        },
        false,
        microService
      );
      if (response.status === 200) {
        const items = response.found;
        setFilteredItems(items);
      } else {
        console.error("Error fetching data:", response?.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Debounced search function to limit API calls while typing
  const debouncedSearchItems = useCallback(
    Debounce((query: string) => {
      searchItems(query);
    }, 500),
    []
  );

  // Handler for the search event from the AutoComplete component
  const handleSearch = (event: AutoCompleteCompleteMethodParams) => {
    debouncedSearchItems(event.query);
  };

  return (
    <div className="p-fluid">
      {label && (
        <LabelField
          title={label}
          className={labelClassName}
          isMandatory={isMandatory}
        />
      )}
      <div ref={autoCompleteRef}>
        <AutoComplete
          name={name}
          value={value}
          suggestions={filteredItems}
          completeMethod={handleSearch}
          onChange={(e) => onChange(e.value)}
          dropdown={showDropdownList}
          multiple={isMultiSelect}
          placeholder={placeholder}
          forceSelection={!shouldCreateNew}
          {...(isGrouped ? { optionGroupLabel: "label", optionGroupChildren: "items" } : {})}
          inputId={id}
          virtualScrollerOptions={{ itemSize: 35 }}
          inputClassName={`autoCompleteInput ${className}`}
          panelStyle={{ maxWidth: autoCompleteRef?.current?.querySelector('input')?.offsetWidth }}
          field="label"
          inputStyle={isDisabled ? { color: '#212529', } : {}}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default AutoCompleteDropdown;
